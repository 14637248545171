import { ReportClassificationName } from '@/services/report-library';

const OTHER = 2;
const internalReport = '内部报告'; // 单品专区 type = ''
const unauthorizedType = 0;
const unauthorizedAppliedType = 2;
const otherRtx = 'undefined';
const COVER_SMART_TYPE = 4;
const COVER_REPORT_FONT_PAGE_TYPE = 3;
const COVER_CUSTOM_TYPE = 2;
const COVER_RANDOM_TYPE = 1;

const classificationObj: { [key in ReportClassificationName]: {
  label: string,
  errorMsg: string | null,
  tooltipMsg: string,
} } = {
  application: {
    label: '业务应用',
    errorMsg: '请选择业务应用',
    tooltipMsg: '报告可应用于哪些业务场景，由作者自行判断',
  },
  category: {
    label: '品类玩法',
    errorMsg: null,
    tooltipMsg: '报告中涉及的游戏都属于哪些品类；若不涉及具体游戏，可不填',
  },
  area: {
    label: '研究区域',
    errorMsg: null,
    tooltipMsg: '报告侧重研究的国家，跨区域研究可选择“全球”',
  },
  terminal: {
    label: '设备终端',
    errorMsg: null,
    tooltipMsg: '报告侧重研究的游戏产品的终端类型',
  },
  research_type: {
    label: '研究类型',
    errorMsg: null,
    tooltipMsg: '报告使用了哪种类型的研究方法或研究范式',
  },
  crowd: {
    label: '人群标签',
    errorMsg: null,
    tooltipMsg: '报告侧重研究的人群所属的标签',
  },
  industry: {
    label: '相关行业',
    errorMsg: null,
    tooltipMsg: '报告侧重研究的行业',
  },
};

const oldReportLabelLength = 0;

const keyObj = {
  report: '研究报告',
  fullReport: '报告全文',
  dataSearch: '数据报表',
  tags: '标签页',
  authors: '报告作者',
};

const classArray = [
  {
    key: 'report',
    name: 'public_research_report',
  },
  {
    key: 'fullReport',
    name: 'full_report_instead',
  },
  {
    key: 'dataSearch',
    name: 'data_search',
  },
  {
    key: 'tags',
    name: 'tag_page',
  },
  {
    key: 'authors',
    name: 'author_RTX',
  },
];

const initParams = {
  report: {
    keyword: '',
    page: 1,
    page_size: 10,
    needUpdate: true,
    total: 0,
    satisfaction: true,
  },
  fullReport: {
    keyword: '',
    page: 1,
    page_size: 10,
    needUpdate: true,
    total: 0,
    satisfaction: true,
  },
  preview: {
    keyword: '',
    page: 1,
    page_size: 10,
  },
  dataSearch: {
    keyword: '',
    page: 1,
    page_size: 10,
    needUpdate: true,
    total: 0,
    satisfaction: true,
  },
  tags: {
    keyword: '',
    page: 1,
    pageSize: 9,
    needUpdate: true,
    total: 0,
    satisfaction: true,
  },
  authors: {
    keyword: '',
    size: 1000,
    pageSize: 12,
    page: 1,
    needUpdate: true,
    total: 0,
    satisfaction: true,
  },
};

const formListInit: {[key: string]: { name: string, zhName: string }} = {
  series: {
    name: 'series',
    zhName: '监测系列',
  },
  application: {
    name: 'Applications',
    zhName: '业务应用',
  },
  category: {
    name: 'Genres',
    zhName: '品类玩法',
  },
  area: {
    name: 'Areas',
    zhName: '研究区域',
  },
  terminal: {
    name: 'Devices',
    zhName: '设备终端',
  },
  crowd: {
    name: 'Demographics',
    zhName: '人群标签',
  },
  industry: {
    name: 'Industries',
    zhName: '相关行业',
  },
  research_type: {
    name: 'Methods',
    zhName: '研究类型',
  },
};

const uploadStepOptions = [
  { name: '上传文件', required: true },
  { name: '报告信息', required: true },
  { name: '报告目录' },
  { name: '报告权限', required: true },
  { name: '全局预览' },
];

const languageOptions = [
  { label: '中文', value: 'zh' },
  { label: '英文', value: 'en' },
];

const projectOption = {
  label: '默认项目',
  value: 'P0000000000000',
};

export default {
  other: OTHER,
  internalReport,
  unauthorizedType,
  unauthorizedAppliedType,
  otherRtx,
  coverReportFontPageType: COVER_REPORT_FONT_PAGE_TYPE,
  coverCustomType: COVER_CUSTOM_TYPE,
  coverRandomType: COVER_RANDOM_TYPE,
  coverSmartType: COVER_SMART_TYPE,
  classificationObj,
  oldReportLabelLength,
  classArray,
  keyObj,
  initParams,
  formListInit,
  uploadStepOptions,
  languageOptions,
  projectOption,
};
