const ROW_STATUS = ['未发布', '未开始', '进行中', '已完成'];
const ROW_TRANSLATE_STATUS = ['', 'NotStarted', 'InProgress', 'Completed'];
const ROW_CLASS_NAME = ['unDone', 'unStart', 'processing', 'done'];
const RESEARCH_STATUS = ['发布', '撤回', '修改状态'];
const CHOOSE_ORDER: any = { ascend: 'asc', descend: 'desc' };

export default {
  rowStatus: ROW_STATUS,
  rowTranslateStatus: ROW_TRANSLATE_STATUS,
  rowClassName: ROW_CLASS_NAME,
  unPublishedStatus: 0,
  processingStatus: 1,
  unStartStatus: 2,
  doneStatus: 3,
  researchStatus: RESEARCH_STATUS,
  chooseOrder: CHOOSE_ORDER,
};
