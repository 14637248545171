import { productRequest, removeObjectFalsyKey } from '@/utils/helper/base';
import {
  ApprovalListParams, ApprovalData, UpdateApprovalData, ApprovalProgressData,
} from '@/services/authority';

export async function getProductApprovalList(productId: number, params: ApprovalListParams): Promise<ApprovalData> {
  const res = await productRequest(
    `/api/szadmin/approval/list?${new URLSearchParams(removeObjectFalsyKey(params))}`, productId,
  );

  return res.data;
}

export async function updateProductApproval(productId: number, payload: UpdateApprovalData) {
  const res = await productRequest(
    '/api/szadmin/approval/store', productId,
    { method: 'post', data: payload },
  );

  return res.data;
}

export async function getProductApprovalProgress(productId: number, authId: number): Promise<ApprovalProgressData> {
  const res = await productRequest(`/api/szadmin/approval/stream/${authId}`, productId);

  return res.data;
}
