/* eslint-disable camelcase */
import React, { FC } from 'react';
import { Avatar, Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { UserInfoContext } from '@/context';
import configs from '@/configs';
import { removeUserEmSpan } from '@/utils/helper/base';
import { UserInfo, logout } from '@/services/users';

const AVATAR_URL = configs('app.avatars');
const otherRtx = configs('report.otherRtx');

interface AvatarProps {
  userInfo: UserInfo | null,
  gamesAdminEntranceVisible?: boolean,
  backstageButtonVisible?: boolean
}
interface MenuOptions{
  admin: number, link: string,
  gamesAdminVisible: boolean,
  backstageButtonVisible: boolean,
  staff_type_id: number
}
const menu = (menuOptions: MenuOptions) => {
  const {
    admin, link, gamesAdminVisible, backstageButtonVisible, staff_type_id: staffTypeId,
  } = menuOptions;
  const { isStaffUser } = UserInfoContext.useContainer();
  const isOutNet = window.location.href.includes('tencent');
  const ssoAdmin = () => (isStaffUser && isOutNet ? window.open('https://imur.tencent.com')
    : window.open('http://sso.imur.woa.com'));
  return (
    <Menu>
      {admin && backstageButtonVisible && (
      <Menu.Item>
        <Link to={link}>
          <Translation>
            {(t) => t('management')}
          </Translation>
        </Link>
      </Menu.Item>
      )}
      {gamesAdminVisible && (
      <Menu.Item>
        <Link to="/single-product/admin/report/list?key=1">
          <Translation>
            {(t) => t('games_management')}
          </Translation>
        </Link>
      </Menu.Item>
      )}
      {staffTypeId === configs('outNet.outAdminUser') && (
      <Menu.Item>
        <span onClick={ssoAdmin} role="button">
          <Translation>
            {(t) => t('ssoAdmin')}
          </Translation>
        </span>
      </Menu.Item>
      )}
      <Menu.Item>
        <span onClick={logout} role="button">
          <Translation>
            {(t) => t('logout')}
          </Translation>
        </span>
      </Menu.Item>
    </Menu>
  );
};

const NavAvatar: FC<AvatarProps> = ({
  userInfo, gamesAdminEntranceVisible = false, backstageButtonVisible = true,

}: AvatarProps) => {
  const { isPayingUsers } = UserInfoContext.useContainer();

  if (!userInfo) {
    return <div className="nav-avatar" />;
  }

  const link = '/admin';
  const menuOptions = {
    admin: userInfo.admin,
    link,
    gamesAdminVisible: gamesAdminEntranceVisible,
    backstageButtonVisible,
    staff_type_id: userInfo.staff_type_id,
  };

  return (
    <Dropdown
      overlay={menu(menuOptions)}
      placement="bottomCenter"
      arrow
    >
      <div className={isPayingUsers ? 'nav-avatar-pay' : 'nav-avatar'}>
        {userInfo.headimg && (
        <Avatar
          src={
          `${AVATAR_URL}${userInfo.user_name === otherRtx ? otherRtx : removeUserEmSpan(userInfo.user_id)}/profile.jpg`
          }
          alt={userInfo.user_id}
        />
        )}
        <span className={isPayingUsers ? 'nav-avatar-name-pay' : 'nav-avatar-name'}>
          {userInfo.user_id.split('@')[0]}
        </span>
      </div>
    </Dropdown>
  );
};

export default NavAvatar;
