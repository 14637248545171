/* eslint-disable max-len */
const immediateSuperiorNumber = 1;
const adminNumber = 2;
const reportChargeNumber = 3;

const basicPermissionType = 1;
const reportPermissionType = 2;
const idataPermissionType = 3;
const reportDownloadType = 5;

const Leaders = ['Director', 'GM', 'VP', '总裁', '总办'];

const unauthorizedReportId = -1;

const sunshineCodeOfConductLink = 'http://policy.woa.com/document/preview?documentId=D2728D77-4F56-485E-8175-EFD78E6639DA';
const highRiskOfOfficeNetworkLink = 'http://policy.woa.com/document/preview?documentId=B93922B4-E7A4-4DA7-B6D0-485BE66EAEB9';

const spreadJSKey = 'imur.woa.com|test.imur.woa.com|insight.imur.tencent.com,821427712733869#B0wVsYup7K6MERZxGVwUGNsZmWZhUY78kUqlHa424SlxGNy8kZLFDeut4LZpFRPVnQxJ4VodFUrQnMaZ4atd4SKBVdoplU6dVRLtWc4Yma7RVQ7ADeJR4aGlmYyYHS5JzKWhkMnNHM8EjMFVHZr2CS5MDMopnN9FFdahVYXdjZxYFc9JkM4QzNVRVdyFzK42SYlN6bphUOhpVQ7RlR6F4YxIDNaJ4TOF4Z62UMaxGSIJnaStkVOtGd8plNQ5GRR9mZyMGbNVDamFzRPZUds3UMulnc7lTM6kWVz3iaspGT4x6aiRzM9oHVOdjS0JlU9EGaplHb6MkSKZ5KYhFUll5LoFGaENTOp3EWK3WcLZmQrJlI0IyUiwiI7IDRDN4MCdjI0ICSiwyN4ATM9IzM7gTM0IicfJye#4Xfd5nIFVUSWJiOiMkIsICNx8idgAyUKBCZhVmcwNlI0IiTis7W0ICZyBlIsISNwkDM8ADI7EDOwEjMwIjI0ICdyNkIsISbvNmL49WZj9WZ49ic55WauQHanl6culGLt36YuE6b79ic55WauQ7clRHLt36YuE6b79ic55WaiojIz5GRiwiI8+Y9sWY9QmZ0Jyp9Jy11zyZ9xer9Iy11Aqo9Re09v0K0+WI0iojIh94QiwiI9YDOzMzNyEzN7IDNxIDOiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zZRdHRq36dyk4aWxWc0VHVzVEbKNXZGVGVURTM6EkN0dDTSVTb8UlVthzUTFWWxRDVlhGb4o4Y9tUblxmahRTc5ITNLZLU';

export interface ClassificationItem {
  name: string,
  option: string,
  link?: string,
}

export interface ScenesProps {
  isPay: boolean,
  title: string,
  describe: string,
  classification: ClassificationItem[],
}

const homeScenes: ScenesProps[] = [
  {
    isPay: true,
    title: 'businessOverseas',
    describe: 'homeInsteadTitle1',
    classification: [
      {
        name: 'homeInsteadTitle2',
        option: '区域市场机会识别',
      },
      {
        name: 'homeInsteadTitle3',
        option: '海外品类布局考察',
      },
      {
        name: 'homeInsteadTitle4',
        option: '海外专项报告',
        link: '/global-research',
      },
    ],
  },
  {
    isPay: true,
    title: 'productsResponse',
    describe: 'homeInsteadTitle5',
    classification: [
      {
        name: 'homeInsteadTitle6',
        option: '热门竞品专项报告',
        link: '/report-library/tag?subscribed=竞品研究&reportType=深度报告',
      },
      {
        name: 'homeInsteadTitle7',
        option: '竞品数据对比分析',
        link: '/base/competitor/home',
      },
    ],
  },
  {
    isPay: false,
    title: 'userTrends',
    describe: 'homeInsteadTitle8',
    classification: [
      {
        name: 'homeInsteadTitle9',
        option: '每周优选文化信号',
        link: '/cultural-signals/home',
      },
      {
        name: 'homeInsteadTitle10',
        option: '每月文化信号解读',
        link: '/report-library/tag?subscribed=文化信号',
      },
      {
        name: 'homeInsteadTitle11',
        option: '年轻人专题',
        link: '/report-library/tag?subscribed=年轻人专题',
      },
    ],
  },
];

export default {
  env: process.env.NODE_ENV,

  copyRight: '腾讯IEG市场与用户研究部 版权所有',

  // 服务器环境（测试、本地、生产）
  serverEnv: process.env.REACT_APP_ENV,

  iconScriptUrl: '//at.alicdn.com/t/font_1941520_xjxe7wnb0z.js',

  immediateSuperiorNumber,

  adminNumber,

  reportChargeNumber,

  basicPermissionType,

  reportPermissionType,

  idataPermissionType,

  reportDownloadType,

  supportedLanguages: [
    { key: 'en', name: 'English' },
    { key: 'zh', name: '中文' },
  ],

  mobileServer: process.env.REACT_APP_MOBILE_SERVER,

  detailPath: process.env.REACT_APP_REPORT_DETAIL_PATH,

  uploadUrl: process.env.REACT_APP_UPLOAD_URL,

  defaultTitle: process.env.REACT_APP_TITLE,

  avatars: process.env.REACT_APP_AVATAR_URL,

  Leaders,

  unauthorizedReportId,

  beaconAppKey: process.env.REACT_APP_BEACON_APP_KEY,

  sunshineCodeOfConductLink,

  highRiskOfOfficeNetworkLink,

  spreadJSKey,

  homeScenes,

  aiServer: process.env.REACT_APP_AI_SERVER,
};
