/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import request from '@/utils/request';
import { MenuTreeData } from './menu';

interface DataType {
  path?: string,
  type?: string,
  url?: string,
  charge_by?: string[],
  hideFunc: boolean,
}

export type AdminMenuData = MenuTreeData;

export async function getAdminMenu(): Promise<AdminMenuData> {
  const res = await request.get('/api/adminapi/adminMenu/menus');

  return res.data;
}

export interface Stream {
  role: number,
  user_id: string,
}

export interface SuperiorData {
  stream: Stream[],
  mail?: string[],
  effective: number,
  applicant: string,
}

export async function getSuperior(): Promise<SuperiorData> {
  const res = await request.get('/api/webapi/system/description');

  return res.data;
}

export interface AuthData{
  download_auth: number | string,
  download_auth_app: number | string,
}

export interface StaffData {
  name: string,
  type: number,
  user_id?: string
}

export type StaffType = 1 | 2; // 1 department/ 2 staff

export async function getStaff(type: StaffType, name?: string, size?: number): Promise<StaffData[]> {
  const res = await request.get('/api/adminapi/staff/search', { params: { name, size, type } });

  return res.data;
}

export type ProductId = number;

export type MenuId = number;

export interface AdminMenuTreeData {
  auth: number,
  data: DataType,
  id: number,
  name: string,
  perm: number,
  type: number,
  icon?: string,
  children: AdminMenuTreeData[],
}

export interface UpdateAdminMenuPayload {
  group_id: number,
  menus_ids: number[],
}

export interface ApplyPermissionData {
  reason: string,
  cc: string[],
  openid: string,
}
interface WriterData{
  id?: number,
  user_id?: string,
  user_name?: string,
  headimg?: string
}
export interface AssociatedItem{
  openid?: string,
  id: number,
  cover_pic?: string,
  created_at?: string,
  title?: string,
  write_by?: WriterData[],
  media_id?: number,
  preview_pic: string[] | null,
}
export interface MenuData {
  type?: string,
  url?: string,
  associated?: AssociatedItem[],
  read_auth?: number,
}

export interface LabelItem {
  classified_id: number,
  name: string,
}

export interface UserPreferenceOperate {
  like: boolean,
  favorites: boolean,
  public_booked?: boolean,
  special_booked?: boolean,
  keep_eye?: boolean,
  need_remind?: boolean,
}
