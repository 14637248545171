/* eslint-disable camelcase */
import request from '@/utils/request';
import { removeBaseUserGroup } from '@/utils/helper/base';
import configs from '@/configs';
import { Role } from '@/configs/type';
import { UserInfoData, PaginationRequest, PaginationResponse } from './response.type';

const ALL_GROUP_PREM = configs('user-group.allGroupPrem');

export interface UserGroupItemData {
  name: string,
  id?: number,
  num: number,
  admin?: number,
  normal?: number,
  type: number,
}

export async function getUserGroup(
  role: Role, isRemoveBaseUserGroup = true,
): Promise<UserGroupItemData[]> {
  const res = await request.get(`/api/adminapi/group/list/${role}`);
  if (role === ALL_GROUP_PREM && isRemoveBaseUserGroup) {
    removeBaseUserGroup(res.data);
  }
  return res.data;
}

export async function getUserGroupSingle(
  role: Role, szid: number, isRemoveBaseUserGroup = true,
): Promise<UserGroupItemData[]> {
  const res = await request.get(`/api/szadmin/group/list/${role}?szid=${szid}`);
  if (role === ALL_GROUP_PREM && isRemoveBaseUserGroup) {
    removeBaseUserGroup(res.data);
  }
  return res.data;
}

export async function updateUserGroup(name: string, type: string): Promise<number> {
  const res = await request.post('/api/adminapi/group/store', { name, type });
  return res.data;
}

export async function deleteUserGroup(id: number[]) {
  const res = await request.delete('/api/adminapi/group/del', { data: { id } });
  return res.data;
}

export interface UserGroupEmployeesItem extends UserInfoData {
  department: string[],
  join_at: string,
  group: string[],
  gid: number[],
  user_group_id: number[],
}

export interface UserGroupEmployees {
  data: UserGroupEmployeesItem[] | [],
  total: number,
  last_page: number
}

export interface UserGroupEmployeesParams {
  name?: string,
  department?: string,
  sort?: string,
  order?: 'asc' | 'desc',
  gid: number,
  page: number,
  page_size?: number,
  companyId?: number,
  cname?: string,
}

export async function getUserGroupEmployees(
  params: UserGroupEmployeesParams,
): Promise<UserGroupEmployees> {
  const res = await request.get('/api/adminapi/group/memberList', { params });

  return res.data;
}

export interface UpdateUserGroupEmployeesPayload {
  gid: number[],
  name?: string[],
  org?: string[],
}

export interface AddUserGroupEmployeesPayload extends UpdateUserGroupEmployeesPayload {
  manner: 1 | 2
}

export async function addUserGroupEmployees(payload: UpdateUserGroupEmployeesPayload): Promise<Boolean | undefined> {
  try {
    const res = await request({
      method: 'POST',
      url: '/api/adminapi/group/memberAdd',
      data: payload,
      timeout: 20000,
    });
    return res.data;
  } catch {
    return undefined;
  }
}

export async function updateUserGroupEmployees(payload: UpdateUserGroupEmployeesPayload): Promise<Boolean> {
  const res = await request.post('/api/adminapi/group/memberEdit', payload);

  return res.data;
}

export async function deleteUserGroupEmployees(id: number[]): Promise<Boolean> {
  const res = await request.delete('/api/adminapi/group/memberDel', { data: { id } });

  return res.data;
}

export interface DependOrgData {
  created_at: null | string,
  gid: number,
  id: number,
  name: string,
  num: null | number,
  status: number,
  updated_at: null | string,
}

export async function getDependOrg(id: number): Promise<DependOrgData | null> {
  const res = await request.get(`/api/adminapi/group/getDependOrg/${id}`);

  return res.data;
}

export async function deleteDependOrg(id: number): Promise<boolean> {
  const res = await request.delete('/api/adminapi/group/dependOrgDel', { data: { depend_id: [id] } });

  return res.data;
}

export interface DependOrgPayload {
  gid: number,
  name: string,
}

export async function addDependOrg(payload: DependOrgPayload): Promise<boolean> {
  const res = await request.post('/api/adminapi/group/dependOrgEdit', payload);

  return res.data;
}

export interface CompanyParams extends PaginationRequest {
  gid: number,
  name?: string,
  sort?: 'user_num',
  order?: 'asc' | 'desc',
}

export interface CompanyItem {
  gid: number,
  name: string,
  status: number,
  created_at: string,
  updated_at: string,
  num: number,
  id: number,
}

interface CompanyData extends PaginationResponse {
  data: CompanyItem[],
  rtx_num: number,
}

export async function getCompanyList(params: CompanyParams): Promise<CompanyData> {
  const res = await request.get('/api/adminapi/group/dependOrgList', { params });

  return res.data;
}

export async function getSSOGroup(): Promise<boolean> {
  const res = await request.get('/api/adminapi/group/sso');

  return res.data;
}
