/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { getMenuData } from '@/services/menu';
import { getZoneMenuData } from '@/services/single-product/home';
import { useFetch } from '@/hooks';
import { useLocation } from 'react-router-dom';
import { AssociatedItem } from '@/services/common';
import { SingleProductInfoContext } from './index';

function useMenuUrlData() {
  const { productId } = SingleProductInfoContext.useContainer();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedMenuId = searchParams.get('selectMenuId');
  const [menuId, setMenuId] = useState<number>((selectedMenuId && Number(selectedMenuId)) || -1);
  const [URL, setURL] = useState<string>('');
  const [auth, setAuth] = useState<undefined | number>(undefined);
  const [associated, setAssociated] = useState<AssociatedItem[]>([]);
  const {
    data, error, refresh, loading,
  } = useFetch(
    () => (location.pathname.includes('/single-product') ? getZoneMenuData(productId, menuId) : getMenuData(menuId)), {
      defaultData: { url: '', type: '' },
      depends: [menuId],
      interrupt: menuId < 1 || isFirstLoad === true,
    },
  );

  useEffect(() => {
    setIsFirstLoad(false);
  }, [menuId]);

  useEffect(() => {
    if (data?.url) {
      setURL(data.url);
      setAuth(undefined);
    } else if (data?.read_auth !== undefined) {
      setURL('');
      setAuth(data.read_auth);
    }
    if (data?.associated) {
      setAssociated(data.associated);
    }
  }, [data]);

  const changeMenuId = (id: number) => {
    if (id < 1) {
      setAuth(undefined);
    }
    setMenuId(id);
  };

  return {
    url: URL,
    changeURL: setURL,
    changeData: changeMenuId,
    error,
    menuId,
    refresh,
    auth,
    associated,
    loading,
  };
}

const MenuUrlData = createContainer(useMenuUrlData);

export default MenuUrlData;
