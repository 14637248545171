/* eslint-disable camelcase */
import React, { FC, MouseEvent } from 'react';
import { Typography, Tag, Avatar } from 'antd';
import { ReportPreviewData } from '@/services/report-library';
import { UserPreferenceOperate, AuthData } from '@/services/common';
import { useTranslation } from 'react-i18next';
import configs from '@/configs';
import { WriterOrCharger } from '@/services/response.type';
import {
  UserInfoContext,
} from '@/context';
import {
  numberTransform, pathToAuthorPage, pathToTagPage, isSingleProductPage, removeUserEmSpan,
} from '@/utils/helper/base';
import ActionBar from './action-bar';
import ExternalBadge from '../external-badge';
import styles from '../index.module.less';

const { Text } = Typography;
const AVATAR_URL = configs('app.avatars');
const otherRtx = configs('report.otherRtx');

interface BaseInfoProps {
  isReport?: boolean,
  auth?: AuthData,
  data: ReportPreviewData,
  operateData: UserPreferenceOperate,
  onFavorite: (event: MouseEvent, checked: boolean, onFailedCallback: () => void) => void,
  onLike: (event: MouseEvent, checked: boolean, onFailedCallback: () => void) => void,
  onPublicClass?: (event: MouseEvent, checked: boolean, onFailedCallback: () => void) => void,
  onSpecialClass?: (event: MouseEvent, checked: boolean, onFailedCallback: () => void) => void,
  onShare: () => void,
  mobilePath?: string,
  withCountVisible?: boolean,
}

const internalReport = configs('report.internalReport');

const BaseInfo: FC<BaseInfoProps> = ({
  data, operateData, onFavorite, onLike, onShare, mobilePath, isReport = false,
  withCountVisible = false, onPublicClass = () => {}, onSpecialClass = () => {},
  auth,
}: BaseInfoProps) => {
  const { t } = useTranslation();
  const { isStaffUser } = UserInfoContext.useContainer();
  const renderWriter = (writers: WriterOrCharger[]) => writers?.map((item) => item && (
    <div
      className={!isStaffUser
        ? styles.reporterListItem : styles.reporterListItemNet} key={item.user_id}
      onClick={(event) => !isSingleProductPage() && pathToAuthorPage(event, item.user_id, item.user_name)}
    >
      {!isStaffUser && (
      <>
        <Avatar
          style={{ fontSize: 14 }}
          size={28}
          src={`${AVATAR_URL}${item.user_name === otherRtx ? otherRtx : removeUserEmSpan(item.user_id)}/profile.jpg`}
        >
          {item.headimg ? '' : item.user_id.substr(0, 1).toLocaleUpperCase()}
        </Avatar>
        <Text className={styles.reporterName}>
          {item.user_id}
          {item.user_name ? `(${item.user_name})` : ''}
        </Text>
      </>
      )}
    </div>
  ));

  const renderNumElement = (
    num: number,
    str: string,
    hasDot = false,
  ) => (
    <>
      {numberTransform(num)}
      {' '}
      {str}
      {' '}
      {hasDot ? <div className={styles.dot} /> : ''}
    </>
  );

  const limit = 6;

  const setTags = () => {
    let { tag = [] } = data;
    const {
      // eslint-disable-next-line react/prop-types
      application = [], category = [], area = [], crowd = [],
    } = data;
    if (tag.length > 2) {
      tag = tag.slice(0, 2);
    }

    const tagList = [...tag, ...application, ...category, ...area, ...crowd].slice(0, limit);
    const tagsElement = tagList.map((item) => (
      <Tag
        className="mur-tag"
        key={item} onClick={(event) => !isSingleProductPage() && pathToTagPage(event, item)}
      >
        {item}
      </Tag>
    ));
    return (tagsElement);
  };

  return (
    <>
      <h2 className={styles.reportTitle}>
        {data.title}
        <ExternalBadge
          type={data.type}
          isCard={false}
          style={{
            marginLeft: 6,
            transform: 'translateY(-2px)',
          }}
        />
      </h2>
      <div className={styles.reportData}>
        <div>
          {setTags()}
          {withCountVisible && (
            <Text className={styles.subText}>
              {renderNumElement(data.views_num, t('views'), true)}
              {renderNumElement(data.favorites_num, t('favorite'), true)}
              {renderNumElement(data.like_num, t('like'), isReport)}
              {isReport ? renderNumElement(data.booked_num || 0, t('signUp')) : null}
            </Text>
          )}
        </div>
        <Text className={styles.reportOutputAt}>{data.output_at.split(' ')[0]}</Text>
      </div>

      <div className={styles.reporterInfo}>
        <div className={styles.reporterList}>
          {(!data.type || internalReport === data.type) && !isStaffUser && renderWriter(data?.write_by || [])}
          { (data.type && internalReport !== data.type)
          && !isStaffUser && renderWriter([data?.write_by[0], data?.charge_by[0]])}
        </div>
        <ActionBar
          likeNum={data.like_num}
          collectNum={data.favorites_num}
          isLike={operateData?.like || false}
          isFavorites={operateData?.favorites || false}
          isPublic={operateData?.public_booked || false}
          isSpecial={operateData?.special_booked || false}
          needShare={!isReport}
          needClass={isReport}
          needDownload={window.location.pathname === '/single-product/report-detail'}
          data={data}
          auth={auth}
          id={data.id as unknown as number}
          title={data.title}
          onFavorite={onFavorite}
          onLike={onLike}
          onPublicClass={onPublicClass}
          onSpecialClass={onSpecialClass}
          onShare={onShare}
          mobilePath={mobilePath}
        />
      </div>
      <span className={styles.divide} />
      <h3 className={styles.subTitle}>{t('abstract')}</h3>
      <section className={`${styles.section} ${styles.introSection}`}>
        {data.introduction_tag
          ?.replace(/\n/g, '<br/>')
          .split('<br/>')
          .map((item) => (
            <div
              key={item}
              className={styles.marker}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: item }}
            />
          ))}
      </section>
    </>
  );
};

export default BaseInfo;
