import replaceAll from 'string.prototype.replaceall';

type ReplaceValue = (substring: string, ...args: any[]) => string;

const polyfill = () => {
  if (!String.prototype.replaceAll) {
    // eslint-disable-next-line no-extend-native
    String.prototype.replaceAll = function replaceAllFn(
      searchValue: string | RegExp, replaceValue: string | ReplaceValue,
    ) {
      return replaceAll(this, searchValue, replaceValue);
    };
  }
};

export default polyfill;
