import React, {
  FC, useCallback, useState,
} from 'react';
import QRCode from 'qrcode';
import * as clipboard from 'clipboard-polyfill';
import { useTranslation } from 'react-i18next';
import { UserInfoContext } from '@/context';
import { Button, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import styles from '../index.module.less';

interface ShareContentProps {
  mobilePath?: string,
  title: string,
  shareLink?: string,
  onClose: () => void,
}

const ShareContent: FC<ShareContentProps> = ({
  mobilePath, title, onClose, shareLink,
}: ShareContentProps) => {
  const { t } = useTranslation();
  const { isStaffUser } = UserInfoContext.useContainer();
  const [downloadURL, setDownLoadURL] = useState();
  const QRCodeRef = useCallback((node) => {
    if (node !== null && mobilePath) {
      QRCode.toCanvas(node, mobilePath, {
        width: 150,
      }, () => {
        const url = node.toDataURL('image/png');
        setDownLoadURL(url);
      });
    }
  }, [mobilePath]);

  const downLoadQRCode = () => {
    const elementA = document.createElement('a');
    elementA.href = `${downloadURL}`;
    elementA.target = '_blank';
    elementA.download = `${title}.png`;
    elementA.click();
  };

  const copyLink = () => {
    clipboard.writeText(shareLink || window.location.href);
    message.success(`${t('copied')}~`);
  };

  const copyHTMLElement = () => {
    const item = new clipboard.ClipboardItem({
      'text/html': new Blob(
        // eslint-disable-next-line max-len
        [`<p><span style="color: rgba(0, 0, 0, 0.75);"><strong>▷ 阅读<a target="_blank" href="${window.location.href}" rel="noopener">完整版报告</a>以及更多研究报告请前往<a target="_blank" href="${window.location.origin}" rel="noopener">游戏市场洞察平台</a></strong></span></p>`],
        { type: 'text/html' },
      ),
    });
    clipboard.write([item]);
    message.success(`${t('copied')}~`);
  };

  return (
    <div className={styles.shareContainer}>
      <div className={styles.shareContentTitle}>
        {t('share')}
        <CloseOutlined className={styles.shareContentCloseButton} onClick={onClose} />
      </div>
      <div className={styles.shareContentBody}>
        {mobilePath && (
          <div className={styles.shareContentQRCodeContent}>
            <canvas ref={QRCodeRef} />
          </div>
        )}
        <div className={styles.shareContentLinkContent}>
          <h4 className="mur-title">链接</h4>
          <p className={styles.shareContentLink}>
            {shareLink || `${window.location.href}`}
          </p>
          <Button
            type="primary" onClick={copyLink}
            className={styles.button}
          >
            {shareLink ? '复制信号链接' : t('copy_link')}
          </Button>
          {
            !shareLink && (
              <>
                <Button
                  type="default" onClick={downLoadQRCode}
                  className={styles.button}
                >
                  {t('download_qr_code')}
                </Button>
                {!isStaffUser && (
                <Button
                  onClick={copyHTMLElement}
                >
                  复制KM分享链接
                </Button>
                )}
              </>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default ShareContent;
