/* eslint-disable camelcase */
const ALL_GROUP_ID = 0;
const BASE_USER_GROUP_NAME = '基础用户组';

const DEPARTMENT_TYPE = 1;
const STAFF_TYPE = 2;

const INTERNAL_USER_GROUP_TYPE = 0;
const ADMIN_USER_GROUP_TYPE = 1;
const EXTERNAL_USER_GROUP_TYPE = 3;

const WAIT_USE_PERM = 1;
const USED_PERM = 0;

const ADMIN_GROUP_PREM = 'admin';
const NORMAL_GROUP_PREM = 'normal';
const ALL_GROUP_PREM = 'all';

const TABS_GROUP: {
  key: string, name: string,
}[] = [
  {
    key: '4',
    name: '订阅用户',
  },
  {
    key: '0',
    name: '免费用户',
  },
  {
    key: '3',
    name: '外部用户',
  },
  {
    key: '1',
    name: '管理员',
  },
];

export default {
  allGroupId: ALL_GROUP_ID,
  baseUserGroupName: BASE_USER_GROUP_NAME,
  departmentType: DEPARTMENT_TYPE,
  staffType: STAFF_TYPE,
  internalUserGroupType: INTERNAL_USER_GROUP_TYPE,
  adminUserGroupType: ADMIN_USER_GROUP_TYPE,
  externalUserGroupType: EXTERNAL_USER_GROUP_TYPE,
  waitUsePerm: WAIT_USE_PERM,
  usedPerm: USED_PERM,
  adminGroupPrem: ADMIN_GROUP_PREM,
  normalGroupPrem: NORMAL_GROUP_PREM,
  allGroupPrem: ALL_GROUP_PREM,
  tabsGroup: TABS_GROUP,
};
