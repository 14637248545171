import React, { FC, CSSProperties } from 'react';
import configs from '@/configs';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import styles from './index.module.less';

const internalReport = configs('report.internalReport');

interface ExternalBadgeProps {
  type?: string,
  style?: CSSProperties,
  className?: string,
  isCard?: boolean,
}

const ExternalBadge: FC<ExternalBadgeProps> = ({
  type, style, className, isCard = true,
}: ExternalBadgeProps) => {
  const { t } = useTranslation();
  const isExternalReport = (type && type !== internalReport);
  return (
    <>
      {isExternalReport ? (
        <span
          className={classnames(className, styles.externalBadge, { [styles.card]: isCard })} style={style}
        >
          {t('external')}
        </span>
      ) : null}
    </>
  );
};

export default ExternalBadge;
