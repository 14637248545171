/* eslint-disable react/button-has-type */
import React, { FC, useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Upload, Form, message, Typography,
} from 'antd';
import COS from 'cos-js-sdk-v5';
import {
  UploadOutlined, CloseOutlined,
} from '@ant-design/icons';
import { getCosAuth } from '@/services/video-manage';
import { getReportCosAuth } from '@/services/report-library-admin';
import styles from './index.module.less';

const { Text } = Typography;

interface UploadProps {
  initialValues?: string,
  reset: boolean,
  tips: string,
  accept: string,
  uploadMaxSize?: number,
  name?: string,
  productId?: number,
  onFinishUpload?: (v: string) => void,
}
const CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

const BigFormUpload: FC<UploadProps> = ({
  tips, accept, initialValues, reset, uploadMaxSize = 2048,
  name = 'media', productId, onFinishUpload,
}: UploadProps) => {
  const [pdfList, setPdfList] = useState<any[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    if (initialValues && !reset) {
      setPdfList([{
        name: initialValues, uid: '1', size: 0, type: '',
      }]);
    } else {
      setPdfList([]);
    }
  }, [initialValues, reset]);
  // 文件上传props
  const props: any = {
    maxCount: 1,
    accept,
    name: 'file',
    beforeUpload(file: any) {
      const isLtMaxSize = file.size / 1024 / 1024 < uploadMaxSize;
      if (!isLtMaxSize) {
        message.error(`上传文件不能超过 ${uploadMaxSize}MB!`);
        return Promise.reject();
      }
      return true;
    },
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: <CloseOutlined className={styles.tipsIcon} />,
    },
    progress: {
      strokeColor: {
        '0%': '#495ea3',
        '100%': '#495ea3',
      },
      format: (percent: number) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    onChange(info: any) {
      const isLtMaxSize = info?.file?.size / 1024 / 1024 < uploadMaxSize;
      if (!isLtMaxSize) {
        return;
      }
      if (info.file.status === 'removed') {
        setPdfList([]);
      } else if (info.file.status === 'error') {
        setPdfList([{ ...info.file, error: { message: '上传错误' } }]);
      } else {
        setPdfList([info.file]);
      }
    },
    async customRequest(option: any) {
      const file = option.file as File;
      const cosData = name === 'media' ? await getCosAuth() : await getReportCosAuth(productId);
      const { credentials, bucket, region } = cosData;
      const cos = new COS({
        getAuthorization(options, callback) {
          callback({
            TmpSecretId: credentials.tmpSecretId,
            TmpSecretKey: credentials.tmpSecretKey,
            SecurityToken: credentials.sessionToken,
            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
            StartTime: cosData.startTime, // 时间戳，单位秒，如：1580000000
            ExpiredTime: cosData.expiredTime, // 时间戳，单位秒，如：1580000000
          });
        },
      });
      const fileSuffix = file.name.split('.')[file.name.split('.').length - 1];
      cos.uploadFile({
        Bucket: bucket,
        Region: region,
        Key: name === 'media'
          ? `media/mp4/${_.sampleSize(CHARSET, 27).join('')}${new Date().getTime()}.mp4`
          : `pdf/${_.sampleSize(`${CHARSET}${new Date().getTime()}`, 40).join('')}.${fileSuffix}`,
        Body: file,
        SliceSize: 1024 * 1024 * 5,
        onProgress(progressData) {
          option.onProgress({ percent: progressData.percent * 100 });
        },
        onFileFinish(err, data) {
          if (err) {
            option.onError(err);
          } else {
            option.onSuccess(data);
            if (onFinishUpload && data.Location) {
              onFinishUpload(data.Location);
            }
          }
        },
      });
    },
  };

  if (productId) {
    props.headers = {
      szid: String(productId),
    };
  }

  const validateFileUpload = () => {
    if (pdfList && pdfList.length > 0) {
      setIsError(false);
      return Promise.resolve();
    }
    setIsError(true);
    return Promise.reject(new Error('请上传附件!'));
  };

  return (
    <Form.Item
      className={styles.bigFileUpload}
      label="上传附件"
      name={name}
      rules={[{
        required: true,
        validator: () => validateFileUpload(),
      }]}
      normalize={(value) => {
        const { response, status } = value.file;
        if (status === 'error') {
          return '';
        }
        if (response) {
          return response.Location;
        }
        return value.file;
      }}
    >
      <Upload
        {...props}
        fileList={pdfList}
      >
        <Button className={isError ? styles.uploadError : ''}>
          <UploadOutlined />
          {' '}
          上传文件
        </Button>
        {pdfList && pdfList.length === 0 && (
          <Text className={styles.uploadTipsText}>
            {tips}
          </Text>
        )}
      </Upload>
    </Form.Item>
  );
};

export default BigFormUpload;
