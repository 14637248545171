import React, { FC, ReactNode, useState } from 'react';
import { Button, Input } from 'antd';
import styles from './index.module.less';

interface Questions {
  type: 'rate' | 'textarea' | 'radio' | 'checkbox',
  title: string | ReactNode,
  getValue: (v: string | number) => void,
  rateValue?: number,
  placeholder?: string,
}

interface FeedbackSurveyProp {
  hasButton?: boolean
  buttonText?: string,
  onClick?: () => void,
  content?: Questions[],
}

const FeedbackSurvey: FC<FeedbackSurveyProp> = ({
  hasButton, buttonText, content, onClick,
}: FeedbackSurveyProp) => {
  const [rateValue, setRateValue] = useState<number>(0);
  const rateList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div>
      {content?.map((item: Questions) => (
        <div>
          <div className={styles.title}>{item.title}</div>
          {item.type === 'rate' && (
            <div className={styles.describe}>
              <span>非常不满意</span>
              <span style={{ marginLeft: '32px' }}>一般</span>
              <span>非常满意</span>
            </div>
          )}
          {item.type === 'rate' && (
            <ul className={styles.rate}>
              {rateList.map((i) => (
                <span
                  className={styles.rateSpan}
                  style={{
                    color: i === rateValue ? '#fff' : 'rgba(0, 0, 0, 0.65)',
                    background: i === rateValue ? '#2B4096' : 'rgba(0, 0, 0, 0.04)',
                  }}
                  onClick={() => {
                    item.getValue(i);
                    setRateValue(i);
                  }}
                >
                  {i}
                </span>
              ))}
            </ul>
          )}
          {item.type === 'textarea' && (
            <Input.TextArea
              placeholder={item.placeholder || ''}
              onChange={(e) => item.getValue(e.target.value)}
              maxLength={200}
              style={{ height: 80 }}
            />
          )}
        </div>
      ))}
      {hasButton && (
        <Button
          onClick={() => onClick?.()}
          type="primary"
          className={styles.button}
          disabled={!rateValue}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default FeedbackSurvey;
