import { useState } from 'react';
import { createContainer } from 'unstated-next';

function useSiderMenuCollapse() {
  const [isCollapsed, setCollapsed] = useState<boolean>(false);

  function changeCollapsed(collapsedVal: boolean) {
    setCollapsed(collapsedVal);
  }

  return {
    isCollapsed,
    changeCollapsed,
  };
}

const SiderMenuCollapse = createContainer(useSiderMenuCollapse);

export default SiderMenuCollapse;
