import React, { ReactNode } from 'react';
import { Menu } from 'antd';
import { MenuTreeData } from '@/services/menu';
import { AdminMenuData } from '@/services/common';
import { IconfontCN } from '@/components';
import { SubMenuProps } from 'antd/lib/menu/SubMenu';
import baseSubs from '@/assets/images/baseSubs.svg';
import subsIcon from '@/assets/images/subsIcon.svg';
import styles from './index.module.less';

interface OnMenuChangeArgument {
  id: number,
  item: MenuTreeData | AdminMenuData,
  openKeys: string[],
  paid?: number | null,
}

interface LeftMenuProps {
  menuTree: MenuTreeData | AdminMenuData | null,
  defaultSelectedKeys?: string[],
  onMenuChange?: ({ id, item, openKeys }: OnMenuChangeArgument) => void,
  defaultOpenKeys?: string[],
  onSubMenuChange?: (openKeys: string[]) => void,
  theme?: 'light' | 'dark',
  className?: string,
  expandIcon?: ReactNode |((props: SubMenuProps & { isSubMenu: boolean }) => ReactNode),
  onMenuSelect?: (value: any) => void,
  selectId?: string,
  platform?: string,
  isPay?: boolean,
}

const { SubMenu } = Menu;

const renderIcon = (icon: string) => (
  <IconfontCN className={styles.menuIcon} type={icon} />
);

interface RenderMenu<T> {
  menuList: T[],
  isPrimary?: boolean,
  onMenuChange?: ({
    id, item, openKeys, paid,
  }: OnMenuChangeArgument) => void,
  selectId?: string,
  platform?: string,
  isPay?: boolean,
}

export const renderMenu = ({
  menuList, isPrimary = true, onMenuChange, selectId, platform, isPay,
}: RenderMenu<MenuTreeData | AdminMenuData>) => menuList.map((item) => {
  if (item.children?.length) {
    return (
      <SubMenu
        key={item.id}
        title={item.name?.replace('{{mobile}}', '')?.replace('{{hd}}', '')}
        icon={item.icon && isPrimary ? renderIcon(item.icon) : ''}
      >
        {renderMenu({
          menuList: item.children,
          isPrimary: false,
          onMenuChange,
          selectId,
          platform,
          isPay,
        })}
      </SubMenu>
    );
  }

  const handleMenuClick = (
    id: number, menuItem: MenuTreeData | AdminMenuData, openItem: any, paid?: number | null,
  ) => {
    const { openKeys } = openItem.props;

    if (onMenuChange) {
      onMenuChange({
        id, item: menuItem, openKeys, paid,
      });
    }
  };

  if ((platform === '移动端' && item.name?.includes('{{hd}}'))
  || (platform === 'HD端' && item.name?.includes('{{mobile}}'))) {
    return null;
  }
  const showName = item.name?.replace('{{mobile}}', '')?.replace('{{hd}}', '');
  return (
    <Menu.Item
      key={item.id}
      onClick={({
        item: openItem,
      }) => handleMenuClick(item.id as number, item, openItem, item.paid_user_only)}
      icon={item.icon && isPrimary ? renderIcon(item.icon) : ''}
      className={(Number(selectId) === item.id) ? styles.iconSelected : undefined}
    >
      <span className={styles.subsName}>{showName}</span>
      {!!item.paid_user_only && (
        <img
          alt=""
          src={isPay ? subsIcon : baseSubs}
          className={isPay ? styles.subsIcon : styles.unSubsIcon}
        />
      )}
    </Menu.Item>
  );
});

const LeftMenu = ({
  menuTree, defaultSelectedKeys, defaultOpenKeys, onMenuChange, onSubMenuChange, onMenuSelect,
  theme = 'light', className, expandIcon, selectId, platform, isPay,
}: LeftMenuProps) => {
  if (!menuTree) {
    return null;
  }

  const handleMenuChange = ({
    id, item, openKeys, paid,
  }: OnMenuChangeArgument) => {
    if (onMenuChange) {
      onMenuChange({
        id, item, openKeys, paid,
      });
    }
  };

  const handleSubMenuChange = (value: any) => {
    if (onSubMenuChange) {
      onSubMenuChange(value);
    }
  };

  const handleMenuSelect = (value: any) => {
    if (onMenuSelect) {
      onMenuSelect(value);
    }
  };
  return (
    <>
      <Menu
        mode="inline" theme={theme}
        selectedKeys={defaultSelectedKeys}
        defaultOpenKeys={defaultOpenKeys}
        onOpenChange={handleSubMenuChange}
        className={className}
        expandIcon={expandIcon}
        onSelect={handleMenuSelect}
      >
        {renderMenu({
          menuList: menuTree.children,
          onMenuChange: handleMenuChange,
          selectId,
          platform,
          isPay,
        })}
      </Menu>
    </>
  );
};

export default LeftMenu;
