import React from 'react';
import { Translation } from 'react-i18next';
import classnames from 'classnames';
import { InternalNetwork } from '../network';
import styles from './index.module.less';

const Suggest = () => (
  <InternalNetwork>
    <a
      href="https://tucao.oa.com/products/175142"
      target="_blank"
      rel="noreferrer noopener"
      className={classnames(styles.tucao, styles.hoverButton)}
    >
      <div className={styles.logo} />
      <p>
        <Translation>
          {(t) => t('suggest')}
        </Translation>
      </p>
    </a>
  </InternalNetwork>
);

export default Suggest;
