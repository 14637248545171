import { createContainer } from 'unstated-next';
import { getInfo } from '@/services/users';
import configs from '@/configs';
import { useFetch } from '@/hooks';
import { useEffect, useState } from 'react';
import Beacon from './beacon';

function useUserInfo() {
  const { beacon } = Beacon.useContainer();
  const { data, refresh } = useFetch(getInfo);
  const outAdminUser = configs('outNet.outAdminUser');
  const outNormalUser = configs('outNet.outNormalUser');
  const [isPayingUsers, setIsPayingUsers] = useState(false);
  const [isIEGUsers, setIsIEGUsers] = useState(false);

  useEffect(() => {
    if (data) {
      beacon.setOpenId(data.user_id);
      setIsPayingUsers(!!data?.group?.filter((item) => item.type === 4)?.length);
      setIsIEGUsers(!!data?.department?.includes('IEG'));
    }
  }, [beacon, data]);

  return {
    info: data,
    // eslint-disable-next-line camelcase
    isStaffUser: !!(data?.staff_type_id === outAdminUser || data?.staff_type_id === outNormalUser),
    // eslint-disable-next-line camelcase
    isOutAdminUser: !!(data?.staff_type_id === outAdminUser),
    isPayingUsers,
    isIEGUsers,
    refresh,
  };
}

const UserInfo = createContainer(useUserInfo);

export default UserInfo;
