import DepartmentSelectForm from './department-select';
import FormAuthority from './authority';
import FormBaseInfo from './base-info';
import FormTagSelect from './tag-select';
import FormUpload from './upload';
import BigFormUpload from './bigFileUpload';
import FormCoverUpload from './cover-upload';
import MenuSelectForm from './menu-select-form';
import ReportFormCoverUpload from './report-cover-upload';
import ReportFormWriter from './report-writer';
import StaffSelectForm from './staff-form';
import UploadResult from './upload-result';

export default {
  DepartmentSelectForm,
  FormAuthority,
  FormBaseInfo,
  FormTagSelect,
  FormUpload,
  FormCoverUpload,
  MenuSelectForm,
  ReportFormCoverUpload,
  ReportFormWriter,
  StaffSelectForm,
  UploadResult,
  BigFormUpload,
};
