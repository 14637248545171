import React, { FC } from 'react';
import { Form } from 'antd';
import { validateRtx } from '@/utils/helper/form';
import { RtxSelect } from '@/components';
import { FormInstance } from 'antd/lib/form';

interface StaffSelectProps {
  required: boolean,
  form: FormInstance,
  initialValues?: string[],
  disabled?: boolean,
}

const StaffSelect: FC<StaffSelectProps> = ({
  required, form, initialValues, disabled = false,
}: StaffSelectProps) => (
  <Form.Item
    label="成员名称" name="name"
    rules={[
      { required, message: '请选择成员' },
      { required, validator: () => validateRtx(form, 'name') },
    ]}
  >
    <RtxSelect
      initialValues={initialValues}
      disabled={disabled}
      placeholder="请输入中文名称，rtx英文或者邮箱格式"
      compatible
    />
  </Form.Item>
);

export default StaffSelect;
