/* eslint-disable camelcase */
import { productRequest, removeObjectFalsyKey } from '@/utils/helper/base';
import {
  UserGroupItemData, UserGroupEmployeesParams,
  UpdateUserGroupEmployeesPayload, DependOrgPayload, DependOrgData,
} from '@/services/user-group';
import { UserInfoData } from '@/services/response.type';

export interface Group {
  group_name: string,
  gid: number,
}

export interface UserGroupEmployeesItem extends UserInfoData {
  department: string[],
  join_at: string,
  group: Group[],
  gid: number[],
  user_group_id: number[],
}

export interface UserGroupEmployees {
  data: UserGroupEmployeesItem[] | [],
  total: number,
  last_page: number
}

export async function getUserGroup(productId: number, role: 'normal'): Promise<UserGroupItemData[]> {
  const res = await productRequest(`/api/szadmin/group/list/${role}`, productId);
  return res.data;
}

export async function updateUserGroup(
  productId: number, type: number, name: string, id?: number,
): Promise<UserGroupItemData | boolean> {
  const res = await productRequest(
    '/api/szadmin/group/store', productId,
    { method: 'post', data: { name, id, type } },
  );
  return res.data;
}

export async function deleteUserGroup(productId: number, id: number) {
  const res = await productRequest(
    '/api/szadmin/group/del', productId,
    { method: 'delete', data: { id: [id] } },
  );
  return res.data;
}

export async function getUserGroupEmployees(
  productId: number, params: UserGroupEmployeesParams,
): Promise<UserGroupEmployees> {
  const res = await productRequest(
    `/api/szadmin/group/memberList?${new URLSearchParams(removeObjectFalsyKey(params))}`, productId,
  );

  return res.data;
}

export async function addProductEmployees(
  productId: number, payload: UpdateUserGroupEmployeesPayload,
): Promise<Boolean> {
  const res = await productRequest(
    '/api/szadmin/group/memberAdd', productId,
    { method: 'post', data: payload },
  );

  return res.data;
}

export async function deleteProductEmployees(productId: number, id: number[]) {
  await productRequest(
    '/api/szadmin/group/memberDel', productId,
    {
      method: 'delete',
      data: { id },
    },
  );

  return true;
}

export async function updateProductEmployees(
  productId: number, payload: UpdateUserGroupEmployeesPayload,
): Promise<Boolean> {
  const res = await productRequest(
    '/api/szadmin/group/memberEdit', productId,
    { method: 'post', data: payload },
  );

  return res.data;
}

export async function getProductDependOrg(productId: number, id: number): Promise<DependOrgData | null> {
  const res = await productRequest(`/api/szadmin/group/getDependOrg/${id}`, productId);

  return res.data;
}

export async function addProductDependOrg(productId: number, payload: DependOrgPayload): Promise<boolean> {
  const res = await productRequest(
    '/api/szadmin/group/dependOrgEdit', productId,
    { method: 'post', data: payload },
  );

  return res.data;
}

export async function deleteProductDependOrg(productId: number, id: number): Promise<boolean> {
  const res = await productRequest(
    '/api/szadmin/group/dependOrgDel', productId,
    { method: 'delete', data: { depend_id: [id] } },
  );

  return res.data;
}
