/* eslint-disable quotes */
/* eslint-disable no-template-curly-in-string */

// antd 全局配置
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import enGB from 'antd/lib/locale/en_GB';
import { CURRENT_LANGUAGE } from '@/i18n';

interface Locales {
  [key: string]: any,
}

const locales: Locales = {
  zh: zhCN,
  en: enGB,
};

const formTypeTemplate = '不是合法的 ${type} 类型';

export default {
  form: {
    validateMessages: {
      default: 'Validation error on field \'${name}\'',
      required: '请填写 \'${label}\'',
      types: {
        string: formTypeTemplate,
        method: formTypeTemplate,
        array: formTypeTemplate,
        object: formTypeTemplate,
        number: formTypeTemplate,
        date: formTypeTemplate,
        boolean: formTypeTemplate,
        integer: formTypeTemplate,
        float: formTypeTemplate,
        regexp: formTypeTemplate,
        email: formTypeTemplate,
        url: formTypeTemplate,
        hex: formTypeTemplate,
      },
    },
  },
  locale: locales[CURRENT_LANGUAGE],
};
