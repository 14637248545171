const MULTI_SELECT = 0;
const SINGLE_SELECT = 1;
const CASCADER = 2;
const REPORT_LABEL_TYPE = 0;
const CULTURAL_SIGNALS_TYPE = 1;
const VIDEO_LABEL_TYPE = 2;

export default {
  singleSelect: SINGLE_SELECT,
  multiSelect: MULTI_SELECT,
  cascader: CASCADER,

  selects: [{
    key: SINGLE_SELECT,
    label: '单选',
  }, {
    key: MULTI_SELECT,
    label: '多选',
  }, {
    key: CASCADER,
    label: '多级单选',
  }],

  reportLabelType: REPORT_LABEL_TYPE,

  culturalSignalsType: CULTURAL_SIGNALS_TYPE,

  videoLabelType: VIDEO_LABEL_TYPE,
};
