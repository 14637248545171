const APPROVAL_GOING_STATUS = 0;
const APPROVAL_SUCCESS_STATUS = 1;
const APPROVAL_FAILED_STATUS = 2;
const OPERATE_APPROVAL_PASS_STATUE = 1;
const OPERATE_APPROVAL_REJECT_STATUS = 2;

interface OperateTextObject {
  [key: number]: string
}

const approvalOperateText: OperateTextObject = {
  0: 'no_response',
  1: 'agreed',
  2: 'disagreed',
  3: 'applied',
};

export interface ApprovalStatusRenderStyles {
  style: string,
  label: string,
  status: number,
}

const approvalStatusStyles: ApprovalStatusRenderStyles[] = [
  { style: 'mur-table-complete-status', label: 'completed', status: 1 },
  { style: 'mur-table-refuse-status', label: 'rejected', status: 2 },
  { style: 'mur-table-ongoing-status', label: 'in_progress', status: 0 },
  { style: 'mur-table-complete-status', label: 'completed', status: 3 },
];

const UNAUTHORIZED_TYPE = 0;
const AUTHORIZED_TYPE = 1;
const UNAUTHORIZED_APPLIED_TYPE = 2;

export default {
  approvalSuccessStatus: APPROVAL_SUCCESS_STATUS,
  approvalFailedStatus: APPROVAL_FAILED_STATUS,
  approvalGoingStatus: APPROVAL_GOING_STATUS,
  approvalOperateText,
  approvalStatusStyles,
  operateApprovalPassStatus: OPERATE_APPROVAL_PASS_STATUE,
  operateApprovalRejectStatus: OPERATE_APPROVAL_REJECT_STATUS,
  unauthorizedType: UNAUTHORIZED_TYPE,
  authorizedType: AUTHORIZED_TYPE,
  unauthorizedAppliedType: UNAUTHORIZED_APPLIED_TYPE,
};
