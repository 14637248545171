/* eslint-disable camelcase */
import { message } from 'antd';
import configs from '@/configs';
import { updateMenuOrder, UpdateMenuPayload } from '@/services/menu';
import { updateProductMenuOrder } from '@/services/single-product/admin/menu';
import { UserGroupItemData } from '@/services/user-group';

const DIRECTORY_TYPE = configs('menu.directoryType');
const URL_TYPE = configs('menu.urlType');
const specialPerm = configs('menu.specialPerm');
const ALL_GROUP_ID = configs('user-group.allGroupId');
const BASE_USER_GROUP_NAME = configs('user-group.baseUserGroupName');

export function isUrlType(type: number) {
  return type === URL_TYPE;
}

export function isDirectoryType(type: number) {
  return type === DIRECTORY_TYPE;
}

export function isSpecialPermType(type: number) {
  return type === specialPerm;
}

export function validateDropEffective(drop: any): Boolean {
  const { node, dropToGap } = drop;

  if (isUrlType(node.data.type) && !dropToGap) {
    message.error('不能拖动到链接上，请拖动到链接节点的前面或后面');
    return false;
  }

  return true;
}

export function dropToNeighbor(drop: any, productId?: number) {
  const { node, dragNode, dropPosition } = drop;
  const targetMenuId = dragNode.data.id;
  const neighborMenuIndex = node.index;
  const neighborMenuId = node.data.id;

  if (dropPosition > neighborMenuIndex) {
    const info: UpdateMenuPayload = {
      insertType: 'after',
      targetMenuId,
      neighborOrParentId: neighborMenuId,
    };
    return productId ? updateProductMenuOrder(productId, info) : updateMenuOrder(info);
  }

  if (dropPosition < neighborMenuIndex) {
    const info: UpdateMenuPayload = {
      insertType: 'before',
      targetMenuId,
      neighborOrParentId: neighborMenuId,
    };
    return productId ? updateProductMenuOrder(productId, info) : updateMenuOrder(info);
  }

  return false;
}

export function dropToChildren(drop: any, productId?: number) {
  const {
    node, dragNode, dropPosition, dropToGap,
  } = drop;
  const targetMenuId = dragNode.data.id;
  const neighborMenuIndex = node.index;
  const neighborMenuId = node.data.id;

  if (dropToGap) {
    return dropToNeighbor(drop, productId);
  }

  if (dropPosition >= neighborMenuIndex) {
    const info: UpdateMenuPayload = {
      insertType: 'nested',
      targetMenuId,
      neighborOrParentId: neighborMenuId,
    };
    return productId ? updateProductMenuOrder(productId, info) : updateMenuOrder(info);
  }

  return false;
}

export function removeAllUserGroupId(ids: number[]): number[] {
  if (ids.includes(ALL_GROUP_ID)) {
    return ids.filter((item) => item !== ALL_GROUP_ID);
  }
  return ids;
}

export function baseUserGroupFilter(userGroup: UserGroupItemData[]): UserGroupItemData[] {
  return userGroup.filter((item) => item.name !== BASE_USER_GROUP_NAME);
}
