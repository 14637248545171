/* eslint-disable react/jsx-props-no-spreading */
import React, {
  FC, useEffect, useState,
} from 'react';
import {
  Form, message, Select, Spin, Tooltip, Tag,
} from 'antd';
import _ from 'lodash';
import { getTagList, TagItem } from '@/services/report-library';
import { useFetch } from '@/hooks';
import { ExclamationCircleOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormInstance } from 'antd/lib/form';
import { validateSignalTagLength } from '@/utils/helper/form';
import styles from './index.module.less';

const { Option } = Select;

interface TagSelectProps {
  size?: number,
  serviceFn?: (word: string, size: number) => Promise<TagItem[]>,
  tagList?: string[],
  form: FormInstance,
  onChange?: Function,
  needToolTip?: boolean,
  errorMessage?: string,
  placeholder?: string,
  label?: string,
  require?: boolean,
  isSignal?: boolean, // 判断是否校验信号标签长度
  reset?: boolean,
}

const TagSelect: FC<TagSelectProps> = ({
  size = 5, serviceFn, tagList, form, onChange, needToolTip = true,
  errorMessage = '请输入报告标签!', placeholder = '请输入报告标签',
  label = '报告标签', require = true, isSignal = false, reset = true,
}: TagSelectProps, ref: any) => {
  const [word, setWord] = useState('');
  const [optionData, setOptionData] = useState<TagItem[]>();
  const { data, loading } = useFetch<TagItem[]>(() => (serviceFn ? serviceFn(word, size) : getTagList(word, size)), {
    depends: [word],
    interrupt: word.length === 0,
  });

  const [dropList, setDropList] = useState<string[]>([]);
  // 修复新建报告同时form表单更新刷新tagList bug
  const [isFirst, setIsFirst] = useState<boolean>(true);

  useEffect(() => {
    if (reset) {
      setIsFirst(true);
    }
  }, [reset]);

  useEffect(() => {
    if (tagList && isFirst) {
      setDropList(tagList);
      setIsFirst(false);
    }
  }, [isFirst, tagList]);

  useEffect(() => {
    if (data) {
      setOptionData(data);
    }
  }, [data]);

  const [needInput, setNeedInput] = useState<boolean>(false);

  function handleChange(values: string[]) {
    if (onChange) {
      onChange(values, setWord);
    } else {
      const valuesLength = values.length;
      if (valuesLength) {
        const newValue = values[valuesLength - 1];
        const maxLength = 32;
        if (newValue.length > maxLength) {
          values.splice(valuesLength - 1, 1);
          setWord('');
          message.info(`最多${maxLength}个字符`);
        }
      }
    }
    setDropList(values);
  }

  const handleDropEnd = (result: any) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId
      && destination.index === source.index
    ) {
      return;
    }
    const copyList = [...dropList];

    const spliceValue = copyList.splice(source.index, 1);
    copyList.splice(destination.index, 0, spliceValue[0]);
    setDropList(copyList);
    form.setFieldsValue({
      tag: copyList,
    });
  };

  const handleDropDownVisible = (open: boolean) => {
    if (!open) {
      setOptionData([]);
      setWord('');
    }
  };

  const handleBlur = () => {
    setNeedInput(false);
  };

  const handleDelete = (index: number) => {
    const copyList = [...dropList];
    copyList.splice(index, 1);
    setDropList(copyList);
    form.setFieldsValue({
      tag: copyList,
    });
  };

  const renderOptions = (optionsList: TagItem[] | undefined) => {
    if (optionsList?.length === 0 && word) {
      return (
        <Option className={styles.tagAddOption} value={word}>
          <span className={styles.tagWord}>{word}</span>
          <span className={styles.tagAdd} style={{ fontWeight: 500 }}>新增</span>
        </Option>
      );
    }
    return optionsList?.map((item) => (
      <Option key={item.id} value={item.name}>
        {item.name}
        <span className={styles.tagNum}>{`(${item.num})`}</span>
      </Option>
    ));
  };

  return (
    <div>

      <Form.Item
        label={label}
        required={require}
        className={styles.formWrapper}
      >
        {!needInput ? (
          <Form.Item
            name="tag"
            className={needToolTip ? styles.tipsFormItem : styles.shortFormItem}
            rules={[
              { required: require, message: errorMessage },
              { validator: () => validateSignalTagLength(isSignal, form, 'tag') },
            ]}
          >
            <DragDropContext onDragEnd={handleDropEnd}>
              <Droppable
                direction="horizontal"
                droppableId="labels" type="tags"
              >
                {(dropProvided) => (
                  <div
                    className={styles.dropInput}
                    ref={dropProvided.innerRef}
                    {...dropProvided.droppableProps}
                  >
                    {dropList.map((item, index) => (
                      <Draggable
                        draggableId={`tags_${item}`}
                        index={index}
                        key={`tags_${item}`}
                      >
                        {(provided) => (
                          <Tag
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps} key={item}
                          >
                            <span>
                              {item}
                              <CloseOutlined onClick={() => handleDelete(index)} className={styles.tagClose} />
                            </span>
                          </Tag>
                        )}
                      </Draggable>
                    ))}
                    <Tag
                      onClick={() => setNeedInput(true)}
                      className={styles.siteTagPlus}
                    >
                      <PlusOutlined className={styles.siteTagPlusIcon} />
                      标签
                    </Tag>
                    {dropProvided.placeholder}
                  </div>
                )}
              </Droppable>

            </DragDropContext>
          </Form.Item>
        ) : (
          <Form.Item
            name="tag" className={needToolTip ? styles.tipsFormItem : styles.shortFormItem}
            rules={[
              { required: require, message: errorMessage },
              { validator: () => validateSignalTagLength(isSignal, form, 'tag') },
            ]}
          >

            <Select
              ref={ref}
              placeholder={placeholder}
              mode="multiple"
              showArrow={false}
              filterOption={false}
              onSearch={_.debounce(setWord, 500)}
              onChange={handleChange}
              onDropdownVisibleChange={(open) => handleDropDownVisible(open)}
              onBlur={handleBlur}
              notFoundContent={loading ? <Spin size="small" /> : null}
              autoFocus
              optionLabelProp="label"
            >
              {renderOptions(optionData)}
            </Select>
          </Form.Item>
        )}
        {needToolTip && (
        <Tooltip
          placement="right" title="可快速定位报告的关键词，或上面无法覆盖到的关键标签"
        >
          <ExclamationCircleOutlined className={styles.tipsIcon} />
        </Tooltip>
        )}

      </Form.Item>
    </div>
  );
};

export default TagSelect;
