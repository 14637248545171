/* eslint-disable camelcase */
import request from '@/utils/request';
import { TableBaseParams } from '@/configs/type';
import { User } from '@/services/report-library-admin';
import { WriterOrCharger, UserInfoData } from './response.type';

export interface AdminVideoListParams extends TableBaseParams {
  title?: string,
  output_by?: string,
}

export interface AdminVideoListItem {
  id: number;
  title: string;
  introduction: string;
  type: string;
  media_path?: string;
  created_by: User;
  charge_by: Array<User>;
  write_by: Array<User>;
  search_authority: number;
  read_authority: number;
  [x: string]: any,
}

export interface AdminVideoList {
  data: AdminVideoListItem[];
  total: number;
  last_page: number;
}

export interface Credentials {
  sessionToken: string,
  tmpSecretId: string,
  tmpSecretKey: string
}

export interface COSRequest {
  expiredTime: number,
  expiration?: number,
  credentials: Credentials,
  requestId?: string,
  startTime: number,
  bucket: string,
  region: string,
}

interface AuthGroup {
  id: number,
  created_at: string,
  group_name: string,
  updated_at: string,
}

export interface MediaBaseDetail {
  id?: number,
  title: string,
  introduction?: string,
  charge_by: WriterOrCharger[],
  output_by: WriterOrCharger[],
  search_authority: number,
  read_authority: number,
  created_at?: string,
  output_at: string,
  cover_pic?: string,
  cover_pic_type: number,
}

export interface MediaDetail extends MediaBaseDetail {
  search_group: AuthGroup[],
  search_user: UserInfoData[],
  read_group: AuthGroup[],
  read_user: UserInfoData[],
  created_by: User[],
}

export interface MediaFrontBaseDetail {
  id?: number,
  title: string,
  cover_pic: string,
  media_path: string
  read_auth: number,
  media_task?: {
    vvt: string,
  }
}

export async function getAdminVideoList(payload: AdminVideoListParams): Promise<AdminVideoList> {
  const res = await request.get('/api/adminapi/media/list', { params: { ...payload } });
  return res.data;
}

export async function getCosAuth(): Promise<COSRequest> {
  const res = await request.get('/api/adminapi/media/cosTmpKey');
  return res.data;
}

export async function updateMedia(payload: FormData): Promise<number | boolean> {
  const res = await request.post('/api/adminapi/media/edit', payload, { timeout: 0 });

  return res.data;
}

export async function getMediaEditDetail(rid: string): Promise<MediaDetail> {
  const res = await request.get(`/api/adminapi/media/editPage/${rid}`);
  return res.data;
}

export async function deleteVideo(id: (number | string)[]) {
  await request.delete('/api/adminapi/media/del', { data: { id } });
  return true;
}

export async function getMediaDetail(id: string): Promise<MediaFrontBaseDetail> {
  const res = await request.get(`/api/webapi/media/preview/${id}`);
  return res.data;
}

export async function detectVideoId(mid: string): Promise<boolean> {
  const res = await request.get('/api/media/detect', { params: { mid } });
  return res.data;
}
