import React, { FC } from 'react';
import { Button } from 'antd';
import classnames from 'classnames';
import styles from './index.module.less';

interface ActionBarProp {
  onMultiDelete?: Function,
  onAdd: Function,
  disable: boolean,
  addButtonText?: string,
  delButtonText?: string,
  className?: string,
  delButtonVisible?: boolean,
}

const ActionBar: FC<ActionBarProp> = ({
  onMultiDelete, onAdd, disable,
  addButtonText = '添加', delButtonText = '删除',
  className, delButtonVisible = true,
}: ActionBarProp) => (
  <div className={classnames(styles.actionBar, className)} data-testid="container">
    <Button
      type="primary"
      className={styles.actionBarButton}
      onClick={() => onAdd()}
      data-testid="addButton"
    >
      {addButtonText}
    </Button>
    {delButtonVisible && (
    <Button
      type="primary" danger
      disabled={disable}
      onClick={() => onMultiDelete?.()}
      data-testid="delButton"
    >
      {delButtonText}
    </Button>
    )}
  </div>
);

export default ActionBar;
