import { UserGroupItemData } from '@/services/user-group';
import { FormInstance } from 'antd/lib/form';
import configs from '@/configs';
import { checkReportDetail } from '@/services/report-library';
import { rtxValidate } from '@/services/users';
import { detectVideoId } from '@/services/video-manage';

const ALL_GROUP_ID = configs('user-group.allGroupId');
let timer: any = null;

export function handleGroupSelect(form: FormInstance,
  value: string, userGroupData: UserGroupItemData[], field: string) {
  return value === ALL_GROUP_ID ? form.setFieldsValue({
    [`${field}`]: [...userGroupData.map((item) => (item.id ? item.id : ALL_GROUP_ID))],
  }) : null;
}

export function handleGroupDelete(form: FormInstance, value: string, field: string) {
  const selectedGroupIds: string[] = form.getFieldValue(field);
  return value === ALL_GROUP_ID ? form.setFieldsValue({
    [`${field}`]: [],
  }) : form.setFieldsValue({
    [`${field}`]: [...selectedGroupIds.filter((item) => item !== ALL_GROUP_ID)],
  });
}

export function validateSpecialPerm(
  form: FormInstance, groupField: string, rtxField: string, orgField?: string,
) {
  const groupIds = form.getFieldValue(groupField);
  const users = form.getFieldValue(rtxField);
  if (orgField) {
    const orgs = form.getFieldValue(orgField);
    if (!groupIds?.length && !users?.length && !orgs?.length) {
      return Promise.reject(new Error('指定分组、指定用户与指定组织架构至少一个不为空'));
    }
  } else if (!groupIds?.length && !users?.length) {
    return Promise.reject(new Error('指定分组与指定用户至少一个不为空'));
  }
  return Promise.resolve();
}

export async function validateNumber(form: FormInstance, field: string) {
  const names = form.getFieldValue(field);
  if (!names) {
    return Promise.resolve();
  }
  const numberNames = Number(names);
  const reg = /^[0-9]+[0-9]*$/;
  if (!reg.test(names) || Number.isNaN(numberNames)
  || !Number.isInteger(numberNames) || numberNames < 0 || numberNames > 99999) {
    return Promise.reject(new Error('请输入正确的视频ID'));
  }
  if (timer) {
    clearTimeout(timer);
  }
  return new Promise<void>((resolve, reject) => {
    timer = setTimeout(async () => {
      try {
        const result = await detectVideoId(names);
        if (result) {
          return resolve();
        }
        return reject(new Error('请输入正确的视频ID'));
      } catch (e) {
        return reject(new Error('请输入正确的视频ID'));
      } finally {
        clearTimeout(timer);
      }
    }, 500);
  });
}

export async function validateRtx(form: FormInstance,
  field: string, required?: boolean, errorMessage = '请输入 rtx ', resultMessage = '输入的 rtx 错误') {
  const names = form.getFieldValue(field);

  if (!names || !names.length) {
    return required ? Promise.reject(new Error(errorMessage)) : Promise.resolve();
  }

  if (names.filter((item: string) => item.trim() === '')?.length) {
    return Promise.reject(new Error('输入的 rtx 错误'));
  }

  if (names) {
    const result = await rtxValidate(names);

    if (!result) {
      return Promise.reject(new Error(resultMessage));
    }
  }

  return Promise.resolve();
}

export function validateRtxLength(rtxForm: FormInstance, field: string, length = 5, errorMessage = '最多填写五个负责人') {
  const names = rtxForm.getFieldValue(field);
  if (names && names.length > 0 && names.length > length) {
    return Promise.reject(new Error(errorMessage));
  }
  return Promise.resolve();
}

export function validateSignalTagLength(
  require: boolean, rtxForm: FormInstance, field: string, length = 6, errorMessage = '信号关键词最多输入6个',
) {
  if (!require) {
    return Promise.resolve();
  }
  const names = rtxForm.getFieldValue(field);
  if (names && names.length > 0 && names.length > length) {
    return Promise.reject(new Error(errorMessage));
  }
  return Promise.resolve();
}

/**
 * 返回字符串长度，中文按四个长度计算
 * @param value 字符
 */
export function getLanguageLength(value: string): number {
  const regexp = new RegExp(/[\u4e00-\u9fa5]/g);
  if (!value) return 0;
  return value.replace(regexp, 'xxxx').length;
}

export function lengthOfChineseAndEnglishValidate(value: string, maxLength: number, errorInfo: string) {
  const languageLength = getLanguageLength(value);
  if (languageLength > maxLength * 4) {
    return Promise.reject(new Error(errorInfo));
  }
  return Promise.resolve();
}

export function getBase64(img: File | Blob | undefined, callback: Function) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  if (img) {
    reader.readAsDataURL(img);
  }
}

export function reportFormDataTransform(userGroup: UserGroupItemData[] | null, formData: FormData, values: any) {
  Object.keys(values).forEach((key) => {
    if (key === 'search_group' || key === 'read_group' || key === 'download_group') {
      if (values[key]) {
        if (values[key].includes(0) && userGroup) {
          userGroup.forEach((item: any, i: number) => {
            if (item.id) {
              formData.append(`${key}[${i}]`, item.id);
            }
          });
        } else {
          values[key].forEach((item: any, i: number) => {
            formData.append(`${key}[${i}]`, item);
          });
        }
      }
    } else if (values[key] !== undefined) {
      if (Array.isArray(values[key])) {
        values[key].forEach((item: any, i: number) => {
          formData.append(`${key}[${i}]`, item);
        });
      } else {
        formData.append(key, values[key]);
      }
    }
  });
}

interface Params {
  id: string,
  name?: string,
  type?: string,
  title?: string,
  url?: string,
  isCollapsed?: boolean,
  src?: string,
  introduction?: string,
}

export async function validateReportUrl(
  form: FormInstance, field: string, params: Params[],
  param: Params, ismodule?: boolean, module?: string,
) {
  const url = form.getFieldValue(field);
  const errMessage = '输入的报告链接不存在或错误！';

  if (!url) {
    return Promise.reject(new Error('请输入报告链接！'));
  }

  if (!url.includes('/report-library/detail')) {
    return Promise.reject(new Error(errMessage));
  }

  const str = url.split('detail/');

  if (str.length !== 2) {
    return Promise.reject(new Error(errMessage));
  }

  const res = await checkReportDetail(str[1]);

  if (!res) {
    return Promise.reject(new Error(errMessage));
  }

  let fieldKey = '';
  if (ismodule) {
    fieldKey = `${module}_title_${field.split('_')[3]}`;
  } else {
    fieldKey = `title_${field.split('_')[2]}`;
  }
  if (form.getFieldValue(fieldKey) !== res) {
    form.setFieldsValue({ [fieldKey]: res });
  }

  return Promise.resolve();
}

export async function validateUrl(form: FormInstance, field: string) {
  const url = form.getFieldValue(field);

  if (!url) {
    return Promise.reject(new Error('请输入链接！'));
  }

  if (!/^(http|https):\/\//.test(url)) {
    return Promise.reject(new Error('请输入正确格式的链接！'));
  }

  return Promise.resolve();
}
