import React, { FC, ReactNode } from 'react';
import {
  Modal, Form, message,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { updateApproval } from '@/services/authority';
import configs from '@/configs';
import styles from '../index.module.less';

interface PermissionSettingModalProps {
  id: number,
  visible: boolean,
  onOk: () => void,
  onCancel: () => void,
  formContent?: ReactNode,
}

const operateApprovalPassStatus = configs('status.operateApprovalPassStatus');

const PermissionSettingModal: FC<PermissionSettingModalProps> = ({
  visible, onOk, onCancel, id, formContent,
}: PermissionSettingModalProps) => {
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const result = await updateApproval({ ...values, id, operate: operateApprovalPassStatus });
    if (result) {
      message.success('编辑成功!');
      onOk();
    } else {
      message.error('编辑失败!');
    }
  };

  return (
    <Modal
      visible={visible} destroyOnClose
      onOk={() => form.validateFields().then(
        (values) => onFinish(values),
      ).catch((info) => console.log(info))} onCancel={onCancel}
      centered
      title={(
        <>
          <ExclamationCircleOutlined className={styles.tipsIcon} />
          请选择用户所在的分组
        </>
      )}
    >
      <Form form={form}>
        {formContent}
      </Form>
    </Modal>
  );
};

export default PermissionSettingModal;
