import React, {
  FC, useState, useEffect, useRef,
} from 'react';
import {
  Input, Popover, Modal, Button, message, Form,
} from 'antd';
import { EllipsisOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  UserGroupItemData, getDependOrg, deleteDependOrg, addDependOrg,
} from '@/services/user-group';
import {
  getProductDependOrg, addProductDependOrg, deleteProductDependOrg,
} from '@/services/single-product/admin/user-group';
import { UserGroupContext } from '@/context';
import classnames from 'classnames';
import { getStaff } from '@/services/common';
import { getZoneStaff } from '@/services/single-product/admin/zone';
import StaffSelect from './staff-select';
import styles from './index.module.less';

interface DataSource extends UserGroupItemData {
  editing: boolean,
  visible: boolean,
  isDependOrg: boolean,
}

interface LeftUserGroupProps {
  isEdit?: boolean,
  data: UserGroupItemData[],
  className?: string,
  sid?: number,
  onDel?: (id: number) => void,
  onUpdate?: (name: string, type: number, id?: number) => void,
  productId?: number,
  isUpdate?: boolean,
  type?: number,
  dependOrgVisible?: boolean,
  initAdminActive?: boolean,
}

const STAFF_TYPE = 1;

const LeftUserGroup: FC<LeftUserGroupProps> = ({
  isEdit = true, data, className, onDel, onUpdate, productId, isUpdate = false,
  type, dependOrgVisible = true, initAdminActive,
}: LeftUserGroupProps) => {
  const {
    userGroupId, setUserGroupId, setUserGroup, userGroupChange, setUserGroupChange,
  } = UserGroupContext.useContainer();
  const [dataSource, setDataSource] = useState<DataSource[]>();
  const [userGroupName, setUserGroupName] = useState<string>('');
  const inputRef = useRef<Input>(null);
  const [handleIndex, setHandleIndex] = useState<number | undefined>();
  const [form] = Form.useForm();
  const [formVisible, setFormVisible] = useState(false);
  const [editId, setEditId] = useState<number>(0);
  const [productDepId, setProductDepId] = useState<number>(0);

  useEffect(() => {
    if (initAdminActive && dataSource?.length) {
      const res = dataSource.filter((item) => item.name === '管理员组');
      if (res.length) setUserGroupId(res[0].id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initAdminActive, dataSource]);

  useEffect(() => {
    const filterDataSource = data?.map((item) => ({
      ...item,
      editing: false,
      visible: false,
      isDependOrg: false,
    }));
    setDataSource(filterDataSource);
    setUserGroup(data || []);
  }, [data, setUserGroup]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [dataSource]);

  const handleOperateVisible = async (index: number, visible: boolean, id?: number) => {
    if (!dataSource) return;
    if (!visible) {
      dataSource[index].visible = visible;
      setDataSource([...dataSource]);
      return;
    }
    if (dependOrgVisible && id) {
      const res = await (productId ? getProductDependOrg(productId, id) : getDependOrg(id));
      if (res) {
        setProductDepId(res.id);
      }
      dataSource[index].visible = visible;
      dataSource[index].isDependOrg = Boolean(res);
    } else {
      dataSource[index].visible = visible;
      dataSource[index].isDependOrg = false;
    }
    setDataSource([...dataSource]);
  };

  const handleEdit = (index: number, userGroupType: number) => {
    if (userGroupType === 1) return;
    if (dataSource) {
      dataSource[index].editing = true;
      dataSource[index].visible = false;
      setHandleIndex(index);
      setUserGroupName(dataSource[index].name);
      setDataSource([...dataSource]);
    }
  };

  const handleDelete = async (record: DataSource) => {
    if (!record.id) {
      return message.error('全部成员分组不能删除!');
    }
    if (onDel) {
      onDel(record.id);
    }
    return true;
  };

  const handleModelVisible = (index: number, record: DataSource) => {
    if (record.type === 1) return;
    if (dataSource) {
      dataSource[index].visible = false;
      setDataSource([...dataSource]);
    }
    Modal.confirm({
      title: '删除后不可恢复，是否确定删除？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk: () => handleDelete(record),
    });
  };

  const handleAdd = () => {
    const dataSourceItem = {
      name: '默认分组',
      num: 0,
      editing: true,
      id: undefined,
      visible: false,
      isDependOrg: false,
      type: type || 0,
    };
    setUserGroupName('默认分组');
    const newData = dataSource ? [...dataSource, dataSourceItem] : [dataSourceItem];
    const dataSourceItemIndex = newData.length - 1;
    setHandleIndex(dataSourceItemIndex);
    setDataSource(newData);
  };

  const save = async () => {
    const newData = dataSource;
    if (inputRef.current && newData && typeof handleIndex === 'number') {
      const name = inputRef.current?.state.value;
      if (name === '默认分组') {
        newData.splice(handleIndex, 1);
        setDataSource([...newData]);
        setUserGroupName('');
        return false;
      }

      newData[handleIndex].editing = false;
      if (onUpdate && type !== undefined) {
        onUpdate(name, type, newData[handleIndex].id);
      }
      setUserGroupName('');
    }
    return false;
  };

  const handleChangeUserGroupId = ({ id }: DataSource) => {
    setUserGroupId(id || 0);
  };

  const handleDependOrg = async (index: number, isDependOrg: boolean, id?: number) => {
    if (dataSource) {
      dataSource[index].visible = false;
      setDataSource([...dataSource]);
    }
    if (isDependOrg && id) {
      try {
        const result = await (productId ? deleteProductDependOrg(productId, productDepId) : deleteDependOrg(id));
        if (result) {
          message.success('取消关联成功!');
          setUserGroupChange(!userGroupChange);
        } else {
          message.error('取消关联失败，请重试!');
        }
      } catch (e) {
        if (e.errors?.details) {
          message.error(e.errors.details);
        }
      }
    }
    if (!isDependOrg && id) {
      setFormVisible(true);
      setEditId(id);
    }
  };

  const serviceFn = (word: string, size: number) => (
    productId ? getZoneStaff(productId, STAFF_TYPE, word, size) : getStaff(STAFF_TYPE, word, size)
  );

  const content = (record: DataSource, index: number) => (
    <ul className={styles.operationList}>
      <li
        className={classnames({ [styles.liDisabled]: record.type === 1 })}
        onClick={() => handleEdit(index, record.type)}
      >
        修改名称
      </li>
      <li
        className={classnames({ [styles.liDisabled]: record.type === 1 })}
        onClick={() => handleModelVisible(index, record)}
      >
        删除分组
      </li>
      {dependOrgVisible && (
        <li onClick={() => handleDependOrg(index, record.isDependOrg, record.id)}>
          {record.isDependOrg ? '取消关联' : '关联组织'}
        </li>
      )}
    </ul>
  );

  const handleAddDependOrgFinish = async ({ name }: { name: string }) => {
    const payload = { name, gid: editId };
    try {
      const result = await (productId ? addProductDependOrg(productId, payload) : addDependOrg(payload));
      if (result) {
        message.success('关联成功!');
        setUserGroupChange(!userGroupChange);
        form.resetFields();
        setFormVisible(false);
      } else {
        message.error('关联失败!');
      }
    } catch (e) {
      if (e.errors?.details) {
        message.error(e.errors.details);
      }
    }
  };

  return (
    <>
      <Modal
        visible={formVisible} onOk={form.submit}
        title="关联组织" onCancel={() => setFormVisible(false)}
      >
        <Form form={form} onFinish={handleAddDependOrgFinish}>
          <Form.Item label="关联组织" name="name">
            <StaffSelect serviceFn={serviceFn} />
          </Form.Item>
        </Form>
      </Modal>
      {dataSource?.map((item, index) => {
        if (item.editing) {
          return (
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              defaultValue={userGroupName}
              key={item.name}
              maxLength={10}
            />
          );
        }
        return (
          <div
            key={item.name}
            className={classnames(className, styles.userGroupItem, {
              [styles.userGroupItemActive]: item.id === userGroupId,
            })}
            onClick={() => handleChangeUserGroupId(item)}
          >
            {item.id && (
              <>
                {item.name}
                （
                {item.num}
                ）
                {isEdit && item.type !== 1 && (
                  <Popover
                    placement="right"
                    visible={item.visible}
                    content={content(item, index)}
                    trigger="click"
                    overlayClassName={styles.popover}
                    onVisibleChange={(visible) => handleOperateVisible(index, visible, item.id)}
                  >
                    <EllipsisOutlined
                      className={styles.userGroupItemEllipsisIcon}
                    />
                  </Popover>
                )}
              </>
            )}
          </div>
        );
      })}
      {isUpdate && (
        <Button
          onClick={handleAdd} type="text"
          className={classnames(styles.addUserGroupButton, className)}
        >
          <PlusOutlined />
          新建分组
        </Button>
      )}
    </>
  );
};

export default LeftUserGroup;
