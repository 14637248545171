import React, { FC } from 'react';
import { Result, Button } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import styles from './index.module.less';

interface UploadResultProps {
  onErrorContinueUploadClick: () => void,
  onContinueUploadClick: () => void,
  onViewReportClick?: () => void,
  onPreviewList: () => void,
  error?: boolean,
  errorTips?: string,
  successTips?: string,
  cancelText?: string,
}

const UploadResult: FC<UploadResultProps> = (
  {
    onErrorContinueUploadClick, onContinueUploadClick, onViewReportClick, onPreviewList,
    errorTips, error, successTips = '继续上传', cancelText = '返回列表',
  }: UploadResultProps,
) => (
  <Result
    className={styles.result}
    status={error ? 'error' : 'success'}
    title={error ? '提交失败' : '提交成功'}
    extra={error ? [
      <div key="title">
        <CloseCircleFilled style={{ color: 'red' }} />
        {' '}
        {errorTips || '网络错误'}
      </div>,
      <Button
        type="primary" key="again"
        onClick={onErrorContinueUploadClick}
      >
        重新上传
      </Button>,
      <Button key="list" onClick={onPreviewList}>
        {cancelText}
      </Button>,
    ] : [
      <Button
        type="primary" key="agin"
        onClick={onContinueUploadClick}
      >
        {successTips}
      </Button>,
      <>
        {onViewReportClick && (
          <Button
            key="view"
            onClick={onViewReportClick}
          >
            查看报告
          </Button>
        )}
      </>,
      <Button key="list" onClick={onPreviewList}>
        {cancelText}
      </Button>,
    ]}
  />
);

export default UploadResult;
