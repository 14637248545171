import React, { FC } from 'react';
import { BackTop } from 'antd';
import { VerticalAlignTopOutlined } from '@ant-design/icons';
import { Translation } from 'react-i18next';
import styles from './index.module.less';

const BackToTop: FC = () => (
  <BackTop visibilityHeight={0}>
    <div className={styles.hoverButton}>
      <VerticalAlignTopOutlined className={styles.icon} />
      <p>
        <Translation>
          {(t) => t('top')}
        </Translation>
      </p>
    </div>
  </BackTop>
);

export default BackToTop;
