import React, { FC, ReactNode } from 'react';
import { UserInfoContext } from '@/context';

interface PublicNetworkProps {
  children: ReactNode,
}

const PublicNetwork: FC<PublicNetworkProps> = ({ children }: PublicNetworkProps) => {
  const { isStaffUser } = UserInfoContext.useContainer();
  if (isStaffUser) {
    return <>{children}</>;
  }

  return null;
};

const InternalNetwork: FC<PublicNetworkProps> = ({ children }: PublicNetworkProps) => {
  const { isStaffUser } = UserInfoContext.useContainer();
  if (!isStaffUser) {
    return <>{children}</>;
  }

  return null;
};

export {
  InternalNetwork,
  PublicNetwork,
};
