import React, { createRef } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { TableSearch } from '@/components';

const filterIcon = (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />;

type InputRefType = Input | null;
const inputRef = createRef<InputRefType>();

const TableSearchProps = {
  getColumnSearchProps: () => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, clearFilters, confirm,
    }: any) => (
      <TableSearch
        setSelectedKeys={setSelectedKeys} selectedKeys={selectedKeys}
        clearFilters={clearFilters} confirm={confirm}
        ref={inputRef}
      />
    ),
    filterIcon,
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible && inputRef) {
        setTimeout(() => inputRef.current?.select(), 100);
      }
    },
  }),
};

export default TableSearchProps;
