/* eslint-disable camelcase */
import React, {
  FC, ReactNode, useState,
} from 'react';
import {
  Spin, Typography, Modal, Table,
} from 'antd';
import { ProgressItem, getApprovalProgress, ApprovalProgressData } from '@/services/authority';
import { getProductApprovalProgress } from '@/services/single-product/admin/authority';
import { ApprovalStatusRenderStyles } from '@/configs/status';
import classnames from 'classnames';
import configs from '@/configs';
import { useTranslation } from 'react-i18next';
import { useWatch } from '@/hooks';

const { Paragraph } = Typography;

interface ViewModalProps {
  visible: boolean,
  id: number,
  onOk: () => void,
  onCancel: () => void,
  reason: string,
  content?: ReactNode,
  productId?: number,
  type?: number,
}

const statusStyles = configs('status.approvalStatusStyles');
const immediateSuperiorNumber = configs('app.immediateSuperiorNumber');

const REFUSE_OPERATE_STATUS = configs('status.refuseOperateStatus');
const operateRenderText = configs('status.approvalOperateText');

const REPORT_PERMISSION_TYPE = configs('app.reportPermissionType');

const ViewModal: FC<ViewModalProps> = ({
  visible, id, onOk, onCancel, reason, content, productId, type,
}: ViewModalProps) => {
  const { t } = useTranslation();
  const superiorLabelArr = [t('direct_manager'), t('higher_level_manager')];
  const [modalData, setModalData] = useState<ApprovalProgressData>();
  const [modalLoading, setModalLoading] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  useWatch(id, async () => {
    let data;
    setModalLoading(true);
    if (productId) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      data = await getProductApprovalProgress(productId, id);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      data = await getApprovalProgress(id);
    }
    setModalData(data);
    setModalLoading(false);
  });

  const modalColumns = [
    {
      title: '时间',
      dataIndex: 'submit_at',
      render: (submitAt: string) => (submitAt || '-'),
    }, {
      title: '操作人',
      dataIndex: 'user_id',
      render: (userId: string, record: ProgressItem) => (userId ? `${userId}(${record.user_name})` : ''),
    }, {
      title: '状态',
      dataIndex: 'operating',
      render: (operating: number) => {
        const operateStyles = statusStyles.find((item: ApprovalStatusRenderStyles) => item.status === operating);
        return (
          <div className={
            classnames(operateStyles?.style, 'admin-application-status')
          }
          >
            {t(operateStyles?.label)}
          </div>
        );
      },
    }, {
      title: '操作',
      dataIndex: 'operating',
      render: (operating: number, record: ProgressItem) => {
        if (operating === REFUSE_OPERATE_STATUS) {
          return `${operateRenderText[operating]}(${record.reason})`;
        }
        return t(operateRenderText[operating]);
      },
    },
  ];

  return (
    <Modal
      destroyOnClose
      title="审批操作"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText="确认"
      cancelText="取消"
      width={800}
    >
      <Spin spinning={modalLoading}>
        {modalData && (
          <>
            <Paragraph>
              {t('process_type')}
              ：
              {modalData.stream.filter((item) => item.role === immediateSuperiorNumber)
                .map((item, index) => `${superiorLabelArr[index]}(${item.user_id}) -->  `)}
              {type === REPORT_PERMISSION_TYPE ? '报告负责人' : '平台管理员'}
            </Paragraph>
            <Paragraph>
              {t('application_reasons')}
              ：
              {reason}
            </Paragraph>
            <Table
              columns={modalColumns}
              dataSource={modalData.stream || []}
              rowKey="id"
              className="admin-table"
              pagination={false}
            />
            {content}
          </>
        )}
      </Spin>
    </Modal>
  );
};

export default ViewModal;
