import React, { FC, MouseEvent } from 'react';
import { Modal } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined, LinkOutlined } from '@ant-design/icons';
import { MenuTreeData, deleteMenu } from '@/services/menu';
import { deleteProductMenu } from '@/services/single-product/admin/menu';
import { AdminBaseMenuContext } from '@/context';
import styles from './index.module.less';
import { isDirectoryType, isUrlType } from './base-util';

interface TreeTitleProps {
  menuTree: MenuTreeData,
  refresh: Function,
  onAddMenu?: (menuTree: MenuTreeData) => void,
  productId?: number,
}

const TreeTitle: FC<TreeTitleProps> = ({
  menuTree, refresh, onAddMenu, productId,
}: TreeTitleProps) => {
  const { selectMenuAction, resetMenuId } = AdminBaseMenuContext.useContainer();
  const onAdd = (event: MouseEvent) => {
    event.stopPropagation();
    if (onAddMenu) {
      onAddMenu(menuTree);
    }
    resetMenuId();
    selectMenuAction({
      action: 'add',
      parentId: menuTree.id,
    });
  };

  const onDelete = (event: MouseEvent) => {
    event.stopPropagation();
    Modal.confirm({
      title: '节点删除确认',
      content: `确认要删除 ${menuTree.name} 这个节点吗？`,
      cancelText: '取消',
      okText: '确认',
      async onOk() {
        if (productId) {
          await deleteProductMenu(productId, menuTree.id);
        } else {
          await deleteMenu(menuTree.id);
        }
        resetMenuId();
        refresh();
      },
    });
  };

  return (
    <div className={styles.treeTitle}>
      {isUrlType(menuTree.type) ? <LinkOutlined /> : null}
      {menuTree.name}
      <span className={styles.treeTitleAction}>
        <MinusCircleOutlined onClick={onDelete} />
        {isDirectoryType(menuTree.type) && <PlusCircleOutlined onClick={onAdd} />}
      </span>
    </div>
  );
};

export default TreeTitle;
