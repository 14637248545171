import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { getProductionStdInfo, ProductionStdInfoData } from '@/services/menu';
import { useFetch } from '@/hooks';

function useCompeteProduct() {
  const [platform, setPlatform] = useState<'mobile' | 'hd'>('mobile');
  const [mobileDataSource, setMobileDataSource] = useState<ProductionStdInfoData | null>();
  const [hdDataSource, setHdDataSource] = useState<ProductionStdInfoData | null>();

  const { data: resMoblie } = useFetch(() => getProductionStdInfo(platform), {
    interrupt: !(platform === 'mobile' && !mobileDataSource),
    depends: [platform],
  });

  const { data: resHD } = useFetch(() => getProductionStdInfo(platform), {
    interrupt: !(platform === 'hd' && !hdDataSource),
    depends: [platform],
  });

  useEffect(() => {
    if (!mobileDataSource && resMoblie) {
      setMobileDataSource(resMoblie);
      setPlatform('hd');
    }
  }, [mobileDataSource, resMoblie]);

  useEffect(() => {
    if (!hdDataSource && resHD) {
      setHdDataSource(resHD);
    }
  }, [hdDataSource, resHD]);

  return {
    mobileDataSource,
    hdDataSource,
  };
}

const CompeteProduct = createContainer(useCompeteProduct);

export default CompeteProduct;
