// eslint-disable-next-line import/no-unresolved
import ActionBar from './action-bar';
import LeftUserGroup from './left-user-group';
import Title from './title';

export default {
  ActionBar,
  LeftUserGroup,
  Title,
};
