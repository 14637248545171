import React, { FC } from 'react';
import { preResearchContext } from '@/context';
import { Carousel } from 'antd';
import { NavLink } from 'react-router-dom';
import preIcon from '@/assets/images/pre-research-icon.png';
import styles from './index.module.less';

interface PreCarousel {
  needShowLevel?: boolean,
  onClick?: Function
}

const PreCarousel: FC<PreCarousel> = ({ needShowLevel, onClick }: PreCarousel) => {
  const { slicePreList, preResearchData } = preResearchContext.useContainer();
  const handleLineClick = (id: number) => {
    if (onClick) {
      onClick(id);
    }
  };
  return (
    <>
      {needShowLevel
        ? (
          <>
            {slicePreList && slicePreList[0]?.length > 0 && (
            <Carousel
              autoplay dotPosition="right"
              dots={false}
              effect="scrollx"
            >
              {slicePreList?.map((list, index) => (
                <div key={index}>
                  {list.map((item) => (item.id ? (
                    <div
                      className={styles.preList} key={item.id}
                    >
                      <img
                        className={styles.preImg} src={preIcon}
                        alt={item.name}
                      />
                      <NavLink
                        onClick={() => handleLineClick(item.id)} className={styles.preLink}
                        to="/research"
                        title={item.name}
                      >
                        {item.name}
                      </NavLink>
                    </div>
                  ) : (
                    <div
                      className={styles.preList} key={item.id}
                    />
                  )))}
                </div>
              ))}
            </Carousel>
            )}
          </>
        )

        : (
          <Carousel
            autoplay dotPosition="right"
            dots={false}
            effect="scrollx"
          >
            {preResearchData.map((item) => (
              <div key={item.name}>
                <div
                  className={styles.preList}
                >
                  <img
                    className={styles.preImg} src={preIcon}
                    alt={item.name}
                  />
                  <NavLink
                    onClick={() => handleLineClick(item.id)} className={styles.preLink}
                    to="/research"
                    title={item.name}
                  >
                    {item.name}
                  </NavLink>
                </div>
              </div>
            ))}
          </Carousel>
        )}
    </>
  );
};

export default PreCarousel;
