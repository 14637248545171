import React from 'react';
import { Popover } from 'antd';
import { MobileOutlined } from '@ant-design/icons';
import configs from '@/configs';
import QRCode from 'qrcode';
import classnames from 'classnames';
import { UserInfoContext, BeaconContext } from '@/context';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';

const MOBILE_SERVER = configs('app.mobileServer');

const MobileQRCode = () => {
  const { info, isStaffUser } = UserInfoContext.useContainer();
  const { beacon } = BeaconContext.useContainer();

  const { t } = useTranslation();
  if (isStaffUser) return null;
  const QRCodeRef = (node: any) => {
    if (node !== null) {
      QRCode.toCanvas(node, MOBILE_SERVER, {
        width: 100,
      });
    }
  };
  const popoverTitle = () => (
    <div className={styles.popoverTitle}>
      <canvas ref={QRCodeRef} />
      <div>
        <p>{t('scan_or')}</p>
        <p>{t('search_wechat')}</p>
      </div>
    </div>
  );

  const handleMobileQRCodeClick = () => {
    // eslint-disable-next-line camelcase
    beacon.onUserAction('click_mobile_QRcode_button', { user_id: info?.user_id });
  };

  return (
    <Popover
      title={popoverTitle} placement="left"
      content={
        <div className={styles.popoverTips}><p>{t('subscribe_note')}</p></div>
      } overlayClassName={styles.mobileQRCodePopover}
      trigger="hover"
    >
      <div className={classnames(styles.hoverButton, styles.mobileShare)} onMouseEnter={handleMobileQRCodeClick}>
        <MobileOutlined className={styles.icon} />
        <p>{t('qr_code')}</p>
      </div>
    </Popover>
  );
};

export default MobileQRCode;
