/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable camelcase */
import React, { FC, ReactNode } from 'react';
import { Button, Modal } from 'antd';
import dialogBg from '@/assets/images/dialogBg.png';
import styles from './index.module.less';

interface Props {
  visible: boolean,
  changeVisible: (v: boolean) => void,
  msg?: string,
}

const Dialog: FC<Props> = ({ visible, changeVisible, msg }: Props) => {
  const str1 = '感谢您对IEG市场与用户研究部产出的研究报告 / 市场用户数据的关注！';
  const str2 = '本份报告 / 数据为订阅版本内容，可通过部门或工作室订阅进行查看；';
  const str3 = '查看订阅机制及流程，如需进一步了解，请联系danniepeng(彭丹妮)，谢谢！';

  if (!visible) return null;

  return (
    <Modal
      visible={visible}
      centered
      title={null}
      footer={null}
      forceRender
      modalRender={(modal: ReactNode) => (
        <div className={styles.dialog}>
          {modal}
        </div>
      )}
      bodyStyle={{
        width: '520px',
        height: '360px',
        backgroundColor: 'transparent',
        backgroundImage: `url(${dialogBg})`,
        backgroundSize: '556px 408px',
        backgroundPosition: '-18px -24px',
      }}
      maskStyle={{ zIndex: 1000000001 }}
      zIndex={1000000002}
    >
      <div className={styles.title}>公告</div>
      <div className={styles.text}>
        {msg || (
          <>
            <p>{str1}</p>
            <p>{str2}</p>
            <p>
              <a
                onClick={() => {
                  window.open('https://imur.woa.com/report-library/detail/de96093c');
                }}
              >
                点击此链接
              </a>
              <span>{str3}</span>
            </p>
          </>
        )}
      </div>
      <Button
        onClick={() => {
          changeVisible(false);
        }}
        className={styles.button}
      >
        确认
      </Button>
    </Modal>
  );
};

export default Dialog;
