import React, { FC, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DataNode } from 'rc-tree/lib/interface';
import {
  Button, Tree, message,
} from 'antd';
import { AdminMenuTreeData } from '@/services/common';

interface MenuDataNode extends DataNode {
  index: number,
  data: AdminMenuTreeData,
  auth: number,
}

interface MenuSelectProps {
  data: AdminMenuTreeData | null,
  loading?: boolean,
  onSubmit: (selectedKeys: number[]) => void,
}

const MenuSelect: FC<MenuSelectProps> = ({ data, loading, onSubmit }: MenuSelectProps) => {
  const [updateLoading, setUpdateLoading] = useState(false);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Map<number, number | undefined>>(new Map());

  useEffect(() => {
    setDefaultSelectedKeys([]);
    setSelectedKeys(new Map());
  }, [loading]);

  const transferTreeData = (list: AdminMenuTreeData[]) => list.map(((item, index) => {
    const treeNodeItem: MenuDataNode = {
      key: `${item.id}`,
      title: item.name,
      index,
      data: item,
      auth: item.auth,
    };

    if (item.auth && !item.children?.length && !defaultSelectedKeys.includes(`${item.id}`)) {
      setSelectedKeys(selectedKeys.set(item.id, item.id));
      setDefaultSelectedKeys([...defaultSelectedKeys, `${item.id}`]);
    }

    if (item.children?.length) {
      treeNodeItem.children = transferTreeData(item.children);
    }

    return treeNodeItem;
  }));

  if (!data) return null;

  const treeData = transferTreeData([data]);

  const onFinish = async () => {
    setUpdateLoading(true);
    try {
      onSubmit(Array.from(selectedKeys.values()).filter((item) => item !== undefined) as number[]);
    } catch (e) {
      if (e.errors?.detail) {
        message.error(e.errors.detail);
      }
    }
    return setUpdateLoading(false);
  };

  const TreeCheck = (checked: boolean, list: AdminMenuTreeData): any => {
    if (list.children.length) {
      return list.children.map((item) => TreeCheck(checked, item));
    }
    return checked ? setSelectedKeys(selectedKeys?.set(list.id,
      list.id)) : selectedKeys?.delete(list.id);
  };

  const onTreeNodeCheck = (checkedKeys: any, e: any) => {
    if (e.node.data.children.length) {
      return e.node.data.children.map((item: AdminMenuTreeData) => TreeCheck(e.checked, item));
    }

    return e.checked ? setSelectedKeys(
      selectedKeys?.set(e.node.data.id, e.node.data.id),
    ) : selectedKeys.delete(e.node.data.id) && setSelectedKeys(selectedKeys);
  };

  return (
    <>
      {!loading && (
        <>
          <Tree
            checkable
            defaultExpandAll
            treeData={treeData}
            onCheck={onTreeNodeCheck}
            defaultCheckedKeys={defaultSelectedKeys}
          />

          <Button
            type="primary" htmlType="submit"
            loading={updateLoading} onClick={onFinish}
          >
            保存
          </Button>
        </>
      )}
      {loading && '加载中...'}
    </>
  );
};

export default MenuSelect;
