import { createContainer } from 'unstated-next';
import { useEffect, useState } from 'react';
import configs from '@/configs';
import { useLocation } from 'react-router-dom';

const DEFAULT_TITLE = configs('app.defaultTitle');

const matchPath = [
  '/cultural-signals/detail/',
  'report-library/detail/',
  '/single-product/report-detail',
  '/base/detail',
  '/mail-report',
];

function useTitle() {
  const [reportTitle, setReportTitle] = useState('');
  const location = useLocation();
  useEffect(() => {
    if (reportTitle && matchPath.find((item) => location.pathname.includes(item))) {
      document.title = reportTitle;
    } else {
      setReportTitle('');
      document.title = DEFAULT_TITLE;
    }
  }, [location, reportTitle]);
  return {
    setReportTitle,
  };
}

const ReportTitle = createContainer(useTitle);

export default ReportTitle;
