import React, { FC } from 'react';
import { NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import classnames from 'classnames';
import styles from './index.module.less';
import Logo from './logo';

const { Sider } = Layout;
const { SubMenu } = Menu;

const AdminSider: FC = () => {
  const match = useRouteMatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  return (
    <Sider width={240} className={classnames(styles.sider, styles.adminSider)}>
      <Logo />
      <Menu
        className={styles.menu} theme="dark"
        mode="inline"
        selectedKeys={queryParams.get('key') ? [queryParams.get('key') as string] : []}
      >
        <SubMenu title="报告管理">
          <Menu.Item key="1">
            <NavLink to={`${match.path}/report/list?key=1`}>专区报告</NavLink>
          </Menu.Item>
          <Menu.Item key="2">
            <NavLink to={`${match.path}/report/upload?key=2`}>上传报告</NavLink>
          </Menu.Item>
          <Menu.Item key="3">
            <NavLink to={`${match.path}/report/classification?key=3`}>报告分类</NavLink>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="4">
          <NavLink to={`${match.path}/chart?key=4`}>数据管理</NavLink>
        </Menu.Item>
        <Menu.Item key="5">
          <NavLink to={`${match.path}/staff?key=5`}>成员管理</NavLink>
        </Menu.Item>
        {/* <Menu.Item>
          <NavLink to={`${match.path}/authority-approval`}>权限审批</NavLink>
        </Menu.Item> */}
        <Menu.Item key="6">
          <NavLink to={`${match.path}/setting?key=6`}>专区设置</NavLink>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default AdminSider;
