/* eslint-disable camelcase */
import React, { FC, useState, useEffect } from 'react';
import {
  Form, Select, message, Tooltip,
} from 'antd';
import { RtxSelect } from '@/components';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import configs from '@/configs';
import {
  ReportBaseDetail,
} from '@/services/report-library-admin';
import { validateRtx } from '@/utils/helper/form';
import { FormInstance } from 'antd/lib/form';
import styles from './index.module.less';

const OTHER = configs('report.other');

const tips = () => (
  <Tooltip
    placement="right" title="报告作者"
  >
    <ExclamationCircleOutlined className={styles.tipsIcon} />
  </Tooltip>
);

interface FormWriterProps {
  form: FormInstance,
  initialData: ReportBaseDetail | null,
  reset: boolean,
  reportType: number,
  autoValue?: string[],
}

const FormWriter: FC<FormWriterProps> = ({
  form, initialData, reset, reportType, autoValue,
}: FormWriterProps) => {
  const writeFormItem = {
    name: 'write_by',
    rules: [{ required: true, message: '请选择撰写者!' }],
  };

  const [isFirst, setIsFirst] = useState(true);
  const [recordReset, setRecordReset] = useState(reset);
  const [selectValue, setSelectValue] = useState<string[]>([]);
  const [rtxSelectValue, setRtxSelectValue] = useState<string[]>([]);

  useEffect(() => {
    if (autoValue?.length) {
      setRtxSelectValue(autoValue);
    }
  }, [autoValue]);

  useEffect(() => {
    if (initialData?.write_by.length) {
      if (!isFirst) {
        return;
      }
      setIsFirst(false);
      const value = initialData.write_by.map((item) => item.user_id);
      if (reportType === OTHER) {
        setSelectValue(value);
      } else {
        setRtxSelectValue(value);
      }
    }
  }, [initialData, isFirst, reportType]);

  useEffect(() => {
    if (reset !== recordReset) {
      setRecordReset(reset);
      setSelectValue([]);
      setRtxSelectValue([]);
    }
  }, [recordReset, reset]);

  const handleChange = (values: string[]) => {
    const { length } = values;
    const isChineseName = !(escape(values[length - 1]).indexOf('%u') < 0);
    const chineseNameLength = 14;
    const englishNameLength = 26;
    const maxWriterNameLength = isChineseName ? chineseNameLength : englishNameLength;

    if (length && values[length - 1].length > maxWriterNameLength) {
      message.info(`第三方报告撰写者，最多${chineseNameLength}个汉字或${englishNameLength}个英文字母`);
      setSelectValue(values.splice(length - 1, 1));
    }
  };

  return (
    <Form.Item
      shouldUpdate
      label="撰写者"
      required
      className={styles.formWrapper}
    >
      {({ getFieldValue }) => (getFieldValue('type') === OTHER
        ? (
          <>
            <Form.Item
              {...writeFormItem}
              className={styles.tipsFormItem}
            >
              <Select
                mode="tags" style={{ width: '100%' }}
                placeholder="请输入撰写者中文名"
                value={selectValue}
                onChange={handleChange}
              />
            </Form.Item>
            {tips()}
          </>
        ) : (
          <>
            <Form.Item
              {...writeFormItem}
              rules={[
                { required: true, validator: () => validateRtx(form, 'write_by') },
                { required: true, message: '请选择撰写者!' }]}
              className={styles.tipsFormItem}
            >
              <RtxSelect initialValues={rtxSelectValue} />
            </Form.Item>
            {tips()}
          </>
        ))}
    </Form.Item>
  );
};

export default FormWriter;
