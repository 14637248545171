/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useFetch } from '@/hooks';
import { createContainer } from 'unstated-next';
import { UserInfoContext } from '@/context';
import { getIsPayUserFirstLogin } from '@/services/users';
import moment from 'moment';

function useUserBehavior() {
  const { info } = UserInfoContext.useContainer();
  const { data } = useFetch(getIsPayUserFirstLogin);
  const [step, setStep] = useState(-1); // 无弹窗: -1; 使用须知: 0; 说明: 1; 首页推荐: 2;
  const [isViewFirst, setIsViewFirst] = useState(false);
  const [isSubscribeFirst, setIsSubscribeFirst] = useState(false);

  useEffect(() => {
    if (info && data?.length) {
      const resFirst = data.find((item) => item.version === '9.4');
      const resSubscribe = data.find((item) => item.version === '9.5');
      setIsViewFirst(resFirst?.is_first || false);
      setIsSubscribeFirst(resSubscribe?.is_first || false);
      if (!info.safety_at && info.group?.length) {
        setStep(0);
      } else {
        const lastTime = moment(info.safety_at).valueOf();
        const nowTime = new Date().getTime();
        const sixtyDays = 5184000000;
        if (nowTime - lastTime >= sixtyDays) {
          setStep(0);
        } else if (resFirst?.is_first) {
          setStep(1);
        } else if (resSubscribe?.is_first) {
          setStep(2);
        }
      }
    }
  }, [info, data]);

  return {
    isFirst: isViewFirst,
    isSubscribeFirst,
    setIsSubscribeFirst,
    step,
    setStep,
  };
}

const UserBehavior = createContainer(useUserBehavior);

export default UserBehavior;
