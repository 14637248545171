const REPORT_TYPE = 0;
const CULTURAL_SIGNALS_TYPE = 1;

export default {
  reportType: REPORT_TYPE,
  culturalSignalType: CULTURAL_SIGNALS_TYPE,

  links: {
    [REPORT_TYPE]: '/report-library/detail/',
    [CULTURAL_SIGNALS_TYPE]: '/cultural-signals/detail/',
  },
};
