import { createContainer } from 'unstated-next';
import { getLabelList } from '@/services/knowledge/label';
import { useFetch } from '@/hooks';
import { useState } from 'react';
import configs from '@/configs';

const REPORT_LABEL_TYPE = configs('label.reportLabelType');

function useLabels() {
  const [labelType, setLabelType] = useState(REPORT_LABEL_TYPE);
  const { data: labels, loading, refresh } = useFetch(() => getLabelList(labelType), { depends: [labelType] });
  const [isLabelNameFilled, setIsLabelNameFilled] = useState<boolean>(false);

  return {
    labels,
    labelType,
    setLabelType,
    isLabelNameFilled,
    setIsLabelNameFilled,
    loading,
    refresh,
  };
}

const Labels = createContainer(useLabels);

export default Labels;
