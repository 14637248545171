import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout, Button } from 'antd';
import { Steps } from 'intro.js-react';
import singleBackImg from '@/assets/images/single-back.png';
import { UserInfoContext, SingleProductInfoContext } from '@/context';
import history from '@/utils/history';
import NavAvatar from '../nav-avatar';
import styles from './index.module.less';

const { Header } = Layout;

const AdminHeader: FC = () => {
  let stepsEnabled = true;
  const { info } = UserInfoContext.useContainer();
  const { data: productInfo } = SingleProductInfoContext.useContainer();
  const gamesLink = ['/single-product/report-index', '/single-product/report-detail', '/single-product/data'];
  const location = useLocation();
  const handleBackMenuClick = () => {
    if (location.pathname.includes('/single-product/admin/report/upload') && location.search) {
      return history.push('/single-product/admin/report/list');
    }
    if (location.pathname.includes('/single-product/admin/report/list')
      || location.pathname.includes('/single-product/admin/report/upload')
      || location.pathname.includes('/single-product/admin/report/classification')
      || location.pathname.includes('/single-product/admin/chart')
      || location.pathname.includes('/single-product/admin/staff')
      || location.pathname.includes('/single-product/admin/setting')
    ) {
      return history.push(`/single-product/report-index?szid=${productInfo?.id}`);
    }
    if (location.pathname.includes('/single-product/report-detail')) {
      return history.push('/single-product/report');
    }
    return history.push('/single-product/dashboard');
  };

  const [gamesAdminEntranceVisible, setGamesAdminEntranceVisible] = useState(false);
  const [backstageButtonVisible, setBackstageButtonVisuble] = useState(true);
  useEffect(() => {
    if (info && productInfo) {
      const auth = productInfo.charge_by.some((item) => item.user_id === info.user_id);
      if (auth && gamesLink.includes(location.pathname)) {
        setGamesAdminEntranceVisible(true);
      } else {
        setGamesAdminEntranceVisible(false);
      }
      if (location.pathname.includes('/single-product/admin') || gamesLink.includes(location.pathname)) {
        setBackstageButtonVisuble(false);
      } else {
        setBackstageButtonVisuble(true);
      }
    }
  }, [productInfo, info, location.pathname, gamesLink]);

  return (
    <Header className={styles.header}>
      <img
        src={singleBackImg}
        className={styles.menuFoldOut}
        onClick={handleBackMenuClick}
        alt=""
      />
      <div className="nav-right-action">
        {gamesAdminEntranceVisible && (
          <Button
            id="intro_step"
            type="text"
            className={styles.adminButton}
            onClick={() => {
              history.push('/single-product/admin/report/list?key=1');
            }}
          >
            专区管理
          </Button>
        )}
        <NavAvatar
          userInfo={info}
          backstageButtonVisible={backstageButtonVisible}
        />
      </div>
      {!localStorage.getItem('intro-single-index')
        && gamesAdminEntranceVisible
        && document.querySelector('#intro_step')
        && (
        <Steps
          enabled={stepsEnabled}
          steps={[{
            element: document.querySelector('#intro_step') as HTMLElement,
            intro: '点击此处上传报告，管理专区',
            position: 'bottom',
          }]}
          initialStep={0}
          onExit={() => {
            stepsEnabled = false;
            localStorage.setItem('intro-single-index', 'true');
          }}
          options={{
            showBullets: false,
            tooltipClass: styles.introTooltip,
            buttonClass: styles.introButton,
            doneLabel: '知道了',
          }}
        />
        )}
    </Header>
  );
};

export default AdminHeader;
