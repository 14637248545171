import React, { useEffect } from 'react';
import { Button } from 'antd';
import ErrorImg from '@/assets/images/error-img.png';
import styles from './index.module.less';

const ErrorFallback = ({ error }: any) => {
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      localStorage.getItem(
        'page-has-been-force-refreshed',
      ) || 'false',
    );
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        localStorage.setItem(
          'page-has-been-force-refreshed',
          'true',
        );
        window.location.reload();
      }
    }
  }, [error]);

  return (
    <div className={styles.content}>
      <div className={styles.mask} />
      <div className={styles.card}>
        <img
          src={ErrorImg}
          alt=""
          className={styles.errorImg}
        />
        <p className={styles.tip}>
          当前页面不可用，请跳转到首页～
        </p>
        <Button
          type="primary"
          onClick={() => {
            window.location.href = '/';
          }}
          className={styles.button}
        >
          返回首页
        </Button>
      </div>
    </div>
  );
};

export default ErrorFallback;
