import BaseInfo from './base-info';
import ExternalBadge from './external-badge';
import FullReport from './full-report';
import SearchListItem from './search-list-item';
import ApprovalFrom from './approval-form';
import RichTextEditor from './rich-text-editor';
import FullReportPreview from './full-report-preview';

export default {
  BaseInfo,
  ExternalBadge,
  FullReport,
  SearchListItem,
  ApprovalFrom,
  RichTextEditor,
  FullReportPreview,
};
