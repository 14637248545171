import React, { FC } from 'react';
import { Form, Input } from 'antd';

const { TextArea } = Input;

const ReasonForm: FC = () => (
  <Form.Item
    name="reason" rules={[
      { required: true, message: '请输入拒绝的理由' },
      { message: '至少五个字符', min: 5 },
    ]}
  >
    <TextArea
      minLength={5}
      rows={4} placeholder="请输入拒绝理由（至少五个字符）"
    />
  </Form.Item>
);

export default ReasonForm;
