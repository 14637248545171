import configs from '@/configs';

export const BASIC_PERMISSION_TYPE = configs('app.basicPermissionType');
const REPORT_PERMISSION_TYPE = configs('app.reportPermissionType');
const IDATA_PERMISSION_TYPE = configs('app.idataPermissionType');
export const REFUSE_NUMBER = configs('status.approvalFailedStatus');

export const getPermissionType = (type: number): string => {
  let typeText;
  switch (type) {
    case BASIC_PERMISSION_TYPE:
      typeText = '系统权限';
      break;
    case REPORT_PERMISSION_TYPE:
      typeText = '报告权限';
      break;

    case IDATA_PERMISSION_TYPE:
      typeText = '数据权限';
      break;

    default:
      typeText = '权限';
  }
  return typeText;
};

export const passOperateOptions = {
  operate: 1,
};

export const FORM_TYPE = 1;

export const REFUSE_FORM_TYPE = 2;

export const DEFAULT_TAB = 0;

export const PAGE_SIZE = 10;

export const initParams = {
  page: 1,
  page_size: PAGE_SIZE,
  status: DEFAULT_TAB,
};

export const initFilterInfo = { user_id: null, department: null };
