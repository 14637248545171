import React, { FC } from 'react';
import { Menu, Dropdown } from 'antd';
import _ from 'lodash';
import { DownOutlined } from '@ant-design/icons';
import { changeLanguage, CURRENT_LANGUAGE } from '@/i18n';
import configs from '@/configs';
import { UserInfoContext, BeaconContext } from '@/context';

const supportedLanguages = configs('app.supportedLanguages');

interface LangMap {
  [key: string]: {
    key: string,
    name: string,
  }
}

const langMap: LangMap = _.keyBy(supportedLanguages, 'key');

const SwitchLang: FC = () => {
  const { beacon } = BeaconContext.useContainer();
  const { info } = UserInfoContext.useContainer();

  const onChangeLanguage = (data: any) => {
    // eslint-disable-next-line camelcase
    beacon.onUserAction('click_language_button', { user_id: info?.user_id });
    changeLanguage(data.key);
    window.location.reload();
  };

  const menu = (
    <Menu onClick={onChangeLanguage}>
      {supportedLanguages.map((item: any) => (
        <Menu.Item key={item.key}>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      placement="bottomCenter"
      arrow
    >
      <div className="nav-switch-lang">
        <span>Language：</span>
        <span>{langMap[CURRENT_LANGUAGE]?.name}</span>
        <DownOutlined />
      </div>
    </Dropdown>
  );
};

export default SwitchLang;
