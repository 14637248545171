/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable react/no-danger */
import React, {
  FC, useEffect, useState, MouseEvent,
} from 'react';
import {
  Typography, Col, Row, Avatar, message,
} from 'antd';
import {
  LikeOutlined, EyeOutlined, StarOutlined, LikeFilled, StarFilled, ClockCircleOutlined,
} from '@ant-design/icons';
import { SearchReportItemData } from '@/services/report-library';
import { handleIconSrc } from '@/services/home';
import { WriterOrCharger } from '@/services/response.type';
import {
  numberTransform, pathToAuthorPage, filterEMTag, isSingleProductPage, removeUserEmSpan,
} from '@/utils/helper/base';
import { UserInfoContext } from '@/context';
import configs from '@/configs';
import classnames from 'classnames';
import { CURRENT_LANGUAGE } from '@/i18n';
import unSubsCorner from '@/assets/images/unSubsCorner.svg';
import unSubsCornerEN from '@/assets/images/unSubsCornerEN.svg';
import subsCorner from '@/assets/images/subsCorner.svg';
import zoneReportCover from '@/assets/images/zone_report_cover.png';
import ExternalBadge from '../external-badge';
import styles from './index.module.less';

const internalReport = configs('report.internalReport');
const AVATAR_URL = configs('app.avatars');
const otherRtx = configs('report.otherRtx');
const iconSrcObj: { [key: string]: string } = { unSubsCorner, unSubsCornerEN, subsCorner };

const { Text } = Typography;

interface SearchListItemProps {
  data: SearchReportItemData,
  onCollect: (event: MouseEvent, checked: boolean, openid: string, onFailedCallback: () => void) => void,
  onLike: (event: MouseEvent, checked: boolean, openid: string, onFailedCallback: () => void) => void,
  onClick: (event: MouseEvent, id: number, openid?: string) => void,
  className?: string,
  style?: object,
  hasSubscribeIcon?: boolean,
}

const SearchListItem: FC<SearchListItemProps> = ({
  data, onCollect, onLike, onClick,
  className, style, hasSubscribeIcon,
}: SearchListItemProps) => {
  const {
    like_num: initLikeNum, like_status: initLikeStatus, favorites_num: initFavorNum, favorites_status: initFavorStatus,
    booked_num: bookedNum,
    id, openid, cover_pic: CoverPic, title, introduction, write_by: writers, output_at: outputAt,
    views_num: viewsNum, type, preview_pic: PreviewPic,
  } = data;
  const [likeNum, setLikeNum] = useState(initLikeNum);
  const [isLiked, setIsLiked] = useState(initLikeStatus);
  const [isLikeResponse, setIsLikeResponse] = useState(false);
  const [favoritesNum, setFavoritesNum] = useState(initFavorNum);
  const [isCollected, setIsCollected] = useState(initFavorStatus);
  const [isCollectResponse, setIsCollectResponse] = useState(false);
  const { isStaffUser, isPayingUsers } = UserInfoContext.useContainer();
  useEffect(() => {
    setIsLiked(initLikeStatus);
  }, [initLikeStatus]);

  const onCollectFailed = (checked: boolean, num: number) => {
    setIsCollected(checked);
    setFavoritesNum(num);
  };

  const handleCollect = async (event: MouseEvent) => {
    if (isCollectResponse) {
      message.info('请勿重复操作');
      return;
    }
    setIsCollectResponse(true);

    const collected = isCollected;
    const curFavoritesNum = favoritesNum;
    setFavoritesNum(isCollected ? favoritesNum - 1 : favoritesNum + 1);
    setIsCollected(!isCollected);

    onCollect(event, !isCollected, openid, () => onCollectFailed(collected, curFavoritesNum));

    setIsCollectResponse(false);
  };

  const onLikeFailed = (liked: boolean, curLikeNum: number) => {
    setIsLiked(liked);
    setLikeNum(curLikeNum);
  };

  const handleLike = async (event: MouseEvent) => {
    if (isLikeResponse) {
      message.info('请勿重复操作');
      return;
    }
    setIsLikeResponse(true);
    const liked = isLiked;
    const curLikeNum = likeNum;
    setLikeNum(liked ? likeNum - 1 : likeNum + 1);
    setIsLiked(!isLiked);

    onLike(event, !isLiked, openid, () => onLikeFailed(liked, curLikeNum));

    setIsLikeResponse(false);
  };

  const handleClick = (event: MouseEvent) => {
    onClick(event, id, openid);
  };

  const coverPic = () => {
    if (CoverPic || PreviewPic?.[0]) {
      return `${CoverPic || PreviewPic?.[0]}&imageMogr2/thumbnail/x143`;
    }
    return zoneReportCover;
  };

  const handleAvatarClick = (event: MouseEvent, writeBy: WriterOrCharger) => {
    event.stopPropagation();
    if (isSingleProductPage()) return;
    const { user_id: userId, user_name: userName } = writeBy;
    const matchUserIds = filterEMTag(userId);
    const matchUserNames = filterEMTag(userName);
    pathToAuthorPage(event, matchUserIds ? matchUserIds[0] : userId, matchUserNames ? matchUserNames[0] : userName);
  };

  const renderAvatar = (writeBy: WriterOrCharger) => {
    if (!writeBy) return null;

    return (
      <div
        className={styles.avatar}
        onClick={(event) => handleAvatarClick(event, writeBy)}
      >
        <Avatar
          src={
            `${AVATAR_URL}${writeBy.user_name === otherRtx ? otherRtx : removeUserEmSpan(writeBy.user_id)}/profile.jpg`
            } size={28}
          style={{ fontSize: 14 }}
        >
          {writeBy.headimg ? '' : writeBy.user_id.substr(0, 1).toLocaleUpperCase()}
        </Avatar>
        <Text className={styles.searchListItemUserInfoText}>
          <span
            dangerouslySetInnerHTML={
              { __html: `${writeBy.user_id}${writeBy.user_name ? `(${writeBy.user_name})` : ''}` }
            }
          />
        </Text>
      </div>
    );
  };

  return (
    <Row
      className={classnames(styles.searchListItem, className)}
      onClick={handleClick}
      style={{ ...style }}
    >
      <Col flex="236px" style={{ marginRight: 30, overflow: 'hidden', position: 'relative' }}>
        {hasSubscribeIcon && data.report_type === '深度报告' && (
          <img
            alt=""
            src={iconSrcObj[handleIconSrc(isPayingUsers, CURRENT_LANGUAGE, 0)]}
            className={styles.subscribeIcon}
          />
        )}
        <img
          src={coverPic()} alt="cover"
          className={styles.cover}
        />
      </Col>
      <Col flex="auto">
        <h2 className={`mur-title ${styles.postTitle}`} dangerouslySetInnerHTML={{ __html: title }} />
        <Text className={styles.outputAt}>{outputAt.split(' ')[0]}</Text>
        <div
          className={styles.introduction}
          dangerouslySetInnerHTML={{ __html: introduction?.replace(/\r\n/ig, '<br />') || '' }}
        />
        <Row align="middle">
          <Col
            flex={300} className={!isStaffUser
              ? styles.searchListItemUserInfo : styles.avatarNet}
          >
            {(type && internalReport !== type) ? <ExternalBadge />
              : renderAvatar(writers)}
          </Col>
          <Col flex="auto">
            <Text className={styles.textColor}>
              {isCollected
                ? (
                  <StarFilled
                    className={`${styles.icons} ${styles.selectedIcons}`} onClick={(event) => handleCollect(event)}
                  />
                )
                : (
                  <StarOutlined
                    className={styles.icons} onClick={(event) => handleCollect(event)}
                  />
                )}
              {numberTransform(favoritesNum)}
            </Text>
            <Text className={styles.textColor}>
              {isLiked
                ? (
                  <LikeFilled
                    className={`${styles.icons} ${styles.selectedIcons}`} onClick={(event) => handleLike(event)}
                  />
                ) : <LikeOutlined className={styles.icons} onClick={(event) => handleLike(event)} />}
              {numberTransform(likeNum)}
            </Text>
            <Text className={styles.textColor}>
              <ClockCircleOutlined className={styles.icons} />
              {numberTransform(bookedNum)}
            </Text>
            <Text className={styles.textColor}>
              <EyeOutlined className={styles.icons} />
              {numberTransform(viewsNum)}
            </Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SearchListItem;
