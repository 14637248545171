/* eslint-disable camelcase */
import React, { FC, ReactNode } from 'react';
import { Button, Modal } from 'antd';
import { UserInfoContext } from '@/context';
import dialogBg from '@/assets/images/dialogBg.png';
import styles from './index.module.less';

interface DialogProp {
  visible: boolean,
  hasMask?: boolean,
  onClick: Function,
  onCancel?: Function,
}

const Dialog: FC<DialogProp> = ({ visible, onClick, onCancel }: DialogProp) => {
  const { info } = UserInfoContext.useContainer();
  if (!visible) return <div />;

  return (
    <Modal
      visible={visible}
      centered
      title={null}
      footer={null}
      forceRender
      modalRender={(modal: ReactNode) => (
        <div className={styles.dialog}>
          {modal}
        </div>
      )}
      bodyStyle={{
        width: '550px',
        height: '380px',
        backgroundColor: 'transparent',
        backgroundImage: `url(${dialogBg})`,
        backgroundSize: '598px 428px',
        backgroundPosition: '-24px -24px',
      }}
      data-testid="dialog"
      maskStyle={{ zIndex: 1000000001 }}
      zIndex={1000000002}
      onCancel={() => onCancel?.()}
    >
      <div className={styles.title}>有奖调研</div>
      <div className={styles.text}>
        <p>为了更好地服务洞察平台用户</p>
        <p>提升服务满意度</p>
        <p>诚邀你参与产品满意度调查</p>
        <p>（问卷预计1分钟可完成）</p>
        <br />
        <p>完成填写，会随机抽取用户赠送精美礼品1份</p>
      </div>
      <Button
        onClick={() => {
          window.open(`https://in.weisurvey.com/v2/?sid=6396d265cbd484b5bf0caf43&openid=${info?.user_id}`);
          onClick();
        }}
        data-testid="button"
        className={styles.button}
      >
        点击立即填写
      </Button>
    </Modal>
  );
};

export default Dialog;
