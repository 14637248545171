import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createContainer } from 'unstated-next';
import { getZoneInfo } from '@/services/single-product/admin/zone';
import { getAuthZone } from '@/services/single-product/home';
import { useFetch } from '@/hooks';
import history from '@/utils/history';

function useSingleProductInfo() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const SZID = params.get('szid');
  if (SZID) {
    localStorage.setItem('product_id', SZID);
  }
  const initProductId = SZID || localStorage.getItem('product_id');
  const { pathname } = location;
  if (
    !initProductId && pathname !== '/single-product/dashboard'
    && pathname.startsWith('/single-product')) {
    history.push('/single-product/dashboard');
  }
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [productId, setProductId] = useState<number>(initProductId ? +initProductId : 0);
  const [pinyin, setPinyin] = useState('');
  const interrupt = productId === 0
  || !pathname.includes('/single-product') || pathname.includes('/single-product/dashboard');

  const [menuAuth, setMenuAuth] = useState(false);
  const { data, loading, refresh: productInfoRefresh } = useFetch(() => getZoneInfo(productId), {
    interrupt,
    depends: [productId],
  });
  const { data: productData, refresh, loading: authProductDataLoading } = useFetch(getAuthZone, {
    interrupt: isFirstLoad === true,
    depends: [],
  });

  const onProductIdChange = (id: number) => {
    localStorage.setItem('product_id', String(id));
    setProductId(id);
  };

  const refreshAuthProductData = () => {
    setIsFirstLoad(false);
    refresh();
  };

  useEffect(() => {
    if (data && data.pinyin !== pinyin) {
      setPinyin(data.pinyin);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    onProductIdChange,
    data,
    productData,
    productId,
    refreshAuthProductData,
    loading,
    authProductDataLoading,
    menuAuth,
    changeMenuAuth: setMenuAuth,
    pinyin,
    setPinyin,
    productInfoRefresh,
  };
}

const ProductInfo = createContainer(useSingleProductInfo);

export default ProductInfo;
