import { createContainer } from 'unstated-next';
import { getHomeMenu } from '@/services/menu';
import { useFetch } from '@/hooks';

function useHomeMenu() {
  const { data } = useFetch(getHomeMenu);

  return {
    menu: Array.isArray(data) ? null : data,
  };
}

const HomeMenu = createContainer(useHomeMenu);

export default HomeMenu;
