/* eslint-disable no-underscore-dangle */
import React, {
  FC, useState, useEffect,
} from 'react';
import BraftEditor, { BuiltInControlType, ExtendControlType, EditorState } from 'braft-editor';
import { ContentUtils } from 'braft-utils';
import { FormatPainterFilled } from '@ant-design/icons';
import { Steps } from 'intro.js-react';
import classnames from 'classnames';
import 'braft-editor/dist/index.css';
import styles from './index.module.less';

interface RichEditorProps {
  richError?: boolean,
  placeholder?: string,
  onChange?: (editorState: any) => void,
  value?: string,
  contentStyle?: any,
}

const RichEditor: FC<RichEditorProps> = ({
  richError, placeholder, onChange, value, contentStyle,
}: RichEditorProps) => {
  const controls: BuiltInControlType[] = [
    'text-color', 'bold', 'italic', 'underline',
    'list-ul', 'list-ol', 'link',
  ];

  const [editorState, setEditorState] = useState('');
  const [isUseFormat, setIsUseFormat] = useState(false);
  const [inlineStyle, setInlineStyle] = useState<any>();
  const [exceed, setExceed] = useState<boolean>(false);

  const formatPainterRender = () => (
    <div className={classnames({ [styles.activeFormatPainter]: isUseFormat })}>
      <FormatPainterFilled />
    </div>
  );

  const extendControls: ExtendControlType[] = [
    {
      key: 'custom-button',
      type: 'button',
      text: formatPainterRender(),
      onClick: (event: MouseEvent) => {
        event.stopPropagation();
        if (isUseFormat) {
          setIsUseFormat(false);
        } else {
          setIsUseFormat(true);
          if (typeof editorState !== 'object') return;
          setInlineStyle(ContentUtils.getSelectionInlineStyle(editorState));
        }
      },
    },
  ];

  const fontFamilies: { name: string; family: string }[] = [{
    name: '微软雅黑',
    family: 'Microsoft YaHei',
  }, {
    name: '黑体',
    family: '"黑体",serif',
  }, {
    name: '宋体',
    family: '"宋体",sans-serif',
  }, {
    name: '楷体',
    family: '楷体',
  }, {
    name: 'Arial',
    family: 'Arial, Helvetica, sans-serif',
  }, {
    name: 'Georgia',
    family: 'Georgia, serif',
  }, {
    name: 'Helvetica',
    family: 'Helvetica, sans-serif',
  }, {
    name: 'Impact',
    family: 'Impact, serif',
  }];

  useEffect(() => {
    if (value) {
      setEditorState(value);
    }
  }, [value]);

  const handleChange = async (changeEditorState: any) => {
    if (changeEditorState?.toHTML()?.replace(/<[^>]+>/ig, '')?.length <= 5000) {
      setExceed(false);
    }
    setEditorState(changeEditorState);
    if (onChange) {
      onChange(changeEditorState.toHTML());
    }
  };

  const handlePastedText = (text: any, html: any, editorstate: any, editor: any) => {
    if (html) {
      const newHtml = html.replace(/<img src="file:.*?(?:>|\/>)/gi, '');
      editor.setValue(ContentUtils.insertHTML(editorstate, newHtml, 'paste'));
    } else {
      editor.setValue(ContentUtils.insertHTML(editorstate, text, 'paste'));
    }
    return 'handled';
  };

  const onEditorClick = () => {
    if (typeof editorState === 'object' && inlineStyle && isUseFormat && window.getSelection()?.type === 'Range') {
      const curSelectionStyle = ContentUtils.getSelectionInlineStyle(editorState);
      const curStyle: [string, boolean][] = curSelectionStyle._map._list._tail
        ?.array || [];
      const map: Map<string, boolean> = new Map();
      curStyle.forEach((item) => item && map.set(item[0], item[1]));
      const copyStyle: [string, boolean][] = inlineStyle._map._list._tail
        ?.array || [];
      copyStyle.forEach((item) => {
        if (item) {
          if (map.has(item[0])) {
            map.delete(item[0]);
          } else {
            map.set(item[0], item[1]);
          }
        }
      });
      const copyArr: any[] = Array.from(map);

      const nextEditorState = copyArr.reduce(
        (curEditorState: EditorState, style: string) => {
          const splitedStyle = style[0].split('-');
          const [start, end] = splitedStyle;
          const isMaxLength = splitedStyle.length === 2;

          return ContentUtils.toggleSelectionInlineStyle(
            curEditorState, isMaxLength ? end : start, isMaxLength ? `${start}-` : '',
          );
        }, editorState,
      );
      setEditorState(nextEditorState);
      setIsUseFormat(false);
    }
  };

  const hasPlaceholder = (state: object | string) => {
    if (typeof state === 'string' && state.replace(/<[^>]+>/ig, '') === '') {
      return true;
    }
    return false;
  };

  const hooks = {
    'toggle-link': ({ href, target }: { href: string, target: string }) => {
      const newHref = href.indexOf('http') === 0 ? href : `https://${href}`;
      return { href: newHref, target };
    },
  };

  return (
    <div onClick={onEditorClick}>
      <BraftEditor
        fontFamilies={fontFamilies}
        className={classnames(styles.richEditor, { [styles.isEmpty]: richError })}
        controls={controls}
        placeholder={hasPlaceholder(editorState) ? placeholder : undefined}
        onChange={handleChange}
        onBlur={() => {
          if ((value || '').replace(/<[^>]+>/ig, '').length > 5000) {
            setExceed(true);
          }
        }}
        value={editorState}
        contentStyle={contentStyle}
        handlePastedText={handlePastedText}
        hooks={hooks}
        extendControls={extendControls}
      />
      {exceed && (
        <p className={styles.exceed}>
          {`${value?.replace(/<[^>]+>/ig, '')?.length}/5000`}
        </p>
      )}
      {((window.location.pathname === '/single-product/admin/report/upload'
        && !localStorage.getItem('intro-single') && window.location.search === '?key=2')
        || (window.location.pathname === '/admin/report-upload'
          && !localStorage.getItem('intro-admin') && window.location.search === ''))
        && document.querySelector('.bf-controlbar')
        && (
        <Steps
          enabled
          steps={[
            {
              element: '.bf-controlbar',
              intro: '我们提供了样式编辑功能，让您可以突出报告里的关键内容。',
              position: 'right',
            },
          ]}
          initialStep={0}
          onExit={() => {
            if (window.location.pathname === '/admin/report-upload') {
              localStorage.setItem('intro-admin', 'true');
            }
            if (window.location.pathname === '/single-product/admin/report/upload') {
              localStorage.setItem('intro-single', 'true');
            }
          }}
          options={{
            showBullets: false,
            tooltipClass: styles.introTooltip,
            buttonClass: styles.introButton,
            doneLabel: '知道了',
          }}
        />
        )}
    </div>
  );
};

export default RichEditor;
