/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import React, { FC, useState, useEffect } from 'react';
import { Layout, Menu, Divider } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import {
  UserInfoContext, MenuUrlContext, HomeMenuContext, RemindCache,
} from '@/context';
import logo from 'assets/images/logoZH.svg';
import logoEn from 'assets/images/logoEN.svg';
import { useTranslation } from 'react-i18next';
import { CURRENT_LANGUAGE } from '@/i18n';
import classnames from 'classnames';
import remindIcon from '@/assets/images/remindIcon.png';
import NavAvatar from '../nav-avatar';
import SwitchLang from './switch-lang';
import styles from './index.module.less';

const { Header } = Layout;

interface HomeHeaderProps {
  className?: string,
}

const HomeHeader: FC<HomeHeaderProps> = ({ className }: HomeHeaderProps) => {
  const { t } = useTranslation();
  const { info, isPayingUsers } = UserInfoContext.useContainer();
  const { changeURL, changeData } = MenuUrlContext.useContainer();
  const { menu } = HomeMenuContext.useContainer();
  const { hasRemindCache, setHasRemindCache } = RemindCache.useContainer();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const location = useLocation();

  const navToBase = (path: string) => {
    if (path !== '/base') return;
    if (location.pathname === '/base') return;
    changeURL('');
    changeData(0);
  };

  useEffect(() => {
    if (selectedKeys[0] !== `/${location.pathname.split('/')[1]}`) {
      setSelectedKeys([`/${location.pathname.split('/')[1]}`]);
    }
  }, [location.pathname, selectedKeys]);

  const handleClickRemind = () => {
    setHasRemindCache('true');
    localStorage.setItem('remind-cache', 'true');
  };

  return (
    <Header className={classnames(styles.homeHeader, className, styles.header)}>
      <a
        href="/" className={classnames(
          'nav-logo',
          {
            'en-nav-logo': CURRENT_LANGUAGE === 'en' && !isPayingUsers,
            'en-nav-logo-pay': CURRENT_LANGUAGE === 'en' && isPayingUsers,
            'zh-nav-logo': CURRENT_LANGUAGE === 'zh' && !isPayingUsers,
            'zh-nav-logo-pay': CURRENT_LANGUAGE === 'zh' && isPayingUsers,
          },
        )}
      >
        <div style={{ position: 'relative' }}>
          <img src={CURRENT_LANGUAGE === 'en' ? logoEn : logo} alt="i-mur洞察平台" />
          {isPayingUsers && (
            <span>{CURRENT_LANGUAGE === 'en' ? '(subs)' : '(订阅版)'}</span>
          )}
        </div>
      </a>
      <Menu
        mode="horizontal"
        selectedKeys={location.pathname === '/report-library/search' ? [] : selectedKeys}
      >
        <Menu.Item key="/">
          <NavLink to="/">
            <span>{t('home')}</span>
          </NavLink>
        </Menu.Item>
        {menu?.children.map((item) => (
          <Menu.Item key={item.data.path.match(/\/[a-zA-Z-]+/)?.[0]} onClick={() => navToBase(item.data.path)}>
            <NavLink to={item.data.path}>
              {CURRENT_LANGUAGE === 'en' ? item.en_name : item.name}
            </NavLink>
          </Menu.Item>
        ))}
        <Menu.Item key="/personal-center">
          <NavLink to="/personal-center">
            <span>{t('personal_center')}</span>
          </NavLink>
        </Menu.Item>
      </Menu>
      <div className="nav-right-action">
        <SwitchLang />
        <Divider type="vertical" />
        <NavLink onClick={handleClickRemind} to="/remind-page">
          <span className={classnames({ [styles.hasUpdate]: hasRemindCache !== 'true' })}>
            <img
              className={classnames(styles.remindIcon)} src={remindIcon}
              alt=""
            />
          </span>
        </NavLink>

        <NavAvatar userInfo={info} />
      </div>
    </Header>
  );
};

export default HomeHeader;
