import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createContainer } from 'unstated-next';
import { UserGroupItemData } from '@/services/user-group';
import configs from '@/configs';

const ALL_USER_GROUP_ID = configs('user-group.allGroupId');

function useUserGroupContent() {
  const location = useLocation();
  const [userGroupId, setUserGroupId] = useState(ALL_USER_GROUP_ID);
  const [userGroup, setUserGroup] = useState<UserGroupItemData[]>([]);
  const [userGroupChange, setUserGroupChange] = useState(false);

  useEffect(() => {
    setUserGroupId(ALL_USER_GROUP_ID);
  }, [location.pathname]);

  return {
    userGroupId,
    setUserGroupId,
    userGroup,
    setUserGroup,
    userGroupChange,
    setUserGroupChange,
  };
}

const UserGroupContent = createContainer(useUserGroupContent);

export default UserGroupContent;
