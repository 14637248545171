import { createContainer } from 'unstated-next';
import { useState, useEffect } from 'react';
import { UPDATE_TIME } from '@/utils/updateTime';

function useRemindCache() {
  const [hasRemindCache, setHasRemindCache] = useState<string>(localStorage.getItem('remind-cache') || '');

  useEffect(() => {
    if (UPDATE_TIME.trim() === 'false') return;
    const dateTime = new Date(UPDATE_TIME).getTime().toString();
    const oldTime = localStorage.getItem('update-time');
    if (!oldTime || (oldTime && oldTime !== dateTime)) {
      localStorage.setItem('update-time', dateTime);
      localStorage.setItem('remind-cache', '');
      setHasRemindCache('');
    }
  }, []);

  return {
    hasRemindCache,
    setHasRemindCache,
  };
}

const RemindCache = createContainer(useRemindCache);

export default RemindCache;
