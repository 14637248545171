/* eslint-disable camelcase */
import React, {
  FC, useState, MouseEvent, useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button, Popconfirm, Popover, Result, Modal,
} from 'antd';
import {
  LikeOutlined, StarOutlined, LikeFilled, StarFilled, ShareAltOutlined, ClockCircleOutlined, ClockCircleFilled,
  DownloadOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { UserInfoContext, BeaconContext } from '@/context';
import { AuthData } from '@/services/common';
import DownloadModal from './download-modal';
import styles from '../index.module.less';
import ShareContent from './share-content';
import AppointmentContent from './appointment-content';

interface ActionBarProps {
  likeNum: number,
  collectNum: number,
  isLike: boolean,
  isFavorites: boolean,
  isPublic?: boolean,
  isSpecial?: boolean,
  needShare?: boolean,
  needClass?: boolean,
  needDownload?: boolean,
  data?: any,
  id?: number,
  title: string,
  style?: any,
  onFavorite: (event: MouseEvent, checked: boolean, onFailedCallback: () => void) => void,
  onLike: (event: MouseEvent, checked: boolean, onFailedCallback: () => void) => void,
  onPublicClass?: (event: MouseEvent, checked: boolean, onFailedCallback: () => void) => void,
  onSpecialClass?: (event: MouseEvent, checked: boolean, onFailedCallback: () => void) => void,
  onShare?: () => void,
  shareLink?: string,
  mobilePath?: string,
  auth?: AuthData,
}

let timer: NodeJS.Timeout;

const ActionBar: FC<ActionBarProps> = ({
  isLike = false, title, isFavorites = false, isPublic = false, isSpecial = false, needShare = true, needClass = false,
  onFavorite, onLike, onShare, mobilePath, onPublicClass = () => {}, onSpecialClass = () => {}, needDownload = false,
  style, shareLink, id, data, auth,
}: ActionBarProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { info } = UserInfoContext.useContainer();
  const { beacon } = BeaconContext.useContainer();
  const [isLiked, setIsLiked] = useState<boolean>(isLike);
  const [isCollected, setIsCollected] = useState(isFavorites);
  const [popconfirmVisible, setPopconfirmVisible] = useState(false);

  const [isPubliced, setIsPubliced] = useState<boolean>(isPublic);
  const [isSpecialed, setIsSpecialed] = useState<boolean>(isSpecial);
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  useEffect(() => {
    setIsLiked(isLike);
    setIsCollected(isFavorites);
  }, [isFavorites, isLike]);

  useEffect(() => {
    setIsPubliced(isPublic);
    setIsSpecialed(isSpecial);
  }, [isPublic, isSpecial]);

  const handleCollectClick = (event: MouseEvent) => {
    const collected = isCollected;
    setIsCollected(!isCollected);
    onFavorite(event, !isCollected, () => setIsCollected(collected));
  };

  const handlePublicClass = (event: MouseEvent) => {
    const publiced = isPubliced;
    setIsPubliced(!isPubliced);
    setIsSpecialed(false);
    onPublicClass(event, !isPubliced, () => setIsLiked(publiced));
  };

  const handleSpecialClass = (event: MouseEvent) => {
    const specialed = isSpecialed;
    setIsSpecialed(!isSpecialed);
    setIsPubliced(false);
    onSpecialClass(event, !isSpecialed, () => setIsLiked(specialed));
  };

  const handleLikeClick = (event: MouseEvent) => {
    const liked = isLiked;
    setIsLiked(!isLiked);
    onLike(event, !isLiked, () => setIsLiked(liked));
  };

  const handleShareButtonClick = () => {
    setPopconfirmVisible(true);
    if (onShare) {
      onShare();
    }
  };

  const handleDownloadButtonClick = async () => {
    beacon.onUserAction(
      'click_game_report_download_file',
      {
        user_id: info?.user_id,
        report_id: data?.openid,
        game_id: queryParams.get('szid'),
      },
    );
    if (auth?.download_auth) {
      setModalVisible(true);
      return;
    }
    if (timer) clearTimeout(timer);
    setPopoverVisible(true);
    timer = setTimeout(() => {
      setPopoverVisible(false);
    }, 3000);
  };

  const handleAppointmentButtonClick = () => {
    setPopconfirmVisible(true);
  };

  return (
    <div className={styles.actionBar} style={{ ...style }}>
      <Button type={isCollected ? 'primary' : 'default'} onClick={handleCollectClick}>
        {isCollected ? <StarFilled /> : <StarOutlined />}
        {t('favorite')}
      </Button>
      <Button type={isLiked ? 'primary' : 'default'} onClick={handleLikeClick}>
        {isLiked ? <LikeFilled /> : <LikeOutlined />}
        {t('like')}
      </Button>
      {
        needDownload && (
          <Popover
            content={t('download_no_permission')}
            color="rgba(0, 0, 0, 0.75)"
            overlayClassName={styles.popover}
            visible={popoverVisible}
          >
            <Button
              type="default"
              onClick={handleDownloadButtonClick}
            >
              <DownloadOutlined />
              {t('download')}
            </Button>
          </Popover>
        )
      }
      {
        needDownload && (
          <DownloadModal
            id={id as number}
            data={data}
            auth={auth}
            visible={modalVisible}
            changeVisible={(visible: boolean, isFinish: boolean) => {
              setModalVisible(visible);
              if (isFinish) {
                Modal.success({
                  icon: null,
                  content: (
                    <Result
                      status="success"
                      title={t('idata_submitted')}
                      subTitle={t('download_submitted')}
                    />
                  ),
                  centered: true,
                  width: 800,
                  okText: t('close_page'),
                  className: styles.submitSuccessModal,
                });
              }
            }}
          />
        )
      }
      {
        !!needShare && (
        <Popconfirm
          title={(
            <ShareContent
              onClose={() => setPopconfirmVisible(false)}
              title={title} mobilePath={mobilePath}
              shareLink={shareLink}
            />
        )}
          icon={null}
          placement="bottomRight" cancelText={null}
          okText={null}
          overlayClassName={classnames(styles.sharePopContainer,
            shareLink ? styles.signalsSharePop : styles.reportSharePop)}
          visible={popconfirmVisible} onVisibleChange={(visible) => setPopconfirmVisible(visible)}
        >
          <Button type="default" onClick={handleShareButtonClick}>
            <ShareAltOutlined />
            {t('share')}
          </Button>
        </Popconfirm>
        )
      }
      {!!needClass && (
      <Popconfirm
        title={(
          <AppointmentContent
            isPubliced={isPubliced}
            isSpecialed={isSpecialed}
            onSpecialClass={handleSpecialClass}
            onPublicClass={handlePublicClass}
            onClose={() => setPopconfirmVisible(false)}
          />
        )}
        icon={null}
        placement="bottomRight" cancelText={null}
        okText={null}
        overlayClassName={classnames(styles.sharePopContainer,
          shareLink ? styles.signalsSharePop : styles.reportAppointmnetPop)}
        visible={popconfirmVisible} onVisibleChange={(visible) => setPopconfirmVisible(visible)}
      >
        <Button
          className={classnames(styles.AppointmentButton,
            { [styles.hasClass]: isPubliced || isSpecialed })} type="default"
          onClick={handleAppointmentButtonClick}
        >
          { isPubliced || isSpecialed ? <ClockCircleFilled /> : <ClockCircleOutlined />}
          {t('signUp')}
        </Button>
      </Popconfirm>
      )}
    </div>
  );
};

export default ActionBar;
