/* eslint-disable camelcase */
import React, { FC, useState } from 'react';
import {
  Table, Button, Popconfirm, Modal, Form, Checkbox, Radio, message, Row, Col,
} from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useFetch } from '@/hooks';
import {
  ApprovalDataItem, updateApproval, UpdateApprovalData, ApprovalListParams, ApprovalData,
} from '@/services/authority';
import { updateProductApproval } from '@/services/single-product/admin/authority';
import { getUserGroup } from '@/services/user-group';
import { removeObjectFalsyKey } from '@/utils/helper/base';
import TableSearchProps from '@/props/table-search';
import configs from '@/configs';
import request from '@/utils/request';
import { BASIC_REPORT_TYPE } from '@/pages/admin/report-upload/base-util';
import { ViewModal, PermissionSettingModal, ReasonForm } from './components';
import {
  getPermissionType, passOperateOptions, BASIC_PERMISSION_TYPE,
  REFUSE_NUMBER, FORM_TYPE, REFUSE_FORM_TYPE, DEFAULT_TAB, PAGE_SIZE, initParams, initFilterInfo,
} from './base-util';
import styles from './index.module.less';

const NORMAL_GROUP_PREM = configs('user-group.normalGroupPrem');

const { getColumnSearchProps } = TableSearchProps;

interface AuthorityApprovalProps {
  getListServiceFn: (params: ApprovalListParams) => Promise<ApprovalData>,
  productId?: number,
  onViewDetail: (type: number, id: number) => void,
}

const AuthorityApproval: FC<AuthorityApprovalProps> = ({
  getListServiceFn, productId, onViewDetail,
}: AuthorityApprovalProps) => {
  const tabs = [{ label: '等待审批', value: 0 }, { label: '完成审批', value: 1 }];
  const [refuseFormVisible, setRefuseFormVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [permissionSettingVisible, setPermissionSettingVisible] = useState(false);
  const [userGroupVisible, setUserGroupVisible] = useState(false);
  const [form] = Form.useForm();
  const [refuseForm] = Form.useForm();
  const [filteredInfo, setFilteredInfo] = useState(initFilterInfo);
  const [params, setParams] = useState<ApprovalListParams>(initParams);
  const { data, loading, refresh } = useFetch(
    () => getListServiceFn(params), { depends: [params] },
  );

  const { data: userGroup } = useFetch(() => getUserGroup(NORMAL_GROUP_PREM), { defaultData: [], depends: [] });

  const [approvalProgressId, setApprovalProgressId] = useState(0);
  const [approvalReason, setApprovalReason] = useState<string>('');
  const [approvalType, setApprovalType] = useState(0);

  const handleTabsChange = (event: RadioChangeEvent) => {
    setFilteredInfo(initFilterInfo);
    setParams({ status: event.target.value, page: 1, page_size: PAGE_SIZE });
  };

  const confirmView = (record: ApprovalDataItem) => {
    if (record.tail && record.type === BASIC_PERMISSION_TYPE) {
      setUserGroupVisible(true);
    } else {
      setUserGroupVisible(false);
    }
    setFormVisible(true);
    setApprovalProgressId(record.id);
    setApprovalReason(record.reason);
    setApprovalType(record.type);
    form.setFieldsValue({ type: REFUSE_NUMBER, reason: undefined });
  };

  const handleCancel = (submitFormType: number) => {
    if (submitFormType === FORM_TYPE) {
      setFormVisible(false);
      form.setFieldsValue({ type: REFUSE_NUMBER, reason: undefined });
    } else {
      setRefuseFormVisible(false);
    }
  };

  const confirmRefuse = (id: number) => {
    setApprovalProgressId(id);
    setRefuseFormVisible(true);
  };

  const confirmDel = async (id: number) => {
    const res = await request.delete(`/api/adminapi/approval/del/${id}`);
    if (res.data) {
      message.success('删除成功');
      setParams({ ...params });
    } else {
      message.success('删除失败');
    }
  };

  const onFinish = async (values: any) => {
    try {
      let result;
      const payload = removeObjectFalsyKey({ ...values, id: approvalProgressId }) as UpdateApprovalData;
      if (productId) {
        result = await updateProductApproval(productId, payload);
      } else {
        result = await updateApproval(payload);
      }
      if (result) {
        message.success('编辑成功!');
      } else {
        message.error('编辑失败!');
      }
    } catch (e) {
      if (e.errors?.detail) {
        message.error(e.errors.detail);
      }
    }
    setFormVisible(false);
    setRefuseFormVisible(false);
    refresh();
    refuseForm.resetFields();
    form.setFieldsValue({ type: REFUSE_NUMBER, reason: undefined });
  };

  const confirmPass = () => {
    onFinish(passOperateOptions);
  };

  const handlePermissionSettingShow = (id: number, tail: boolean) => {
    setApprovalProgressId(id);
    if (tail) {
      setUserGroupVisible(true);
      return setPermissionSettingVisible(true);
    }
    return onFinish(passOperateOptions);
  };

  const onTableChange = (pagination: any, filters: any, sorter: any,
    { action }: { action: 'paginate' | 'sort' | 'filter' }) => {
    const { current } = pagination;
    const { user_id: userId, department } = filters;
    setFilteredInfo(filters);
    const assignsObject = removeObjectFalsyKey({
      rtx: userId?.[0],
      department: department?.[0],
    });
    setParams({
      page: action === 'paginate' ? current : 1,
      status: params.status,
      page_size: PAGE_SIZE,
      ...assignsObject,
    });
  };

  const columns = [
    { title: 'ID', dataIndex: 'id' },
    {
      title: '申请人',
      dataIndex: 'user_id',
      render: (
        user_id: string,
        record: ApprovalDataItem,
      ) => `${user_id}${record.user_name ? `(${record.user_name})` : ''}`,
      ...getColumnSearchProps(),
      filteredValue: filteredInfo.user_id || null,
    },
    {
      title: '所属部门', dataIndex: 'department', ...getColumnSearchProps(), filteredValue: filteredInfo.department || null,
    },
    {
      title: '申请类型',
      dataIndex: 'type',
      render: (type: number) => (<>{getPermissionType(type)}</>),
    },
    {
      title: '备注',
      dataIndex: 'type',
      render: (type: number, record: ApprovalDataItem) => {
        const { object_type: title, object_id: objectId } = record;
        if (type === BASIC_PERMISSION_TYPE) return '';
        return (
          <Button
            type="text" className={styles.linkText}
            onClick={() => onViewDetail(type, objectId)}
          >
            {title?.length > 15 ? `${title.substring(0, 15)}...` : title}
          </Button>
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'id',
      render: (id: number, record: any) => (
        <>
          <Button
            type="text" className="primaryButton"
            onClick={() => confirmView(record)}
          >
            查看
          </Button>
          {
            params.status === DEFAULT_TAB && (
              <>
                {(record.type !== BASIC_PERMISSION_TYPE
                  || (record.type === BASIC_REPORT_TYPE && record.tail === false)) && (
                    <Popconfirm
                      title="你确定要通过这条申请么?" okText="确认"
                      cancelText="取消" onConfirm={confirmPass}
                    >
                      <Button
                        type="text" className="primaryButton"
                        onClick={() => setApprovalProgressId(id)}
                      >
                        通过
                      </Button>
                    </Popconfirm>
                )}
                {
                  record.type === BASIC_PERMISSION_TYPE && record.tail && (
                    <Button
                      type="text" className="primaryButton"
                      onClick={() => handlePermissionSettingShow(id, record.tail)}
                    >
                      通过
                    </Button>
                  )
                }
                <Button
                  type="text" danger
                  onClick={() => confirmRefuse(id)}
                >
                  拒绝
                </Button>
                <Button
                  type="text" danger
                  onClick={() => confirmDel(id)}
                >
                  删除
                </Button>
              </>
            )
          }
          {
            params.status !== DEFAULT_TAB
            && <Button type="text">{record.status === REFUSE_NUMBER ? '已拒绝' : '已通过'}</Button>
          }
        </>
      ),
    },
  ];

  const onRefuseFinish = (values: any) => {
    onFinish({ ...values, operate: 2 });
  };

  const userGroupFormItem = () => (
    userGroup && userGroupVisible && (
      <Form.Item
        label="用户分组" name="group_name"
        rules={[{ required: true, message: '请选择用户所属分组' }]}
      >
        <Checkbox.Group>
          <Row>
            {userGroup.map((item) => item.id && (
              <Col span={8}>
                <Checkbox value={item.name} key={item.id}>{item.name}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>
    )
  );

  const viewForm = () => (
    <Form
      form={form} onFinish={onFinish}
      className={styles.form}
    >
      <Form.Item
        label="操作" name="operate"
        initialValue={1}
      >
        <Radio.Group>
          <Radio value={1}>通过</Radio>
          <Radio value={2}>拒绝</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate
      >
        {({ getFieldValue }) => (
          getFieldValue('operate') !== 1 ? <ReasonForm /> : null
        )}
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          getFieldValue('operate') === 1 ? userGroupFormItem() : null
        )}
      </Form.Item>
    </Form>
  );

  const onPermissionSettingSuccess = () => {
    setPermissionSettingVisible(false);
    refresh();
  };

  return (
    <div className="mur-admin-container">
      <Radio.Group
        value={params.status} onChange={handleTabsChange}
        buttonStyle="solid" className={styles.actionBar}
      >
        {tabs.map((item) => <Radio.Button key={item.value} value={item.value}>{item.label}</Radio.Button>)}
      </Radio.Group>
      <ViewModal
        visible={formVisible} id={approvalProgressId}
        onOk={params.status === DEFAULT_TAB ? form.submit : () => setFormVisible(false)}
        onCancel={() => handleCancel(FORM_TYPE)}
        reason={approvalReason}
        content={params.status === DEFAULT_TAB ? viewForm() : null}
        productId={productId}
        type={approvalType}
      />
      {userGroup && (
        <PermissionSettingModal
          visible={permissionSettingVisible}
          onCancel={() => setPermissionSettingVisible(false)}
          onOk={onPermissionSettingSuccess}
          id={approvalProgressId}
          formContent={userGroupFormItem}
        />
      )}
      <Modal
        visible={refuseFormVisible}
        title="请输入拒绝的理由"
        okText="确认"
        onOk={refuseForm.submit}
        onCancel={() => handleCancel(REFUSE_FORM_TYPE)}
      >
        <Form
          form={refuseForm} onFinish={onRefuseFinish}
        >
          <ReasonForm />
        </Form>
      </Modal>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data?.data}
        rowKey="id"
        pagination={{
          position: ['bottomCenter'],
          total: data?.total,
          pageSize: PAGE_SIZE,
          pageSizeOptions: [`${PAGE_SIZE}`],
          showQuickJumper: true,
          showSizeChanger: false,
        }}
        onChange={onTableChange}
      />
    </div>
  );
};

export default AuthorityApproval;
