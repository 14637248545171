/* eslint-disable camelcase */
import { productRequest } from '@/utils/helper/base';
import {
  MenuTreeData, MenuItemData, MenuUserGroupData, UpdateMenuPayload,
} from '@/services/menu';
import {
  ProductId, MenuId, AdminMenuTreeData, UpdateAdminMenuPayload,
} from '@/services/common';

export async function getIDataMenu(productId: number, pinyin: string): Promise<MenuTreeData> {
  const res = await productRequest(`/api/szadmin/${pinyin}/menu/tree`, productId);

  return res.data;
}

export async function getProductMenuItem(productId: ProductId, menuId: MenuId): Promise<MenuItemData> {
  const res = await productRequest(`/api/szadmin/menu/${menuId}`, productId);

  return res.data;
}

export async function getProductMenuUserGroup(
  productId: ProductId, menuId: MenuId, page: number, pageSize: number,
): Promise<MenuUserGroupData> {
  const res = await productRequest(`/api/szadmin/auth/${menuId}/group?page=${page}&page_size=${pageSize}`, productId);

  return res.data;
}

export async function addProductMenu(productId: ProductId, payload: FormData) {
  const res = await productRequest(
    '/api/szadmin/menu/', productId,
    {
      method: 'post',
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );

  return res.data;
}

export async function updateProductMenu(
  productId: ProductId, menuId: MenuId, payload: FormData,
): Promise<Boolean> {
  const res = await productRequest(`/api/szadmin/menu/${menuId}`, productId,
    {
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: payload,
    });

  return res.data;
}

export async function updateProductMenuOrder(productId: ProductId, payload: UpdateMenuPayload) {
  const { insertType, targetMenuId, neighborOrParentId } = payload;
  const res = await productRequest(
    `/api/szadmin/menu/order/${insertType}/${targetMenuId}/${neighborOrParentId}`, productId, { method: 'put' },
  );

  return res;
}

export async function deleteProductMenu(productId: ProductId, menuId: MenuId): Promise<Boolean> {
  await productRequest(`/api/szadmin/menu/${menuId}`, productId, { method: 'delete' });

  return true;
}

export async function getAdminMenuTree(productId: ProductId, gid: number): Promise<AdminMenuTreeData> {
  const res = await productRequest(`/api/szadmin/adminMenu/tree/${gid}?scope=webSzadmin`, productId);

  return res.data;
}

export async function updateAdminMenuTree(productId: ProductId, payload: UpdateAdminMenuPayload) {
  const res = await productRequest(
    '/api/szadmin/adminMenu/auth?scope=webSzadmin', productId,
    { method: 'post', data: payload },
  );

  return res.data;
}
