import React, { FC, useEffect, useState } from 'react';
import { Form, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import classnames from 'classnames';
import configs from '@/configs';
import { FormInstance } from 'antd/lib/form';
import FormCoverUpload from './cover-upload';
import styles from './index.module.less';

interface CoverUploadProps {
  reset: boolean,
  initialValues?: string,
  initialBanner?: string,
  needBanner?: boolean,
  needReportType?: boolean,
  coverPicType?: number,
  form?: FormInstance,
}
const COVER_REPORT_FONT_PAGE_TYPE = configs('report.coverReportFontPageType');
const COVER_CUSTOM_TYPE = configs('report.coverCustomType');
const COVER_RANDOM_TYPE = configs('report.coverRandomType');

const CoverUpload: FC<CoverUploadProps> = ({
  initialValues, reset, needReportType = false,
  coverPicType, needBanner, initialBanner, form,
}: CoverUploadProps) => {
  const maxCoverSize = 4;
  const defaultType = needReportType ? (coverPicType || COVER_RANDOM_TYPE) : COVER_REPORT_FONT_PAGE_TYPE;
  const [coverType, setCoverType] = useState(defaultType);

  const customOptions = [
    { label: '智能封面', value: COVER_RANDOM_TYPE },
    { label: '自定义封面', value: COVER_CUSTOM_TYPE },
  ];

  const options = needReportType ? customOptions : [
    { label: '报告首页', value: COVER_REPORT_FONT_PAGE_TYPE },
    { label: '随机封面', value: COVER_RANDOM_TYPE },
    { label: '自定义封面', value: COVER_CUSTOM_TYPE },
  ];

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      sm: { span: 20, offset: 4 },
    },
  };

  useEffect(() => {
    if (reset) {
      setCoverType(defaultType);
    }
  }, [defaultType, reset]);

  useEffect(() => {
    if (initialValues) {
      if (!coverPicType) {
        setCoverType(2);
      } else {
        setCoverType(coverPicType);
      }
    }
  }, [coverPicType, initialValues]);

  const handleCoverTypeChange = (event: RadioChangeEvent) => {
    const { value } = event.target;
    setCoverType(value);
  };

  return (
    <>
      <Form.Item
        label="上传封面"
        required
        className={classnames({ [styles.borderFormItem]: coverType === COVER_REPORT_FONT_PAGE_TYPE })}
        name="cover_pic_type"
      >
        <Radio.Group
          optionType="button" options={options}
          onChange={handleCoverTypeChange}
          buttonStyle="solid"
          defaultValue={defaultType}
        />
      </Form.Item>
      {coverType === COVER_CUSTOM_TYPE && (
        <FormCoverUpload
          initialValues={initialValues}
          initialBanner={initialBanner}
          reset={reset}
          form={form}
          needBanner={needBanner}
          maxCoverSize={maxCoverSize}
          name="cover_pic"
          className={styles.formCoverPicContainer}
          formClassName={styles.borderFormItem}
          rules={[{
            required: true,
            message: '请上传封面!',
          }]}
          tips={`提示：当前仅支持 ${maxCoverSize} M 以内的 JPG 或 PNG 格式图片`}
          {...formItemLayoutWithOutLabel}
          style={{ marginLeft: '4px', marginTop: '4px' }}
        />
      )}
    </>
  );
};
export default CoverUpload;
