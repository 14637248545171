/* eslint-disable camelcase */
import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import _ from 'lodash';
import {
  Alert, Form, Input, Button, Radio, Typography, message, Select, Upload,
} from 'antd';
import configs from '@/configs';
import { MenuPermission } from '@/configs/type';
import { AdminBaseMenuContext } from '@/context';
import {
  MenuItemData, UpdateMenuItemData, getMenuItem, updateMenu, addMenu,
} from '@/services/menu';
import { getProductMenuItem, addProductMenu, updateProductMenu } from '@/services/single-product/admin/menu';
import { getUserGroup, UserGroupItemData } from '@/services/user-group';
import {
  fileToBase64, objectToFormData, removeObjectFalsyKey,
} from '@/utils/helper/base';
import { getUserGroup as getProductUserGroup } from '@/services/single-product/admin/user-group';
import { useWatch } from '@/hooks';
import { RtxSelect } from '@/components';
import { getCos } from '@/services/report-library-admin';
import classnames from 'classnames';
import {
  handleGroupSelect, handleGroupDelete, validateSpecialPerm, validateRtx,
} from '@/utils/helper/form';
import { PlusOutlined } from '@ant-design/icons';
import COS from 'cos-js-sdk-v5';
import {
  isUrlType, isSpecialPermType, removeAllUserGroupId, baseUserGroupFilter,
} from './base-util';
import styles from './index.module.less';

const DIRECTORY_TYPE = configs('menu.directoryType');
const URL_TYPE = configs('menu.urlType');
const PERMISSIONS = configs('menu.permissions');
const ANYONE_PERM = configs('menu.anyonePerm');
const BASE_USER_GROUP_NAME = configs('user-group.baseUserGroupName');
const UPLOAD_URL = configs('app.uploadUrl');
const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const unSelectedMenu = (menuId: number) => menuId === 0;

interface EditorProps {
  onSaveSuccess: Function,
  productId?: number,
}

const Editor: FC<EditorProps> = ({ onSaveSuccess, productId }: EditorProps) => {
  const {
    menuId, menuAction, resetMenu, selectMenu,
  } = AdminBaseMenuContext.useContainer();
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const editorRef = useRef<HTMLDivElement>(null);
  const [form] = Form.useForm();
  const [data, setData] = useState<MenuItemData>();
  const [userGroupData, setUserGroupData] = useState<UserGroupItemData[]>();
  const [dataImageUrl, setDataImageUrl] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string>('');
  const [menuType, setMenuType] = useState<number>();
  const [coverPicUrl, setCoverPicUrl] = useState<string>('');
  const [isUploadImg, setIsUploadImg] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  useWatch(menuId, async () => {
    if (unSelectedMenu(menuId)) return;
    let menuItemData;
    if (productId) {
      menuItemData = await getProductMenuItem(productId, menuId);
    } else {
      menuItemData = await getMenuItem(menuId);
    }
    setData(menuItemData);
  });

  const getUserGroupData = async () => {
    let groupData;
    if (productId) {
      groupData = await getProductUserGroup(productId, 'normal');
    } else {
      groupData = await getUserGroup('normal');
    }
    setUserGroupData(groupData);
  };

  useEffect(() => {
    getUserGroupData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultHideFunc = true;
  const initialValues = {
    associated_rid: '',
    cover_pic: '',
    icon: '',
    url: '',
    introduction: '',
    name: '',
    type: DIRECTORY_TYPE,
    urlType: 'idata',
    hideFunc: defaultHideFunc,
    charge_by: [],
    read_group: [],
    search_group: [],
    read_perm: ANYONE_PERM,
    search_perm: ANYONE_PERM,
    read_user: [],
    search_user: [],
    paid_user_only: '0',
  };
  const [chargeBy, setChargeBy] = useState<string[]>([]);
  const [readUsers, setReadUsers] = useState<string[]>([]);
  const [searchUsers, setSearchUsers] = useState<string[]>([]);

  useEffect(() => {
    if (data) {
      const {
        associated_rid: associatedRid, cover_pic: coverPic, icon, data: dataItem, introduction,
        name, read_group: readGroup, read_perm: readPerm, search_group: searchGroup,
        search_perm: searchPerm, type, title, paid_user_only: paidUserOnly,
      } = data;
      const readUser = data.read_user?.map((item) => item.user_id) || [];
      const searchUser = data.search_user?.map((item) => item.user_id) || [];
      const charges = Array.from(new Set(dataItem?.charge_by || []));
      form.setFieldsValue({
        associated_rid: associatedRid,
        cover_pic: type === URL_TYPE ? '' : coverPic,
        data_cover_pic: type === URL_TYPE ? coverPic : '',
        icon,
        url: dataItem?.url,
        introduction: type === URL_TYPE ? '' : introduction,
        data_introduction: type === URL_TYPE ? introduction : '',
        name,
        type,
        title,
        urlType: dataItem?.type,
        hideFunc: !!dataItem?.hideFunc,
        charge_by: charges,
        read_group: readGroup?.filter((item) => item.group_name !== BASE_USER_GROUP_NAME).map((item) => item.id),
        search_group: searchGroup?.filter((item) => item.group_name !== BASE_USER_GROUP_NAME).map((item) => item.id),
        read_perm: readPerm,
        search_perm: searchPerm,
        read_user: readUser,
        search_user: searchUser,
        paid_user_only: paidUserOnly ? '1' : '0',
      });
      setMenuType(type);
      setCoverPicUrl(coverPic || '');
      setIsUploadImg(false);
      setChargeBy(charges);
      setReadUsers(readUser);
      setSearchUsers(searchUser);
      if (type === URL_TYPE) {
        setDataImageUrl(coverPic || '');
      } else {
        setImageUrl(coverPic || '');
      }
    }

    if (!menuId && formRef.current) {
      form.resetFields(['name', 'type', 'title', 'url', 'associated_rid', 'icon', 'introduction', 'data_introduction',
        'read_perm', 'search_perm', 'search_group', 'read_group', 'hideFunc', 'urlType']);
      setChargeBy([]);
      setReadUsers([]);
      setSearchUsers([]);
      setImageUrl('');
      setCoverPicUrl('');
      setIsUploadImg(false);
      setDataImageUrl('');
      form.setFieldsValue({
        charge_by: [],
        read_user: [],
        search_user: [],
        cover_pic: '',
        data_cover_pic: '',
      });
    }
  }, [data, defaultHideFunc, form, formRef, menuId]);

  useWatch<number>(menuId, () => {
    if (editorRef?.current) {
      editorRef.current.scrollTop = 0;
    }
  });

  const onFinish = async (values: any) => {
    const dataValue = { ...values };
    ['url', 'urlType', 'hideFunc', 'charge_by', 'cover_pic', 'data_cover_pic', 'data_introduction'].forEach((key) => {
      delete dataValue[key];
    });
    setLoading(true);
    const requestData = {
      data: values.type === URL_TYPE ? {
        url: values.url,
        type: values.urlType,
        hideFunc: values.hideFunc ? 1 : 0,
        charge_by: values.charge_by,
      } : {},
      ...dataValue,
      introduction: values.type === URL_TYPE ? values.data_introduction : values.introduction,
      read_group: values.read_group ? removeAllUserGroupId(values.read_group) : undefined,
      search_group: values.search_group ? removeAllUserGroupId(values.search_group) : undefined,
      paid_user_only: values.paid_user_only || '0',
    };
    if (menuAction?.action === 'add') {
      const menuData = removeObjectFalsyKey({
        perm: ANYONE_PERM,
        parent_id: menuAction.parentId,
        cover_pic: isUploadImg ? coverPicUrl : null,
        ...requestData,
      }) as UpdateMenuItemData;
      const payload = objectToFormData(menuData);
      let res;
      if (productId) {
        res = await addProductMenu(productId, payload);
      } else {
        res = await addMenu(payload);
      }
      if (res.id) {
        selectMenu(res.id);
        message.success('新增配置成功');
      } else {
        message.error('新增配置失败');
      }
    } else {
      const menuData = removeObjectFalsyKey({
        cover_pic: isUploadImg ? coverPicUrl : null,
        ...requestData,
      }) as UpdateMenuItemData;
      const payload = objectToFormData({
        ...menuData,
        introduction: requestData.introduction,
      });
      let result;
      if (productId) {
        result = await updateProductMenu(productId, menuId, payload);
      } else {
        result = await updateMenu(menuId, payload);
      }
      if (result) {
        message.success('修改配置成功');
      } else {
        message.error('修改配置失败');
      }
    }
    onSaveSuccess();
    setLoading(false);
  };

  if (!menuId && !menuAction) {
    return (
      <Alert
        message="请点击选择左侧菜单"
        description="配置选中的菜单信息，点击保存完成修改"
        type="info"
      />
    );
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  );

  const handleCustomRequest = async (option: any) => {
    const file = option.file as File;
    const isLt4M = file.size / 1024 < 4096;
    if (!isLt4M) {
      message.error('图片必须小于4M');
      return;
    }
    const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg';
    if (!isJpgOrPng) {
      message.error('图片格式只支持JPG和PNG');
      return;
    }
    const cosData = await getCos();
    const { credentials, bucket, region } = cosData;
    const cos = new COS({
      getAuthorization(options, callback) {
        callback({
          TmpSecretId: credentials.tmpSecretId,
          TmpSecretKey: credentials.tmpSecretKey,
          SecurityToken: credentials.sessionToken,
          StartTime: cosData.startTime,
          ExpiredTime: cosData.expiredTime,
        });
      },
    });
    const fileSuffix = file.name.split('.')[file.name.split('.').length - 1];
    const CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const name = _.sampleSize(`${CHARSET}${new Date().getTime()}`, 40).join('');
    cos.uploadFile({
      Bucket: bucket,
      Region: region,
      Key: `other/coverPic/${name}.${fileSuffix}`,
      Body: file,
      onFileFinish(err, dataSource) {
        if (err) {
          option.onError(err);
        } else {
          option.onSuccess(dataSource);
          setCoverPicUrl(`${UPLOAD_URL}/other/coverPic/${name}.${fileSuffix}`);
          setIsUploadImg(true);
        }
      },
    });
    const imgUrl = await fileToBase64(file);
    if (menuType === URL_TYPE) {
      setDataImageUrl(imgUrl as string);
    } else {
      setImageUrl(imgUrl as string);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const handleMenuTypeChange = (e: any) => {
    const { value } = e.target;
    setMenuType(value);
  };

  return (
    <div className={styles.menuEditor} ref={editorRef}>
      <Title level={2}>菜单配置</Title>
      <Form
        layout="vertical"
        form={form}
        ref={formRef}
        initialValues={initialValues}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          name="name" label="菜单名称"
          rules={[{ required: true, max: 50 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="type" label="菜单类型"
          rules={[{ required: true }]}
        >
          <Radio.Group onChange={handleMenuTypeChange}>
            <Radio value={DIRECTORY_TYPE}>菜单</Radio>
            <Radio value={URL_TYPE}>链接</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => isUrlType(getFieldValue('type')) && (
            <Form.Item
              label="数据简介"
              name="data_introduction"
            >
              <TextArea placeholder="请输入数据内容介绍" autoSize={{ minRows: 3, maxRows: 3 }} />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => isUrlType(getFieldValue('type')) && (
            <Form.Item
              label="图表标题" rules={[{ required: true }]}
              name="title"
            >
              <TextArea placeholder="请输入数据链接内的图表标题，每张图占一行" autoSize={{ minRows: 3, maxRows: 3 }} />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => isUrlType(getFieldValue('type')) && (
            <>
              <Form.Item
                label="内嵌地址"
                rules={[{ required: true }]}
                name="urlType"
                className={styles.urlTypeStyle}
              >
                <Radio.Group>
                  <Radio value="idata">iData</Radio>
                  <Radio value="tableau">Tableau</Radio>
                  <Radio value="DA">DA</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="url"
                rules={[{ required: true, type: 'url', message: '请输入有效的url地址' }]}
              >
                <TextArea placeholder="请输入内嵌地址" />
              </Form.Item>

            </>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => isUrlType(getFieldValue('type'))
            && (
              <Form.Item
                label="权限控制" rules={[{ required: true }]}
                name="paid_user_only"
              >
                <Radio.Group>
                  <Radio value="0">免费</Radio>
                  <Radio value="1">订阅</Radio>
                </Radio.Group>
              </Form.Item>
            )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => isUrlType(getFieldValue('type')) && (
            <Form.Item
              label="上传封面" rules={[{ required: false }]}
              name="data_cover_pic"
            >
              <div className={styles.uploadContent}>
                <Upload
                  name="data_cover_pic"
                  listType="picture-card"
                  showUploadList={false}
                  customRequest={handleCustomRequest}
                  className={styles.coverUpload}
                >
                  {dataImageUrl ? (
                    <img
                      src={dataImageUrl}
                      alt="avatar"
                      style={{ width: '100%' }}
                    />
                  ) : uploadButton}
                </Upload>
                <span style={{ color: '#d9d9d9' }}>当前仅支持4M以内的JPG和PNG格式图片1</span>
              </div>
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => isUrlType(getFieldValue('type')) && (
            <Form.Item
              label="相关报告" rules={[{ required: false }]}
              name="associated_rid"
            >
              <TextArea placeholder="请输入报告ID，每篇报告占一行" autoSize={{ minRows: 3, maxRows: 3 }} />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => isUrlType(getFieldValue('type')) && (
            <Form.Item
              label="负责人" rules={[{ required: true, validator: () => validateRtx(form, 'charge_by', true) }]}
              name="charge_by"
            >
              <RtxSelect initialValues={chargeBy} />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => isUrlType(getFieldValue('type'))
            && (
              <Form.Item
                label="筛选下载" rules={[{ required: true }]}
                name="hideFunc"
              >
                <Radio.Group>
                  <Radio value={false}>关闭</Radio>
                  <Radio value={defaultHideFunc}>打开</Radio>
                </Radio.Group>
              </Form.Item>
            )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => isUrlType(getFieldValue('type'))
            && (
              <>
                <Form.Item
                  name="search_perm" label="搜索权限"
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    {PERMISSIONS.map((permission: MenuPermission) => (
                      <Radio key={permission.key} value={permission.key}>
                        {permission.label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
                {isSpecialPermType(getFieldValue('search_perm')) && userGroupData
                  && (
                    <>
                      <Form.Item
                        label="指定分组" name="search_group"
                        id="area" className={classnames(styles.selectFormItem, 'select-form-item')}
                        rules={[{
                          required: true,
                          validator: () => validateSpecialPerm(form, 'search_group', 'search_user'),
                        }]}
                      >
                        <Select
                          mode="tags"
                          getPopupContainer={
                            (): HTMLElement => document.querySelector('.select-form-item') as HTMLElement
                          }
                          onSelect={(value: string) => handleGroupSelect(form,
                            value, baseUserGroupFilter(userGroupData), 'search_group')}
                          onDeselect={(value) => handleGroupDelete(form, value, 'search_group')}
                        >
                          {userGroupData?.map((item) => (
                            item.name !== BASE_USER_GROUP_NAME && (
                              <Option
                                key={item.id ? item.id : 0} value={item.id ? item.id : 0}
                              >
                                {item.name}
                              </Option>
                            )
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label="指定用户" name="search_user"
                        rules={[{
                          required: true,
                          validator: () => validateSpecialPerm(form, 'search_group', 'search_user'),
                        }, {
                          required: true, validator: () => validateRtx(form, 'search_user'),
                        }]}
                      >
                        <RtxSelect initialValues={searchUsers} />
                      </Form.Item>
                    </>
                  )}
              </>
            )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (isUrlType(getFieldValue('type'))
            ? (
              <>
                <Form.Item
                  name="read_perm" label="菜单权限"
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    {PERMISSIONS.map((permission: MenuPermission) => (
                      <Radio key={permission.key} value={permission.key}>
                        {permission.label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
                {isSpecialPermType(getFieldValue('read_perm')) && userGroupData
                  && (
                    <>
                      <Form.Item
                        label="指定分组" name="read_group"
                        id="area" className={classnames(styles.selectFormItem, 'select-form-item')}
                        rules={[{
                          required: true,
                          validator: () => validateSpecialPerm(form, 'read_group', 'read_user'),
                        }]}
                      >
                        <Select
                          mode="tags"
                          getPopupContainer={
                            (): HTMLElement => document.querySelector('.select-form-item') as HTMLElement
                          }
                          onSelect={(value: string) => handleGroupSelect(form,
                            value, baseUserGroupFilter(userGroupData), 'read_group')}
                          onDeselect={(value) => handleGroupDelete(form, value, 'read_group')}
                        >
                          {userGroupData?.map((item) => (
                            item.name !== BASE_USER_GROUP_NAME && (
                              <Option
                                key={item.id ? item.id : 0} value={item.id ? item.id : 0}
                              >
                                {item.name}
                              </Option>
                            )
                          ))}

                        </Select>
                      </Form.Item>

                      <Form.Item
                        label="指定用户" name="read_user"
                        rules={[{
                          required: true,
                          validator: () => validateSpecialPerm(form, 'read_group', 'read_user'),
                        }, {
                          required: true, validator: () => validateRtx(form, 'read_user'),
                        }]}
                      >
                        <RtxSelect initialValues={readUsers} />
                      </Form.Item>
                    </>
                  )}
              </>
            )
            : (
              <>
                <Form.Item
                  label="菜单简介"
                  name="introduction"
                >
                  <TextArea placeholder="请输入数据内容介绍" autoSize={{ minRows: 3, maxRows: 3 }} />
                </Form.Item>
                <Form.Item
                  label="上传封面" rules={[{ required: false }]}
                  name="cover_pic"
                >
                  <div className={styles.uploadContent}>
                    <Upload
                      name="cover_pic"
                      listType="picture-card"
                      showUploadList={false}
                      customRequest={handleCustomRequest}
                      className={styles.coverUpload}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{ width: '100%' }}
                        />
                      ) : uploadButton}
                    </Upload>
                    <span style={{ color: '#d9d9d9' }}>当前仅支持4M以内的JPG和PNG格式图片2</span>
                  </div>
                </Form.Item>
              </>
            ))}
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="button" className="mur-form-button"
            onClick={resetMenu} size="large"
          >
            取消
          </Button>
          <Button
            type="primary" htmlType="submit"
            loading={loading} className="mur-form-button"
            size="large"
          >
            保存
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Editor;
