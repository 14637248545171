/* eslint-disable camelcase */
import request from '@/utils/request';
import { UserInfoData } from './response.type';
import { SuperiorData, MenuData } from './common';

type DataType = {
  path?: string,
  type?: string,
  url?: string,
  charge_by?: string[],
  hideFunc: boolean,
};

interface BaseMenuItemData {
  id?: number,
  scope?: string,
  name?: string,
  title?: string,
  introduction?: string,
  type?: number,
  search_perm?: number,
  read_perm?: number,
  icon?: string,
  parent_id?: number,
  data?: DataType,
  cover_pic?: string,
  associated_rid?: string,
}

export interface UpdateMenuItemData {
  rtx?: string[],
  group_ids?: number[],
}

interface UserGroup {
  created_at: string,
  group_name: string,
  id: number,
  sort: number,
  type: string,
  update_at: string,
}

export interface MenuItemData extends BaseMenuItemData {
  search_group?: UserGroup[],
  search_user?: UserInfoData[],
  read_group?: UserGroup[],
  read_user?: UserInfoData[],
  paid_user_only?: number,
}

export interface MenuTreeData {
  id: number,
  scope?: string,
  name: string,
  en_name: string,
  title?: string,
  introduction?: string,
  type: number,
  search_perm: number,
  read_perm: number,
  icon?: string,
  data: DataType,
  cover_pic?: string,
  associated_rid?: string,
  children: MenuTreeData[],
  parent_id: number | null,
  paid_user_only?: number | null,
}

export interface UpdateMenuPayload {
  insertType: 'before' | 'after' | 'nested',
  targetMenuId: string,
  neighborOrParentId: string,
}

export interface MenuUserGroupItem {
  created_at: string,
  id: number,
  group_name: string,
  updated_at: string,
}

export interface MenuUserGroupData {
  data: MenuUserGroupItem[],
  total: number,
}

interface MenuParams {
  menu_id?: string,
  menu_name?: string
}

export interface RecommendItem {
  name: string,
  description: string,
  icon: string,
}

export interface TabItem {
  value: string,
  recommend_games?: RecommendItem[],
}

interface TabOptionList {
  category: string,
  tabOptionList: TabItem[]
}

type MenuId = number;

export async function getMenu(): Promise<MenuTreeData> {
  const res = await request.get('/api/adminapi/data/basic/menu/tree');

  return res.data;
}

export async function getTableauOption(typeName: string): Promise<TabOptionList> {
  const res = await request.get('/api/webapi/data/menu/tableau/option', { params: { type: typeName } });

  return res.data;
}

// 获取树形菜单（已保密不显示）
export async function getBaseMenu(menuId = '', menuName = ''): Promise<MenuTreeData> {
  const params: MenuParams = {};
  if (menuId) params.menu_id = menuId;
  if (menuName) params.menu_name = menuName;
  const res = await request.get('/api/webapi/data/basic/menu/tree_menu', { params });

  return res.data;
}

export async function getTableauData(): Promise<MenuData[]> {
  const res = await request.get('/api/webapi/data/menu/tableau/getData');

  return res.data as MenuData[];
}

export async function getMenuData(menuId: MenuId): Promise<MenuData> {
  const res = await request.get(`/api/webapi/data/menu/data/${menuId}`);

  return res.data;
}

export interface AssociatedWriteByItem {
  headimg: string,
  id: number,
  user_id: string,
  user_name: string,
}

export interface AssociatedItem {
  write_by: AssociatedWriteByItem[],
  openid: string,
  cover_pic: string,
  created_at: string,
  media_id: string | number,
  id: number,
  title: string,
  cover_pic_type: number,
  preview_pic: string[],
}

export interface AssociatedData {
  data: AssociatedItem[],
}

export async function getAssociatedSearch(keyword: string, ext_field?: string | string[]): Promise<AssociatedData> {
  const res = await request.post('/api/webapi/report/associatedSearch', { keyword, ext_field });
  return res;
}

export interface ProductionStdInfoGame {
  name: string,
  icon: string,
  show: string,
  sample: string,
  chinese_name: string,
  regions?: string[],
  aa_region?: string[],
  st_region?: string[],
  newest_regions?: string[],
  max_stat_date?: string,
  min_stat_date?: string,
  aa_min_stat_date?: string,
  region_icon?: string,
  developer?: string,
}

export interface ProductionStdInfoData {
  region: string[],
  st_region: string[],
  aa_region: string[],
  games: {
    category: string,
    game: ProductionStdInfoGame[],
  }[],
}

export async function getProductionStdInfo(platform: string): Promise<ProductionStdInfoData> {
  const res = await request.get(`/api/webapi/data/menu/tableau/productionStdInfo?platform=${platform}`);
  return res.data;
}

export async function getMenuItem(menuId: MenuId): Promise<MenuItemData> {
  const res = await request.get(`/api/adminapi/data/menu/${menuId}`);

  return res.data;
}

export async function updateMenuOrder(payload: UpdateMenuPayload) {
  const { insertType, targetMenuId, neighborOrParentId } = payload;
  const res = await request.put(`/api/adminapi/data/menu/order/${insertType}/${targetMenuId}/${neighborOrParentId}`);

  return res;
}

export async function getMenuUserGroup(menuId: MenuId, page: number, pageSize: number): Promise<MenuUserGroupData> {
  const res = await request.get(`/api/auth/${menuId}/group`, { params: { page, page_size: pageSize } });

  return res.data;
}

export async function deleteMenuUserGroup(menuId: MenuId, group_ids: (number | string)[]): Promise<Boolean> {
  await request.delete(`/api/auth/${menuId}/group`, { data: { group_ids } });

  return true;
}

export async function addMenuUserGroup(menuId: MenuId, group_ids: string[]): Promise<Boolean> {
  await request.post(`/api/auth/${menuId}/group`, { group_ids });

  return true;
}

export async function updateMenu(menuId: MenuId, payload: FormData): Promise<Boolean> {
  const res = await request.post(`/api/adminapi/data/menu/${menuId}`, payload);
  return res.data;
}

export async function addMenu(payload: FormData) {
  const res = await request.post('/api/adminapi/data/menu', payload);

  return res.data;
}

export async function deleteMenu(menuId: MenuId): Promise<Boolean> {
  await request.delete(`/api/adminapi/data/menu/${menuId}`);

  return true;
}

export interface ApplicationMenuAuthData {
  id: number,
  reason: string,
}

export interface ApplicationMenuAuthRes {
  error_code: number,
  details: string,
}

export async function applicationMenuAuth(payload: ApplicationMenuAuthData): Promise<Boolean | ApplicationMenuAuthRes> {
  const res = await request.post('/api/webapi/data/menu/readAuth', payload);

  return res.data;
}

export async function getApprover(id: number): Promise<SuperiorData> {
  const res = await request.get('/api/webapi/data/menu/readAuth/description', { params: { id } });

  return res.data;
}

interface HomeMenuTreeData extends Omit<MenuTreeData, 'data' | 'children'> {
  data: {
    path: string
  },
  children: HomeMenuTreeData[],
}

export async function getHomeMenu(): Promise<HomeMenuTreeData> {
  const res = await request.get('/api/webapi/homeMenu/menus');

  return res.data;
}
