import React, { FC, ReactNode, Suspense } from 'react';
import { Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import {
  SuspenseFallback, ErrorFallback, HoverButton, ScrollToTop,
} from '@/components';
import configs from '@/configs';
import { ConfigProvider, Layout } from 'antd';
import {
  UserInfoContext, BeaconContext, MenuUrlContext, SingleProductInfoContext, HomeMenuContext, OutDisplayContext,
  ReportTitle, RemindCache, UserBehaviorContext,
} from '@/context';
import * as Sentry from '@sentry/browser';
import i18n, { CURRENT_LANGUAGE } from '@/i18n';
import history from '@/utils/history';

const globalAntdConfigs = configs('antd');

interface BaseProviderProps {
  children: ReactNode,
}

const BaseProvider: FC<BaseProviderProps> = ({ children }: BaseProviderProps) => (
  <I18nextProvider i18n={i18n}>
    <Router history={history}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(e) => {
          Sentry.captureException(e);
        }}
      >
        <ConfigProvider {...globalAntdConfigs}>
          <BeaconContext.Provider>
            <UserInfoContext.Provider>
              <UserBehaviorContext.Provider>
                <HomeMenuContext.Provider>
                  <SingleProductInfoContext.Provider>
                    <MenuUrlContext.Provider>
                      <OutDisplayContext.Provider>
                        <ReportTitle.Provider>
                          <RemindCache.Provider>
                            <Layout className={`lang-${CURRENT_LANGUAGE}`}>
                              <Suspense fallback={<SuspenseFallback />}>
                                <ScrollToTop />
                                {children}
                              </Suspense>
                            </Layout>
                          </RemindCache.Provider>
                        </ReportTitle.Provider>
                      </OutDisplayContext.Provider>
                    </MenuUrlContext.Provider>
                  </SingleProductInfoContext.Provider>
                </HomeMenuContext.Provider>
              </UserBehaviorContext.Provider>
            </UserInfoContext.Provider>
          </BeaconContext.Provider>
        </ConfigProvider>
        <HoverButton.BackToTop />
      </ErrorBoundary>
    </Router>
  </I18nextProvider>
);

export default BaseProvider;
