import React, { FC } from 'react';
import classnames from 'classnames';
import styles from './index.module.less';

interface AdminTitleProps {
  title: string,
  isBold?: boolean
}

const AdminTitle: FC<AdminTitleProps> = ({ title, isBold = false }: AdminTitleProps) => (
  <div className={styles.adminTitleContainer}>
    <h3 className={classnames('mur-title', { [styles.adminTitleBold]: isBold })}>{title}</h3>
  </div>
);

export default AdminTitle;
