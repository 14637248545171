import React, { FC, MouseEvent } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styles from '../index.module.less';

interface AppointmentContentProps {
  isPubliced: boolean,
  isSpecialed: boolean,
  onPublicClass: (event: MouseEvent) => void,
  onSpecialClass: (event: MouseEvent) => void,
  onClose: () => void,
}

const AppointmentContent: FC<AppointmentContentProps> = ({
  isPubliced = false, isSpecialed = false,
  onClose, onPublicClass, onSpecialClass,
}: AppointmentContentProps) => {
  const { t } = useTranslation();

  const handlePublicClick = (event: MouseEvent) => {
    onPublicClass(event);
  };

  const handleSpecialClick = (event: MouseEvent) => {
    onSpecialClass(event);
  };

  return (
    <div className={styles.shareContainer}>
      <div className={styles.shareContentTitle}>
        {t('SeminarType')}
        <CloseOutlined className={styles.shareContentCloseButton} onClick={onClose} />
      </div>
      <div className={styles.AppointmentContentBody}>
        <div>
          <Button
            className={classnames(styles.ClassButton,
              { [styles.hasClass]: isPubliced })} type="default"
            onClick={handlePublicClick}
          >
            {t('PublicSeminar')}

          </Button>
          <span className={styles.AppointmentContentDescribe}>{t('PublicDescribe')}</span>
        </div>
        <div>
          <Button
            className={classnames(styles.ClassButton,
              { [styles.hasClass]: isSpecialed })} type="default"
            onClick={handleSpecialClick}
          >
            {t('PrivateSeminar')}

          </Button>
          <span className={styles.AppointmentContentDescribe}>{t('PrivateDescribe')}</span>
        </div>
      </div>
    </div>
  );
};

export default AppointmentContent;
