import React, { FC, useState, useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DataNode } from 'rc-tree/lib/interface';
import { Tree } from 'antd';
import { MenuTreeData } from '@/services/menu';
import { AdminBaseMenuContext } from '@/context';
import TreeTitle from './tree-title';
import {
  validateDropEffective, dropToNeighbor, isUrlType, isDirectoryType, dropToChildren,
} from './base-util';
import styles from './index.module.less';

interface MenuDataNode extends DataNode {
  index: number,
  data: MenuTreeData,
}

interface MenuTreeProps {
  menuList: MenuTreeData[],
  refresh: Function,
  treeHeight: number,
  productId?: number,
}

const onTreeDrop = async (info: any, refresh: Function, productId?: number) => {
  if (validateDropEffective(info)) {
    const { node } = info;

    if (isUrlType(node.data.type)) {
      await dropToNeighbor(info, productId);
      refresh();
    }

    if (isDirectoryType(node.data.type)) {
      await dropToChildren(info, productId);
      refresh();
    }
  }
};

const MenuTree: FC<MenuTreeProps> = ({
  menuList, refresh, treeHeight, productId,
}: MenuTreeProps) => {
  const { selectMenu, menuId } = AdminBaseMenuContext.useContainer();
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

  // 当menuId变化，重新设置选中状态
  useEffect(() => {
    setSelectedKeys([menuId]);
  }, [menuId]);

  const onSelect = (keys: React.Key[], info: { node: { key: React.Key; }; }) => {
    setSelectedKeys(keys);
    selectMenu(+info.node.key);
  };

  const onAddMenu = (menuTree: MenuTreeData) => {
    setSelectedKeys([menuTree.id]);
  };

  const transferTreeData = (list: MenuTreeData[]) => list.map((item, index) => {
    const treeNodeItem: MenuDataNode = {
      key: item.id,
      title: <TreeTitle
        menuTree={item} refresh={refresh}
        onAddMenu={onAddMenu} productId={productId}
      />,
      index,
      data: item,
    };

    if (item.children?.length) {
      treeNodeItem.children = transferTreeData(item.children);
    }

    return treeNodeItem;
  });

  const treeData = transferTreeData(menuList);

  return (
    <Tree
      className={styles.menuTree}
      selectedKeys={selectedKeys}
      showLine={{ showLeafIcon: false }}
      defaultExpandAll
      draggable
      blockNode
      height={treeHeight}
      itemHeight={28}
      treeData={treeData}
      onSelect={onSelect}
      onDrop={(info) => onTreeDrop(info, refresh, productId)}
    />
  );
};

export default MenuTree;
