import { createContainer } from 'unstated-next';
import { getSuperior } from '@/services/common';
import { useFetch } from '@/hooks';

function useSuperiorInfo() {
  const { data } = useFetch(getSuperior);

  return {
    info: data,
  };
}

const SuperiorInfo = createContainer(useSuperiorInfo);

export default SuperiorInfo;
