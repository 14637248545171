import React, { FC, useState } from 'react';
import {
  Form, Select, Spin, Empty,
} from 'antd';
import _ from 'lodash';
import { useFetch } from '@/hooks';
import { StaffData } from '@/services/common';
import styles from './index.module.less';

const { Option } = Select;

interface DepartmentSelectProps {
  name: string,
  label: string,
  getZoneServiceFn: (word: string, size: number) => Promise<StaffData[]>,
  hasRules?: any,
  hasPlaceholder?: boolean,
  form?: any,
  style?: any,
}

const renderDepartmentOption = (optionList: StaffData[]) => optionList.map((item) => (
  <Option
    key={item.name} value={item.name}
  >
    {item.name}
  </Option>
));

const DepartmentSelect: FC<DepartmentSelectProps> = ({
  name, label, getZoneServiceFn, form,
  hasRules, hasPlaceholder = true, style,
}: DepartmentSelectProps) => {
  const [departmentWord, setDepartmentWord] = useState('');
  const size = 999;
  const { data, loading } = useFetch(
    () => getZoneServiceFn(departmentWord, size),
    { depends: [departmentWord], interrupt: departmentWord.trim().length === 0 },
  );

  return (
    <Form.Item
      label={label} name={name}
      rules={hasRules || [{ required: true, message: `请选择${label}` }]}
    >
      <Select
        mode="multiple" placeholder={hasPlaceholder ? `请选择${label}` : ''}
        onSearch={_.debounce(setDepartmentWord, 500)}
        filterOption={false}
        notFoundContent={loading ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        dropdownRender={(optin) => (
          <>
            {form && !!data?.length && (
              <div
                className={styles.checkAll}
                onClick={() => {
                  if (form.getFieldValue(name)?.length === data?.length) {
                    form.setFieldsValue({ [name]: [] });
                  } else {
                    const res = data?.map((item) => item.name) || [];
                    form.setFieldsValue({ [name]: res });
                  }
                }}
              >
                {form.getFieldValue(name)?.length === data?.length ? '取消全选' : '全选'}
              </div>
            )}
            {optin}
          </>
        )}
        className={styles.selectItem}
        style={{ ...style }}
      >
        {data && renderDepartmentOption(data)}
      </Select>
    </Form.Item>
  );
};

export default DepartmentSelect;
