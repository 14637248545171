import React, {
  FC, useState, useEffect, useRef,
} from 'react';
import {
  message, Form, Upload,
  Modal, Button,
} from 'antd';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { base64ToFile } from '@/utils/helper/base';
import { getBase64 } from '@/utils/helper/form';
import { PlusOutlined } from '@ant-design/icons';
import { Rule, FormInstance } from 'antd/lib/form';
import classnames from 'classnames';
import styles from './index.module.less';

interface CoverUploadProps {
  initialValues?: string,
  reset: boolean,
  name: string,
  maxCoverSize?: number,
  className?: string,
  form?: FormInstance,
  formClassName?: string,
  label?: string,
  rules?: Rule[],
  tips?: string,
  initialBanner?: string,
  needBanner?: boolean,
  wrapperCol?: {
    sm: {
      span?: number,
      offset?: number,
    }
  },
  style?: object,
}

const CoverUpload: FC<CoverUploadProps> = ({
  initialValues, reset, label, name, maxCoverSize, className, rules,
  formClassName, wrapperCol, tips, style, initialBanner, needBanner, form,
}: CoverUploadProps) => {
  const cropperRef = useRef<ReactCropperElement>(null);
  const [coverPic, setCoverPic] = useState<string>();
  const [bannerPic, setBannerPic] = useState<string>();
  const [cropperVisible, setCropperVisible] = useState(false);
  const [croppedImage, setCroppedImage] = useState<string>('');

  useEffect(() => {
    if (initialValues) {
      setCoverPic(initialValues);
    }
  }, [initialValues]);

  useEffect(() => {
    if (needBanner) {
      setBannerPic(initialBanner || initialValues);
    }
  }, [initialBanner, needBanner, initialValues]);

  useEffect(() => {
    if (reset) {
      setCoverPic(undefined);
    }
  }, [reset]);

  const handleCropperCancel = () => {
    setCroppedImage('');
    setCropperVisible(false);
  };

  const handleCropperFinish = () => {
    setBannerPic(croppedImage);
    if (form) {
      form.setFieldsValue({ banner_pic: base64ToFile(croppedImage, 'banner_pic') });
    }
    setCropperVisible(false);
  };

  const props = {
    accept: 'image/jpeg,image/png',
    name: 'img',
    beforeUpload(file: any) {
      if (!maxCoverSize) return false;
      const isLtMaxSize = file.size / 1024 / 1024 < maxCoverSize;
      if (!isLtMaxSize) {
        message.error(`上传封面不能超过 ${maxCoverSize}MB!`);
        return false;
      }
      return false;
    },
    onChange(info: any) {
      let isLtMaxSize = true;
      if (maxCoverSize) {
        isLtMaxSize = info.file.size / 1024 / 1024 < (maxCoverSize || 0);
      }
      if (info.file && isLtMaxSize) {
        if (form) {
          form.setFieldsValue({ [name]: info.file });
        }
        getBase64(info.file, (imageUrl: string) => {
          setCoverPic(imageUrl);
          if (needBanner) {
            setBannerPic(imageUrl);
            setCropperVisible(true);
          }
        });
      }
    },
  };

  return (
    <Form.Item
      className={classnames(formClassName, styles.formWrapper)}
      label={label} wrapperCol={wrapperCol}
    >
      <Form.Item
        name={name}
        rules={rules}
        className={styles.formInlineItem}
        normalize={(value) => value.file}
        valuePropName="picture-card"
        style={{ ...style }}
      >
        <Upload
          {...props}
          listType="picture-card"
          showUploadList={false}
          className={classnames(styles.coverPicContainer, className)}
        >
          {coverPic ? (
            <img
              src={coverPic} alt="avatar"
              className={className}
            />
          ) : (
            <div className={classnames(styles.coverPicContainer, className)}>
              <PlusOutlined />
              <div>上传照片</div>
            </div>
          )}
        </Upload>
        <div className={styles.formUploadTips}>
          {tips}
        </div>
      </Form.Item>
      {needBanner && (
        <Form.Item
          name="banner_pic"
          className={styles.formInlineItem}
          normalize={(value) => value.file}
          valuePropName="picture-card"
          style={{ ...style, marginLeft: 20 }}
        >
          <Upload
            disabled
            listType="picture-card"
            showUploadList={false}
            className={classnames(
              styles.coverPicContainer,
              styles.bannerPicContainer,
              styles.viewBanner,
              className,
            )}
          >
            {bannerPic ? (
              <img
                src={bannerPic} alt="avatar"
                className={className}
              />
            ) : null}
          </Upload>
          {bannerPic && <div className={styles.formUploadTips}>提示：此图将用作首页banner推荐</div>}
        </Form.Item>
      )}
      <Modal
        width={842}
        title={<div className={styles.bannerTitle}>编辑首页banner图片</div>}
        visible={cropperVisible}
        onCancel={handleCropperCancel}
        footer={(
          <div className={styles.bannerFooter}>
            <Button
              key="submit"
              type="primary"
              onClick={handleCropperFinish}
              className={styles.bannerButton}
            >
              确认
            </Button>
            <Button
              key="cancel"
              onClick={handleCropperCancel}
              className={styles.bannerButton}
            >
              取消
            </Button>
          </div>
        )}
      >
        <Cropper
          ref={cropperRef}
          style={{ height: 315, width: 802 }}
          initialAspectRatio={802 / 315}
          guides
          src={bannerPic}
          viewMode={1}
          autoCropArea={1}
          dragMode="move"
          scalable={false}
          crop={() => {
            const cropper = cropperRef.current?.cropper;
            if (cropper) {
              setCroppedImage(cropper.getCroppedCanvas().toDataURL());
            }
          }}
        />
      </Modal>
    </Form.Item>
  );
};

export default CoverUpload;
