/* eslint-disable camelcase */
import React, { FC, CSSProperties, MouseEvent } from 'react';
import {
  Row, Col, Avatar,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { UserInfoContext } from '@/context';
import configs from '@/configs';
import { removeUserEmSpan } from '@/utils/helper/base';
import styles from './index.module.less';

const AVATAR_URL = configs('app.avatars');
const otherRtx = configs('report.otherRtx');

interface UserInfo {
  headimg?: string,
  user_id: string,
  user_name?: string,
}

interface UserInfoProps {
  userInfo: UserInfo,
  time: string,
  userTextColor?: string,
  timeTextColor?: string,
  avatarStyle?: CSSProperties,
  size?: number,
  className?: string,
  onClick?: (event: MouseEvent) => void,
  hasVideo?: boolean,
  videoIcon?: string,
}

const UserInfoContainer: FC<UserInfoProps> = ({
  userInfo, time, userTextColor, timeTextColor, avatarStyle, size = 28,
  className, onClick, hasVideo = false, videoIcon,
}: UserInfoProps) => {
  const { isStaffUser } = UserInfoContext.useContainer();
  return (
    <Row
      align="middle" className={className}
      onClick={(event) => onClick!(event)}
    >
      {!isStaffUser && (
      <Col>
        {userInfo?.headimg ? (
          <Avatar
            src={
            `${AVATAR_URL}${userInfo.user_name === otherRtx ? otherRtx
              : removeUserEmSpan(userInfo.user_id)}/profile.jpg`
            } alt="avatar"
            size={size} style={{ ...avatarStyle }}
          />
        ) : (
          <Avatar
            icon={<UserOutlined />} alt="avatar"
            size={size} style={{ ...avatarStyle }}
          />
        )}
      </Col>
      )}
      <Col className={!isStaffUser ? styles.userInfo : styles.userInfoNet}>
        {!isStaffUser && (
        <p
          // eslint-disable-next-line react/no-danger
          style={{ color: userTextColor }} dangerouslySetInnerHTML={{
            __html: `${userInfo?.user_id}${userInfo?.user_name ? `(${userInfo?.user_name})` : ''}`,
          }}
        />
        )}
        <p style={{ color: timeTextColor }}>{time.split(' ')[0]}</p>
      </Col>
      {hasVideo && <Col className={styles.videoIcon}><span>{videoIcon}</span></Col>}

    </Row>
  );
};

export default UserInfoContainer;
