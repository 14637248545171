import React, { FC, ReactNode, CSSProperties } from 'react';
import { Layout } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { MainFooter } from '@/components';
import classnames from 'classnames';

const { Sider, Content } = Layout;

interface MainContentProps {
  menu: ReactNode,
  menuWidth?: number,
  children?: ReactNode;
  collapsible?: boolean
  backgroundColor?: string,
  isCollapsed?: boolean,
  changeCollapsed?: Function,
  footerVisible?: boolean,
  footer?: ReactNode,
  className?: string,
}

const iconStyle = { fontSize: '22px', color: '#999999' };

const MainContent: FC<MainContentProps> = ({
  menu,
  children,
  menuWidth = 240,
  collapsible = false,
  isCollapsed = false,
  changeCollapsed,
  backgroundColor,
  footerVisible = true,
  footer,
  className,
}: MainContentProps) => {
  const collapsedMenuWidth = 80;
  const rightLayoutStyle: CSSProperties = {
    marginLeft: isCollapsed ? collapsedMenuWidth : menuWidth,
  };
  const onCollapse = (collapsed: boolean) => {
    if (changeCollapsed) {
      changeCollapsed(collapsed);
    }
  };

  if (backgroundColor) {
    rightLayoutStyle.backgroundColor = backgroundColor;
  }

  return (
    <Layout className={classnames('mur-main-layout', className)}>
      <Sider
        className="mur-layout-sider"
        width={menuWidth}
        theme="light"
        collapsible={collapsible}
        collapsed={isCollapsed}
        onCollapse={onCollapse}
        trigger={isCollapsed
          ? <MenuUnfoldOutlined style={iconStyle} />
          : <MenuFoldOutlined style={iconStyle} />}
        style={{ bottom: collapsible ? '48px' : 0 }}
      >
        {menu}
      </Sider>
      <Layout
        className="mur-right-layout"
        style={rightLayoutStyle}
      >
        <Content className="mur-layout-content">
          {children}
        </Content>
        {footerVisible && <MainFooter />}
        {!footerVisible && footer}
      </Layout>
    </Layout>
  );
};

export default MainContent;
