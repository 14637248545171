import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { ResearchItem } from '@/services/home';

function usePreResearchContext() {
  const [preResearchData, setPreResearchData] = useState<ResearchItem[]>([]);
  const [slicePreList, setSlicePreList] = useState<ResearchItem[][]>();

  const sliceList = (list: ResearchItem[], sliceLength: number) => {
    let index = 0;
    const arr: ResearchItem[][] = [];
    while (index < list.length) {
      arr.push(list.slice(index, index += sliceLength));
    }
    if (arr.length > 1 && arr[arr.length - 1].length < sliceLength) {
      arr[arr.length - 1].push({ id: 0, name: '' });
    }
    return arr;
  };

  return {
    preResearchData,
    slicePreList,
    setPreResearchData,
    setSlicePreList,
    sliceList,
  };
}

const preResearchContext = createContainer(usePreResearchContext);

export default preResearchContext;
