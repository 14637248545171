const HOME_PATH = '/';
const REPORT_HOME_PATH = '/report-library/home';
const REPORT_SEARCH_PATH = '/report-library/search';
const REPORT_DETAIL_PATH = '/report-library/detail';
const REPORT_AUTHOR_PATH = '/report-library/author';
const REPORT_TAG_PATH = '/report-library/tag';
const IDATA_HOME_PATH = '/base';
const PROFILE_PATH = '/personal-center';
const SINGLE_PRODUCT_DASHBOARD = '/single-product/dashboard';
const SINGLE_PRODUCT_REPORT_HOME_PATH = '/single-product/report-index';
const SINGLE_PRODUCT_REPORT_DETAIL_PATH = '/single-product/report-detail';
const SINGLE_PRODUCT_DATA_PATH = '/single-product/data';
const BASE_DETAIL_PATH = '/base/detail';
const CULTURAL_SIGNALS_HOME = '/cultural-signals/home';
const CULTURAL_DETAIL_PATH = '/cultural-signals/detail';
const CULTURAL_SIGNALS_TAG = '/cultural-signals/tag';
const GLOBAL_RESEARCH = '/global-research';
const PRE_RESEARCH = '/research';
const COMPETITOR = '/base/competitor/home';

export default {
  [HOME_PATH]: 'stay_newfirst_free_page',
  [REPORT_HOME_PATH]: 'stay_time_report_home_page',
  [REPORT_SEARCH_PATH]: 'stay_time_report_home_page',
  [REPORT_DETAIL_PATH]: 'stay_time_report_detail_page',
  [REPORT_AUTHOR_PATH]: 'stay_time_author_page',
  [REPORT_TAG_PATH]: 'stay_time_taglist_page',
  [IDATA_HOME_PATH]: 'stay_mark_user_data_page',
  [PROFILE_PATH]: 'stay_profile_page',
  [SINGLE_PRODUCT_DASHBOARD]: 'stay_game_list_page',
  [SINGLE_PRODUCT_REPORT_HOME_PATH]: 'stay_time_game_report_home_page',
  [SINGLE_PRODUCT_REPORT_DETAIL_PATH]: 'stay_time_game_report_detail_page',
  [SINGLE_PRODUCT_DATA_PATH]: 'stay_time_game_data_page',
  [BASE_DETAIL_PATH]: 'stay_market_user_data_details_page',
  [CULTURAL_SIGNALS_HOME]: 'stay_cultural_signals_home_page',
  [CULTURAL_DETAIL_PATH]: 'stay_cultural_signals_detail_page',
  [CULTURAL_SIGNALS_TAG]: 'stay_cultural_signals_tag_page',
  [GLOBAL_RESEARCH]: 'stay_global_research_home_page',
  [PRE_RESEARCH]: 'stay_research_community_home_page',
  [COMPETITOR]: 'stay_time_game_competitor_page',
};
