import React, {
  FC, useState, useEffect, useRef,
} from 'react';
import { Select, Spin, message } from 'antd';
import _ from 'lodash';
import { getRtxData } from '@/services/users';
import { useFetch } from '@/hooks';

const { Option } = Select;

interface RtxUser {
  rtx: string,
  pinyin: string,
  // eslint-disable-next-line camelcase
  chn_name: string,
}

interface RtxSelectProps {
  size?: number, // 一次获取多少数据，建议少点
  style?: object, // style={{width: 200}}
  onChange?: (users: string[]) => void;
  initialValues?: string[],
  placeholder?: string,
  renderChildrenFn?: () => void,
  disabled?: boolean,
  id?: string,
  needCopy?: boolean,
  compatible?: boolean,
  requiredValue?: string,
}

interface RtxData extends RtxUser {
  value?: string,
}

const defaultStyle = {
  width: '100%',
};

const renderOptions = (optionList: RtxData[]) => optionList.map((item) => (
  <Option key={item.rtx} value={item.rtx}>
    {`${item.rtx}${item.chn_name ? `(${item.chn_name})` : ''}`}
  </Option>
));

const filterSelectValue = (rtx: string) => rtx || '';

const RtxSelect: FC<RtxSelectProps> = ({
  size = 10, style, onChange, initialValues = [], disabled = false,
  placeholder = '请输入中文名称或者rtx英文', renderChildrenFn, id,
  needCopy, compatible = false, requiredValue,
}: RtxSelectProps) => {
  const [word, setWord] = useState('');
  const [value, setValue] = useState<string[]>([]);
  const refValue = useRef<string[]>([]);
  const { data, loading } = useFetch<RtxData[]>(() => getRtxData(word, size), {
    depends: [word, size],
    interrupt: word.length === 0,
  });

  useEffect(() => {
    const res = requiredValue ? _.uniq([...initialValues, requiredValue]) : initialValues;
    if (res?.length) {
      setValue(res);
      if (!value?.length) {
        refValue.current = res;
        if (onChange) {
          onChange(res);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, requiredValue]);

  useEffect(() => {
    if (value.length && id && needCopy) {
      const parentNode = document.getElementById(id)?.parentNode?.parentNode?.parentNode;
      if ((parentNode?.lastChild as HTMLDivElement)?.className === 'copyButton') return;
      const copyButton = document.createElement('div');
      copyButton.className = 'copyButton';
      copyButton.innerText = '复制';
      copyButton.style.height = '24px';
      copyButton.style.lineHeight = '24px';
      copyButton.style.color = '#2b4096';
      copyButton.style.marginTop = '2px';
      copyButton.style.cursor = 'pointer';
      copyButton.onclick = () => {
        const input = document.createElement('input');
        document.body.appendChild(input);
        input.value = refValue.current.join(',');
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        message.success('复制成功');
      };
      if (parentNode) parentNode.appendChild(copyButton);
    } else if (!value.length && id && needCopy) {
      const parentNode = document.getElementById(id)?.parentNode?.parentNode?.parentNode;
      if ((parentNode?.lastChild as HTMLDivElement)?.className === 'copyButton') {
        const copyButton = parentNode?.lastChild;
        if (copyButton) copyButton.remove();
      }
    }
  }, [value, id, needCopy]);

  const handleChange = (usersName: string[]) => {
    let resUsersName = usersName;
    if (compatible) {
      resUsersName = usersName.map((item) => item.replace(/<[^>]+>/ig, '').replace(/\(.*?\)/g, '').trim());
    }
    setValue(resUsersName);
    if (needCopy) {
      refValue.current = resUsersName;
    }

    if (onChange) {
      // eslint-disable-next-line no-useless-escape
      const userIds = _.compact(resUsersName.map((item) => filterSelectValue(item).replace(/[\(（][^\)）]+[\)）]$/g, '')));

      onChange(userIds);
    }
  };
  const children = () => (renderChildrenFn ? renderChildrenFn() : null);

  return (
    <Select
      id={id}
      placeholder={placeholder}
      mode="tags"
      value={value}
      style={{ ...defaultStyle, ...style }}
      showArrow={false}
      filterOption={false}
      tokenSeparators={[';', ',']}
      onSearch={_.debounce((v) => {
        setWord(v.trim());
      }, 500)}
      onChange={handleChange}
      notFoundContent={loading ? <Spin size="small" /> : null}
      autoClearSearchValue
      disabled={disabled}
    >
      {requiredValue && (
        <Option
          key={requiredValue}
          value={requiredValue}
          disabled
        >
          {requiredValue}
        </Option>
      )}
      {children()}
      {data && renderOptions(data)}
    </Select>
  );
};

export default RtxSelect;
