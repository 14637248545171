import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'antd';
import { UserBehaviorContext } from '@/context';
import { CloseOutlined } from '@ant-design/icons';
import styles from './index.module.less';

interface Props {
  visible: boolean,
}

const str1 = '主要向您推荐以下几大板块：';
const str2 = '精选推荐：聚焦热门，最新热门领域合集一眼看全；';
const str3 = '研究互动：快人一步，早早参与互娱用研研究进程；';
const str4 = '我的关注：点点关注不迷路，快来将感兴趣的标签、作者“收入囊中”；';
const str5 = '欢迎体验，希望带给您最有价值的游戏市场洞察~';

const VisionModal: FC<Props> = ({ visible }: Props) => {
  const { setStep } = UserBehaviorContext.useContainer();

  return (
    <Modal
      visible={visible}
      centered
      title={null}
      footer={null}
      forceRender
      modalRender={(modal: ReactNode) => (
        <div className={styles.tipsModal}>
          {modal}
        </div>
      )}
      bodyStyle={{
        width: '540px',
        height: '388px',
      }}
      maskStyle={{ zIndex: 1000000001 }}
      zIndex={1000000002}
    >
      <div className={styles.title}>
        洞察主页现已全面更新！
      </div>
      <div className={styles.textContent}>
        <div className={styles.text}>
          {str1}
        </div>
        <div className={styles.text}>
          {str2}
        </div>
        <div className={styles.text}>
          {str3}
        </div>
        <div className={styles.text}>
          {str4}
        </div>
        <div className={styles.text}>
          {str5}
        </div>
      </div>
      <Button
        type="primary"
        onClick={() => {
          setStep(3);
        }}
        className={styles.button}
      >
        立即体验
      </Button>
      <CloseOutlined
        className={styles.closeIcon}
        onClick={() => {
          setStep(3);
        }}
      />
    </Modal>
  );
};

export default VisionModal;
