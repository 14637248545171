import React, { FC } from 'react';
import wxworkSvg from 'assets/images/wxwork.svg';
import styles from './index.module.less';

interface WxworkLinkProps {
  href: string,
  message: string,
}
const WxworkLink: FC<WxworkLinkProps> = ({ href, message }: WxworkLinkProps) => (
  <a href={href} className={styles.wxworkLink}>
    <img src={wxworkSvg} alt="wx" />
    {message}
  </a>
);

export default WxworkLink;
