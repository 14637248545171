import React, {
  FC, useState, useEffect,
} from 'react';
import {
  Form, Upload, message, Button, Typography,
} from 'antd';
import { UploadOutlined, ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons';

import styles from './index.module.less';

const { Text } = Typography;

interface UploadProps {
  action?: string,
  productId?: number,
  initialValues?: string,
  reset: boolean,
  tips: string,
  accept: string,
}

const FormUpload: FC<UploadProps> = ({
  initialValues, reset, tips, accept, action = '', productId,
}: UploadProps) => {
  const [pdfList, setPdfList] = useState<any[]>([]);
  const uploadMaxSize = 120;

  useEffect(() => {
    if (initialValues && !reset) {
      setPdfList([{
        name: initialValues, uid: '1', size: 0, type: '',
      }]);
    } else {
      setPdfList([]);
    }
  }, [initialValues, reset]);

  const props: any = {
    accept,
    name: 'file',
    beforeUpload(file: any) {
      const isLtMaxSize = file.size / 1024 / 1024 < uploadMaxSize;
      if (!isLtMaxSize) {
        message.error(`上传文件不能超过 ${uploadMaxSize}MB!`);
        return Promise.reject();
      }
      if (!action) return false;
      return true;
    },
    onChange(info: any) {
      const isLtMaxSize = info?.file?.size / 1024 / 1024 < uploadMaxSize;
      if (!isLtMaxSize) {
        return;
      }
      if (info.file.status === 'removed') {
        setPdfList([]);
      } else if (info.file.status === 'error') {
        setPdfList([{ ...info.file, error: { message: '上传错误' } }]);
      } else {
        setPdfList([info.file]);
      }
    },
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: <CloseOutlined className={styles.tipsIcon} />,
    },
    progress: {
      strokeColor: {
        '0%': '#495ea3',
        '100%': '#495ea3',
      },
      format: (percent: number) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  if (action) {
    props.action = `${action}`;
  }

  if (productId) {
    props.headers = {
      szid: String(productId),
    };
  }

  const validateFileUpload = () => {
    if (pdfList && pdfList.length > 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('请上传附件'));
  };

  return (
    <Form.Item
      label="上传附件"
      name="pdf_file"
      rules={[{
        required: true,
        validator: () => validateFileUpload(),
      }]}
      normalize={(value) => {
        if (!action) return value.file;
        const { response, status } = value.file;
        if (status === 'error') {
          return '';
        }
        if (response) {
          return response.data;
        }
        return value.file;
      }}
    >
      <Upload
        {...props} fileList={pdfList}
      >
        <Button>
          <UploadOutlined />
          {' '}
          上传文件
        </Button>
        <Text className={styles.tipsText}>
          <ExclamationCircleOutlined className={styles.tipsIcon} />
          {tips}
        </Text>
      </Upload>
    </Form.Item>

  );
};

export default FormUpload;
