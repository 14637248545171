/* eslint-disable complexity */
/* eslint-disable camelcase */
import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Modal, Radio, Button, Form, Input, message,
} from 'antd';
import { downloadReportSingle, getApproverSingle, handleAuthSingle } from '@/services/report-library';
import { useTranslation } from 'react-i18next';
import { CURRENT_LANGUAGE } from '@/i18n';
import { UserInfoContext } from '@/context';
import { useFetch } from '@/hooks';
import { AuthData } from '@/services/common';
import { SuperiorDisplay } from '@/components';
import history from '@/utils/history';
import styles from '../index.module.less';

interface DownloadModalProps {
  id: number,
  data: any,
  visible: boolean,
  changeVisible: Function,
  auth?: AuthData,
}

const DownloadModal: FC<DownloadModalProps> = ({
  id, data, visible, changeVisible, auth,
}: DownloadModalProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [form] = Form.useForm();
  const { info } = UserInfoContext.useContainer();
  const [value, setValue] = useState<string>('watermark');
  const [isAuth, setIsAuth] = useState<boolean>(!!auth?.download_auth_app);

  const { data: resData } = useFetch(() => getApproverSingle(data?.openid, queryParams.get('szid') as string, 2));

  const onOk = async () => {
    if (data?.file_path?.includes('.pdf') && value === 'watermark') {
      const res = await downloadReportSingle(id, queryParams.get('szid') as string);
      if (res) history.push('/personal-center');
      return;
    }

    const res = await handleAuthSingle(
      data?.openid,
      form.getFieldValue('reason')?.trim(),
      queryParams.get('szid') as string,
      2,
    );
    if (!res || Array.isArray(res)) {
      message.info('已申请，提交失败！如有其他问题请找管理员~');
      changeVisible(false, false);
    } else {
      changeVisible(false, true);
      setIsAuth(true);
      form.resetFields();
    }
    setValue('watermark');
  };

  return (
    <Modal
      visible={visible}
      title={t('download')}
      centered
      wrapClassName={styles.downloadModal}
      width={500}
      onCancel={() => changeVisible(false, false)}
      maskClosable={false}
      footer={
        value === 'watermark'
          ? (
            <div style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                onClick={onOk}
                style={{ width: '144px', height: '40px' }}
                disabled={!data?.file_path?.includes('.pdf')}
              >
                {t('confirm')}
              </Button>
              <Button
                onClick={() => changeVisible(false, false)}
                style={{
                  width: '144px',
                  height: '40px',
                  marginLeft: '26px',
                }}
              >
                {t('cancel')}
              </Button>
            </div>
          )
          : <div style={{ height: '14px' }} />
      }
    >
      <p>{t('download_want')}</p>
      <Radio.Group
        onChange={(e) => setValue(e.target.value)}
        value={value}
      >
        <Radio value="watermark">
          {t('download_watermark')}
        </Radio>
        <Radio value="nowatermark">
          {t('download_no_watermark')}
        </Radio>
      </Radio.Group>
      {value === 'watermark' && !data?.file_path?.includes('.pdf') && (
        <p style={{ marginTop: '10px' }}>
          excel类型报告不支持下载
        </p>
      )}
      {value === 'nowatermark' && !!isAuth && (
        <p style={{ marginTop: '10px' }}>
          您的报告已申请，请前往个人中心-我的申请查看进度
        </p>
      )}
      {(value === 'nowatermark' && !isAuth)
        && (
          <div
            style={{
              background: '#F9F9F9',
              height: '210px',
              marginTop: '10px',
              padding: '20px 18px 20px 0px',
            }}
          >
            <Form
              labelCol={{ flex: '84px' }}
              labelAlign="right"
              form={form}
              colon={false}
              onFinish={onOk}
            >
              <Form.Item label={t('process_type_title')}>
                {resData && <SuperiorDisplay superiorData={resData} isZone={false} />}
              </Form.Item>
              <Form.Item
                name="reason"
                label={t('application_reason_title')}
                rules={[
                  {
                    required: true,
                    message: CURRENT_LANGUAGE === 'zh' ? '请填写申请原因' : 'Please fill in the reason',
                    transform: (v: string | undefined) => {
                      if (v === undefined) return '';
                      return v.trim();
                    },
                  },
                  {
                    min: 10,
                    message: CURRENT_LANGUAGE === 'zh' ? '请输入10个字以上' : 'Must be at least 10 characters',
                    transform: (v: string | undefined) => {
                      if (v === undefined) return '';
                      return v.trim();
                    },
                  },
                ]}
                style={{ height: '76px' }}
              >
                <Input.TextArea
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  placeholder={t('download_no_watermark_reason')}
                  maxLength={200}
                  minLength={10}
                  onChange={(e) => form.setFieldsValue({ reason: e.target.value })}
                />
              </Form.Item>
              <Form.Item label={t('applicant')}>
                <span>
                  {info?.user_id}
                </span>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '144px', height: '40px' }}
                >
                  {t('confirm')}
                </Button>
                <Button
                  onClick={() => changeVisible(false, false)}
                  style={{
                    width: '144px',
                    height: '40px',
                    marginLeft: '26px',
                  }}
                >
                  {t('cancel')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
    </Modal>
  );
};

export default DownloadModal;
