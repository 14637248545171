/* eslint-disable camelcase */
import request from '@/utils/request';
import { PaginationRequest, PaginationResponse, UserInfoData } from './response.type';
import { Stream } from './common';

export interface ApprovalDataItem extends UserInfoData {
  department: string,
  uid: number,
  object_id: number,
  object_type: string,
  type: number,
  effective_time: number,
  status: number,
  reason: string,
  created_at: string,
  updated_at: string,
  tail: boolean,
  szid: number,
}

export interface ApprovalListParams extends PaginationRequest {
  status: number,
  rtx?: string,
  department?: string,
}

export interface ApprovalData extends PaginationResponse {
  data: ApprovalDataItem[]
}

export async function getApprovalList(params: ApprovalListParams): Promise<ApprovalData> {
  const res = await request.get('/api/adminapi/approval/list', { params });

  return res.data;
}

export interface UpdateApprovalData {
  id: number,
  operate: number,
  reason?: string,
}

export async function updateApproval(payload: UpdateApprovalData) {
  const res = await request.post('/api/adminapi/approval/store', payload);

  return res.data;
}

export interface ProgressItem extends UserInfoData {
  auth_app_id: number,
  handle_by: number,
  operating: number,
  status: number,
  role: Stream[],
  current: number,
  reason: string,
  submit_at: string,
  created_at: string,
  updated_at: string,
}

export interface ApprovalProgressData {
  stream: ProgressItem[],
  reason: string,
}

export async function getApprovalProgress(authId: number): Promise<ApprovalProgressData> {
  const res = await request.get(`/api/adminapi/approval/stream/${authId}`);

  return res.data;
}

interface PlatformAgreementData {
  reason: string,
}
interface AgreementData{
  uid?: number,
  object_id?: number,
  type?: number,
  status?: number,
  reason?: string,
  updated_at?: string,
  created_at?: string,
  id?: number,
}
export async function addPlatformAgreement(payload: PlatformAgreementData): Promise<AgreementData> {
  const res = await request.post('/api/webapi/system/protocolStore', payload);

  return res.data;
}
