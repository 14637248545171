/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import configs from '@/configs';
import { UserInfo } from '@/services/users';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { UserInfoContext } from '.';

const Leaders: string[] = configs('app.Leaders');
const director = 'Director';

function usePlatformAgreement() {
  const { t } = useTranslation();
  const { info } = UserInfoContext.useContainer();
  const [platformAgreementVisible, setPlatformAgreementVisible] = useState(false);
  const [hasSubmittedPlatformAgreement, setHasSubmittedPlatformAgreement] = useState(true);
  const [validated, setValidated] = useState(true);
  const [isPass, setIsPass] = useState(true);
  const [validateFrom, setValidateFrom] = useState('');

  const staffAdjust = (data: UserInfo) => {
    const { protocol, group } = data;

    if (!protocol && !group?.length) {
      setIsPass(false);
      setValidated(false);
      setPlatformAgreementVisible(true);
      setHasSubmittedPlatformAgreement(false);
    } else if (protocol && !group?.length) {
      setIsPass(false);
      setValidated(true);
    } else {
      setIsPass(true);
      setValidated(true);
    }
  };

  const bossAdjust = (data: UserInfo) => {
    const { department, position_name: positionName } = data;
    const isIEGStaff = department.includes('IEG');
    if (isIEGStaff || (!isIEGStaff && !positionName.includes(director))) {
      setIsPass(true);
      setValidated(true);
    } else {
      staffAdjust(data);
    }
  };

  const userAuthAdjust = (data: UserInfo) => {
    const { position_name: positionName } = data;

    if (Leaders.some((item) => positionName.includes(item))) {
      bossAdjust(data);
    } else {
      staffAdjust(data);
    }
  };

  const agreementValidate = (): boolean => {
    if (isPass) return true;
    if (!validated) {
      setPlatformAgreementVisible(true);
      return false;
    }
    if (validated && !hasSubmittedPlatformAgreement) {
      return false;
    } if (validated && hasSubmittedPlatformAgreement) {
      message.info(t('application_submitted'));
      return false;
    }
    return true;
  };

  const needAd = (data: UserInfo): boolean => {
    const { group } = data;
    return group?.some((item) => item.group_name === 'IMUR') || false;
  };

  useEffect(() => {
    if (info) {
      userAuthAdjust(info);
    } else {
      setIsPass(true);
      setValidated(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  return {
    platformAgreementVisible,
    hasSubmittedPlatformAgreement,
    validated,
    setPlatformAgreementVisible,
    isPass,
    agreementValidate,
    validateFrom,
    setValidateFrom,
    needAd,
  };
}

const PlatformAgreement = createContainer(usePlatformAgreement);

export default PlatformAgreement;
