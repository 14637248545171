import { useState } from 'react';
import { createContainer } from 'unstated-next';

interface MenuAction {
  action: 'add',
  parentId?: number,
}

function useAdminBaseMenu() {
  const [menuId, setMenuId] = useState<number>(0);
  const [menuAction, setMenuAction] = useState<MenuAction | null>(null);
  const selectMenu = (id: number) => {
    setMenuAction(null);
    setMenuId(id);
  };
  const selectMenuAction = (payload: MenuAction) => setMenuAction(payload);
  const resetMenuId = () => setMenuId(0);
  const resetMenu = () => {
    setMenuId(0);
    setMenuAction(null);
  };

  return {
    menuId,
    selectMenu,
    menuAction,
    selectMenuAction,
    resetMenuId,
    resetMenu,
  };
}

const AdminBaseMenu = createContainer(useAdminBaseMenu);

export default AdminBaseMenu;
