/* eslint-disable camelcase */
import request from '@/utils/request';
import { CURRENT_LANGUAGE } from '@/i18n';
import { productRequest, removeObjectFalsyKey, filterRtx } from '@/utils/helper/base';
import {
  ProductId, AdminMenuData, SuperiorData, ApplyPermissionData, MenuData, UserPreferenceOperate,
} from '@/services/common';
import {
  SearchReportData, ReportPreviewData, ReportImages, ReadAuthData,
} from '@/services/report-library';
import { MenuTreeData, ApplicationMenuAuthData } from '@/services/menu';
import { ExtClassItem } from './admin/class';
import { ZoneItemData } from '../single-product';

interface ZoneListData {
  [key: string]: ZoneItemData[]
}

export async function getZone(name?: string): Promise<ZoneListData> {
  const res = await request.get('/api/webapi/zone/list', name ? { params: { name } } : undefined);

  return res.data;
}

export async function getAuthZone(): Promise<ZoneItemData[]> {
  const res = await request.get('/api/webapi/zone/authList');

  return res.data;
}

export async function getProductMenuTree(productId: ProductId): Promise<AdminMenuData | []> {
  const res = await productRequest('/api/szweb/adminMenu/menus?scope=webSzAdmin', productId);

  return res.data;
}

export interface ReportListParams {
  keyword?: string,
  ext_field?: string[][],
  order?: object,
  page: number,
  page_size: number,
}

export async function getReportList(productId: number, payload: ReportListParams): Promise<SearchReportData> {
  const params = { ...payload };
  if (params.keyword) {
    params.keyword = await filterRtx(params.keyword);
  }
  if (!params.keyword) {
    params.order = {
      output_at: 'desc',
    };
  }
  const res = await productRequest(
    `/api/szweb/report/search?sys_lang=${CURRENT_LANGUAGE}`, productId,
    {
      method: 'post',
      data: removeObjectFalsyKey(params),
    },
  );

  return res.data;
}

export async function getExtClass(productId: number): Promise<ExtClassItem[]> {
  const res = await productRequest(
    `/api/szweb/report/getExtClassification/${CURRENT_LANGUAGE}`, productId,
  );

  return res.data;
}

export interface FullReportResponseData {
  rid: number,
  title: string,
  pic: string,
  sort: number,
  content: string[],
  created_at: string,
  updated_at: string,
  created_timestamp: number,
  updated_timestamp: number,
  id: number,
}

interface FullReportResponse {
  total?: number,
  data: FullReportResponseData[]
}

export async function getFullReport(params: {
  page: number, page_size: number, keyword?: string,
}, productId: number): Promise<FullReportResponse> {
  const res = await productRequest(
    '/api/szweb/reportPage/search',
    productId,
    { method: 'post', data: params },
  );
  return res.data;
}

export async function updateReportOperate(
  productId: ProductId, openid: number | string, field: string, cancel: number,
) {
  const res = await productRequest(
    '/api/szweb/report/cul',
    productId,
    {
      method: 'post',
      data: {
        openid,
        field: { [field]: cancel },
      },
    },
  );

  return res.data;
}

export async function getReportPreviewDetail(productId: ProductId, openid: string): Promise<ReportPreviewData> {
  const res = await productRequest(`/api/szweb/report/preview/${openid}`, productId);

  return res.data;
}

export async function getMailToken(productId: ProductId, openid: string): Promise<string> {
  const res = await productRequest(`/api/szadmin/report/encryptOpenid/${openid}`, productId);

  return res.data;
}

export async function getReportPreviewDetailMail(productId: ProductId, token: string): Promise<ReportPreviewData> {
  const res = await productRequest(`/api/webapi/report/previewCrypt/${token}`, productId);

  return res.data;
}

export async function getReportOperate(
  productId: ProductId, openid: string, field: string[],
): Promise<UserPreferenceOperate> {
  const res = await productRequest('/api/szweb/report/hadCul', productId, {
    method: 'post',
    data: { openid, field },
  });
  return res.data;
}

export async function getZoneReportApprover(productId: ProductId, openid: string): Promise<SuperiorData> {
  const res = await productRequest(`/api/szweb/report/auth/description?openid=${openid}`, productId);

  return res.data;
}

export async function getReportAuthSingle(szid: ProductId, openid: string) {
  const res = await request.get(`/api/szweb/report/downloadDetail/${openid}?szid=${szid}`);
  return res.data;
}

export async function applyZoneReportPermission(productId: ProductId,
  payload: ApplyPermissionData): Promise<ReadAuthData> {
  const res = await productRequest('/api/szweb/report/auth', productId,
    { method: 'post', data: payload });

  return res.data;
}

export async function getZoneBaseMenu(productId: ProductId, pinyin: string): Promise<MenuTreeData> {
  const res = await productRequest(`/api/szweb/${pinyin}/menu/tree_menu`, productId);

  return res.data;
}

type MenuId = number;

export async function getZoneMenuData(productId: ProductId, menuId: MenuId): Promise<MenuData> {
  const res = await productRequest(`/api/szweb/menu/data/${menuId}`, productId);

  return res.data;
}

export async function getZoneApprover(productId: ProductId, id: number): Promise<SuperiorData> {
  const res = await productRequest(`/api/szweb/menu/readAuth/description?id=${id}`, productId);

  return res.data;
}

export async function applicationZoneMenuAuth(
  productId: ProductId, payload: ApplicationMenuAuthData,
): Promise<Boolean> {
  const res = await productRequest('/api/szweb/menu/readAuth', productId,
    { method: 'post', data: payload });

  return res.data;
}

export async function getZoneReportImages(
  productId: ProductId, openid: string, page: number, pageSize = 5,
): Promise<ReportImages[]> {
  const res = await productRequest(`/api/szweb/images/list?openid=${openid}&page=${page}
  &page_size=${pageSize}`, productId);

  return res.data;
}

export async function getZoneReportImagesMail(
  productId: ProductId, openid: string, page: number, pageSize = 5,
): Promise<ReportImages[]> {
  const res = await productRequest(`/api/webapi/images/list?openid=${openid}&page=${page}
  &page_size=${pageSize}`, productId);

  return res.data;
}

interface ApplyResData{
  error_code?: number;
  details?: string;
  length?: number;
  data?: {
    uid?: number,
    object_id?: number,
    type?: number,
    status?: string,
    updated_at?: string,
    created_at?: string,
    id?: number
  }
}
export async function applyZoneAuth(szid: number, reason: string): Promise<ApplyResData> {
  const res = await request({
    url: '/api/webapi/zone/protocolStore',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', szid },
    data: { reason },
  });
  return res.data;
}
