import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import configs from '@/configs';
import { environment } from '@/utils/helper/base';
import App from './app';
import 'assets/styles/core.less';
import polyfill from './polyfill';

polyfill();

if (!environment('local')) {
  Sentry.init({
    dsn: 'https://e094dddb619ad83e89a849201658252d@o4505871093989376.ingest.sentry.io/4506386041208832',
    environment: configs('app.serverEnv'),
    release: process.env.REACT_APP_RELEASE_VERSION,
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: Cancelled',
      'Non-Error promise rejection captured with keys: errors',
    ],
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(Router),
      }),
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.render(
  // <React.StrictMode>
  <Router>
    <App />
  </Router>,
  // </React.StrictMode>,
  document.getElementById('root'),
);
