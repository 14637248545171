/* eslint-disable camelcase */
import React, { FC, useEffect, useState } from 'react';
import {
  Form, Radio, Select, Modal,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { MenuPermission } from '@/configs/type';
import configs from '@/configs';
import { UserGroupItemData } from '@/services/user-group';
import { getStaff } from '@/services/common';
import {
  handleGroupDelete, handleGroupSelect, validateSpecialPerm, validateRtx,
} from '@/utils/helper/form';
import { RtxSelect } from '@/components';
import { FormInstance } from 'antd/lib/form';
import { unlockMail } from '@/services/report-library-admin';
import DepartmentSelectForm from './department-select';
import styles from './index.module.less';

const PERMISSIONS = configs('menu.permissions');
const SPECIAL_PERM = configs('menu.specialPerm');
const ANYONE_PERM = configs('menu.anyonePerm');
const ALL_GROUP_ID = configs('user-group.allGroupId');
const EXTERNAL_USER_TYPE = 3;
const { Option } = Select;

interface AuthorityProps {
  form: FormInstance,
  userGroup: UserGroupItemData[],
  initUserValues?: string[],
  authorityKey: string,
  authorityLabel: string,
  userGroupKey: string,
  userGroupLabel: string,
  userKey: string,
  userLabel: string,
  required?: boolean,
  rtxSelect?: boolean,
  isUpdate?: boolean,
  keep_eye?: number,
  editId?: string,
  onChange?: Function,
  id?: string,
  needCopy?: boolean,
  clearUsers?: number,
  needOrg?: boolean,
  userOrgKey?: string,
  userOrgLabel?: string,
  initWidth?: number,
}

const Authority: FC<AuthorityProps> = ({
  form, userGroup, initUserValues, authorityKey,
  authorityLabel, userGroupKey,
  userGroupLabel, userKey, userLabel, required,
  rtxSelect = true, isUpdate = false, editId,
  keep_eye = 0, onChange, id, needCopy, clearUsers,
  needOrg = false, userOrgKey, userOrgLabel, initWidth = 100,
}: AuthorityProps) => {
  const [authorityValue, setAuthorityValue] = useState(authorityKey === 'read_authority'
    ? form.getFieldValue('read_authority') : 1);
  const [userIds, setUserIds] = useState<string[]>();

  const handleChange = (event: any) => {
    const { value } = event.target;
    if (value === ANYONE_PERM && authorityKey === 'read_authority' && isUpdate && keep_eye > 0) {
      Modal.confirm({
        title: '是否开启权限？',
        icon: <ExclamationCircleOutlined />,
        content: `您的报告有${keep_eye}名用户关注了，开启权限会自动通知这些用户前来查看`,
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          form.setFieldsValue({
            read_authority: value,
          });
          if (editId) {
            unlockMail({ rid: editId });
          }
          setAuthorityValue(value);
        },
        onCancel: () => {
          form.setFieldsValue({
            read_authority: authorityValue,
          });
        },
      });
    }
    if (authorityKey === 'read_authority' && isUpdate) {
      setAuthorityValue(value);
    }
    if (value === SPECIAL_PERM
      && !(userIds && userIds.length > 0)
      && !(initUserValues
      && initUserValues.length > 0)
      && onChange) {
      onChange(authorityKey);
    }
  };

  const handleOption = (inputValue: any, option: any) => option.children && option.children.includes(inputValue);

  const handleUserChange = (ids: string[]) => {
    setUserIds(ids);
  };

  useEffect(() => {
    if (clearUsers) {
      setUserIds([]);
    }
  }, [clearUsers]);

  useEffect(() => {
    if (initUserValues?.length) {
      setUserIds(initUserValues);
    }
  }, [initUserValues]);

  return (
    <>
      <Form.Item
        name={authorityKey} label={authorityLabel}
        required={required}
      >

        <Radio.Group buttonStyle="solid" onChange={(event) => handleChange(event)}>
          {PERMISSIONS.map((permission: MenuPermission) => (
            <Radio.Button key={permission.key} value={permission.key}>
              {permission.label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (getFieldValue(authorityKey) === SPECIAL_PERM ? (
          <div className={styles.authorGroup}>
            <Form.Item
              name={userGroupKey} label={userGroupLabel}
              rules={[
                { required: false, validator: () => validateSpecialPerm(form, userGroupKey, userKey, userOrgKey) },
              ]}
            >
              <Select
                mode="multiple"
                onSelect={(value: string) => handleGroupSelect(form, value, userGroup, userGroupKey)}
                onDeselect={(value: any) => handleGroupDelete(form, value, userGroupKey)}
                filterOption={(inputValue, option) => handleOption(inputValue, option)}
                style={{ width: `${initWidth}%` }}
              >
                {userGroup?.map((item) => (
                  <Option
                    key={item.id ? item.id : ALL_GROUP_ID}
                    value={item.id ? item.id : ALL_GROUP_ID}
                    disabled={item.type === EXTERNAL_USER_TYPE}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {
            rtxSelect
            && (
            <Form.Item
              label={userLabel} name={userKey}
              rules={[
                { required: false, validator: () => validateSpecialPerm(form, userGroupKey, userKey, userOrgKey) },
                {
                  required: false,
                  validator: () => {
                    if (initUserValues?.length) {
                      const dataSource: string[] = form.getFieldValue(userKey);
                      const res = dataSource?.filter((item) => !initUserValues.includes(item));
                      if (res.length) {
                        return validateRtx(form, userKey);
                      }
                      return Promise.resolve();
                    }
                    return validateRtx(form, userKey);
                  },
                },
              ]}
            >
              <RtxSelect
                id={id}
                needCopy={needCopy}
                onChange={(ids) => handleUserChange(ids)}
                initialValues={userIds}
                placeholder="请输入中文名称，rtx英文或者邮箱格式"
                compatible
                requiredValue={userKey?.includes('read') ? 'ivyzheng' : ''}
                style={{ width: `${initWidth}%` }}
              />
            </Form.Item>
            )
          }
            {needOrg && (
              <DepartmentSelectForm
                hasRules={[{
                  required: false,
                  validator: () => validateSpecialPerm(form, userGroupKey, userKey, userOrgKey),
                }]}
                hasPlaceholder={false}
                label={userOrgLabel as string}
                name={userOrgKey as string}
                getZoneServiceFn={(word: string, size: number) => getStaff(1, word.trim(), size)}
                style={{ width: `${initWidth}%` }}
              />
            )}
          </div>
        ) : null)}
      </Form.Item>
    </>
  );
};

export default Authority;
