/* eslint-disable camelcase */
import request from '@/utils/request';
import { productRequest } from '@/utils/helper/base';
import { ProductId, StaffData, StaffType } from '@/services/common';
import { ZoneInfo } from '@/services/response.type';

export async function getZoneInfo(id: number): Promise<ZoneInfo> {
  const res = await request.get(`/api/szweb/zone/editPage/${id}?szid=${id}`);

  return res.data;
}

export async function getZoneBaseSet(productId: ProductId): Promise<ZoneInfo> {
  const res = await productRequest('/api/szadmin/baseSet/editPage', productId);

  return res.data;
}

export async function updateZoneBaseSet(productId: ProductId, payload: FormData) {
  const res = await request({
    method: 'post',
    url: '/api/szadmin/baseSet/edit',
    headers: {
      'Content-Type': 'multipart/form-data',
      szid: String(productId),
    },
    data: payload,
  });

  return res;
}

export async function getZoneStaff(
  productId: ProductId, type: StaffType, name?: string, size?: number,
): Promise<StaffData[]> {
  const res = await productRequest(`/api/szadmin/staff/search?type=${type}&name=${name}&size=${size}`, productId);

  return res.data;
}
