/* eslint-disable camelcase */
import request from '@/utils/request';
import { CURRENT_LANGUAGE } from '@/i18n';
import { BaseUserInfo, UserInfoResponse, WriterOrCharger } from './response.type';

export interface BannerItem {
  report_type: number;
  openid: string,
  output_at: string;
  id: number,
  url: string,
  images: string,
  user: UserInfoResponse,
  report_created_at: string,
  read_auth: number,
  banner_type: string,
  title?: string,
}

export async function getBannerData(): Promise<BannerItem[]> {
  const res = await request.get('/api/webapi/banner/banner');
  return res.data;
}

export interface SystemStatisticsData {
  report: number,
  idata: number,
}

export interface HotItem {
  id: number,
  type: string,
  times: number,
  word: string,
  word_info: string,
  created_at: string,
  updated_at: string,
  read_auth: number,
}

export interface ResearchItem {
  name: string,
  id: number
}

export interface HotData {
  report: HotItem[],
  idata: HotItem[],
  research: ResearchItem[]
}

export interface ReportRankItem {
  report_type: string;
  cover_pic: string,
  created_at: string,
  title: string,
  id: number,
  write_by: BaseUserInfo[],
  read_auth: number,
  output_at: string,
  preview_pic?: string | string[],
  openid: string,
}

interface NewReportItem {
  relegation: string,
  cover_pic: string,
  created_at: string,
  tag: [],
  title: string,
  id: number,
  write_by: BaseUserInfo,
  read_auth: number,
  output_at: string,
  openid: string,
  report_type: string,
}

export interface NewReportData {
  total: number,
  data: NewReportItem[],
}

interface HomeParams {
  newest_page_size: number,
  grouping_page_size?: number,
}

interface HomeData {
  tag: string[];
  subscribed: { total: number; data: ReportRankItem[]; };
  newestReport: NewReportData,
  grouping: [ReportRankItem[]],
  count: SystemStatisticsData,
  heatWord: HotData,
}

export async function getHomeData(params: HomeParams): Promise<HomeData> {
  const res = await request.get('/api/webapi/home/gather', { params: { ...params, sys_lang: CURRENT_LANGUAGE } });

  return res.data;
}

export interface TopicDataItemAssociated {
  id: number,
  openid: string,
  created_at: string,
  title: string,
  cover_pic: string,
  write_by: WriterOrCharger[],
  preview_pic: string[],
  media_id: number,
  report_type: string,
  introduction_tag: string,
  output_at: string,
}

export interface TopicDataItem {
  id: number,
  topic: string,
  foreword: string,
  related_openids: string,
  order: number,
  status: number,
  categories: {
    name: string,
    links: {
      type: string,
      report_url: string,
      title: string,
      pic_url?: string,
      introduction?: string,
    }[],
    associated: TopicDataItemAssociated[],
  }[],
}

export interface ViewDataItem {
  type: string,
  title: string,
  coverPic: string,
  introduction: string,
  openid?: string,
  reportType?: string,
  writers?: WriterOrCharger[],
  outputAt?: string,
  previewPic?: string[],
  reportUrl?: string,
}

export async function getTopicData(): Promise<TopicDataItem[]> {
  const res = await request.get('/api/webapi/topic/list');
  return res.data;
}

interface SurveyData {
  score: number;
  proposal: string;
  created_at: string,
}

export async function getSurveyData(module: string): Promise<SurveyData> {
  const res = await request.get(`/api/webapi/survey/view/${module}`);

  return res.data;
}

interface SurveyParams {
  score?: number,
  proposal?: string,
  module: string,
}

export async function addSurveyData(params: SurveyParams): Promise<boolean> {
  const res = await request.post('/api/webapi/survey/add', params);

  return res.data;
}

export function handleIconSrc(isPay: boolean, lang: string, type: number): string {
  if (type === 0) {
    if (isPay) {
      return 'subsCorner';
    }
    if (lang === 'zh') {
      return 'unSubsCorner';
    }
    return 'unSubsCornerEN';
  }
  if (isPay) {
    return 'subsIcon';
  }
  if (lang === 'zh') {
    return 'unSubsSide';
  }
  return 'unSubsSideEN';
}

export interface SubscribeDataTag {
  tag_name: string,
  need_remind: boolean,
}

export interface SubscribeDataWriter {
  writer_rtx: string,
  writer_name: string,
  headimg: string,
  need_remind: boolean,
}

export interface SubscribeData {
  tag: SubscribeDataTag[],
  writer: SubscribeDataWriter[],
}

export async function getSubscribeData(): Promise<SubscribeData> {
  const res = await request.get('/api/webapi/subscribed/group');
  return res.data;
}

export async function updateWriter(writer: string, subscribed: number): Promise<UpdateAllRes> {
  const res = await request.post('/api/webapi/report/subscribed', { subscribed, writer: [writer] });
  return res.data;
}

export async function clickWriter(author: string): Promise<Boolean> {
  const res = await request.post('/api/webapi/redDot/accept', { author });
  return res.data;
}

export async function clickTags(tag: string): Promise<Boolean> {
  const res = await request.post('/api/webapi/redDot/accept', { tag });
  return res.data;
}

export interface SubscribeDataSource {
  tag: {
    tag_id: string,
    tag_name: string,
  }[],
  writer: {
    writer_rtx: string,
    writer_name: string,
    headimg: string,
  }[],
  tag_top: {
    tag_id: string,
    tag_name: string,
  }[],
  tag_search?: {
    tag_id: string,
    tag_name: string,
  }[],
  writer_search?: {
    writer_rtx: string,
    writer_name: string,
    headimg: string,
  }[],
}

export async function getInitSubscribeData(): Promise<SubscribeDataSource> {
  const res = await request.get('/api/webapi/subscribed/recommend');
  return res.data;
}

export async function getNormalSubscribeData(tagPage: number, writerPage: number): Promise<SubscribeDataSource> {
  const res = await request({
    method: 'POST',
    url: '/api/webapi/subscribed/recommend/list',
    data: {
      tag: { page: 1, page_size: tagPage },
      writer: { page: 1, page_size: writerPage },
    },
    headers: { 'Content-Type': 'application/json' },
  });
  return res.data;
}

interface UpdateAllRes {
  data?: boolean,
  message?: string,
  status?: string,
}

export async function updateAll(writer: string[], word: string[]): Promise<UpdateAllRes> {
  let params: { subscribed: number, writer?: string[], word?: string[] } = { subscribed: 0 };
  if (writer?.length) {
    params = { ...params, writer };
  }
  if (word?.length) {
    params = { ...params, word };
  }
  const res = await request.post('/api/webapi/report/subscribed', params);
  return res.data;
}
