import _, { isArray, isEmpty } from 'lodash';
import configs from '@/configs';
import request from '@/utils/request';
import { UserGroupItemData } from '@/services/user-group';
import { rtxValidate } from '@/services/users';
import { MouseEvent } from 'react';
import { MenuTreeData } from '@/services/menu';

type Object = { [key: string]: any };

export function environment(env: string) {
  if (env) {
    return process.env.REACT_APP_ENV === env;
  }

  return process.env.REACT_APP_ENV;
}

/**
 * 过滤对象中的假值
 * @param object
 */
export function removeObjectFalsyKey<T extends Object>(object: T) {
  return _.pickBy(object, _.identity);
}

/**
 * 过滤数组中的假值
 * @param string[]
 */
export function removeArrayFalsyKey<T extends string[]>(array: T) {
  return _.remove(array, _.identity);
}

/**
 * 过滤用户id中的em标签
 * @param string
 */
export function removeUserEmSpan(userId: string) {
  return userId.replace(/<\/?.+?>/g, '');
}

/**
 * 过滤对象中的 NULL/undefined
 * @param object
 */
export function removeObjectNil<T extends Object>(object: T) {
  return _.omitBy(object, _.isNil);
}

export function urlEncode(param: any, key: string | undefined) {
  if (param == null) return '';
  let paramStr = '';
  const type = typeof (param);
  if (type === 'string' || type === 'number' || type === 'boolean') {
    paramStr += `&${key}=${encodeURIComponent(param)}`;
  } else {
    Object.keys(param).forEach((i) => {
      const k = key === undefined ? i : key + (param instanceof Array ? `[${i}]` : `.${i}`);
      paramStr += urlEncode(param[i], k);
    });
  }
  return paramStr;
}

export function formDataTransform(formData: FormData, values: any) {
  Object.keys(values).forEach((key) => {
    if (values[key] !== undefined) {
      if (Array.isArray(values[key])) {
        values[key].forEach((item: any, i: number) => {
          formData.append(`${key}[${i}]`, item);
        });
      } else {
        formData.append(key, values[key]);
      }
    }
  });
}

export function handleOpenReport(openid: string) {
  window.open(`/report-library/detail/${openid}`);
}

export function numberTransform(num: number): string | number {
  if (num > 10000) {
    return `${(num / 10000).toFixed(1)}W`;
  } if (num > 1000) {
    return `${(num / 1000).toFixed(1)}K`;
  }
  return num;
}

export function isMobilePhone() {
  if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
    return true;
  }
  return false;
}

export function getPageEnterFrom(): string {
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.get('from') || '';
}

export function beaconUpdateArrayTransformString(data: number[]): string {
  return data.reduce((pre, cur, index) => (index === 0 ? `${cur}` : `${pre},${cur}`), '');
}

export function removeBaseUserGroup(data: UserGroupItemData[]) {
  const baseUserGroupName = configs('user-group.baseUserGroupName');
  data.splice(data.findIndex((item) => item.name === baseUserGroupName), 1);
}

type MethodType = 'get' | 'post' | 'delete' | 'put';
type BodyType = string | FormData | URLSearchParams | Blob
| ArrayBufferView | ArrayBuffer | ReadableStream<Uint8Array> | null | undefined | Object;
interface RequestConfig {
  method?: MethodType,
  data?: BodyType,
  headers?: {},
  config?: {},
}

export function productRequest(url: string, productId: number,
  othersConfig: RequestConfig = {}) {
  const {
    method, data, headers, config,
  } = othersConfig;
  return request.request({
    url,
    method: method || 'get',
    headers: {
      szid: String(productId),
      ...headers,
    },
    data,
    ...config,
  });
}

export function dataSplice(data: any[], key: string, separator: string): string {
  return data.reduce((acc, cur) => (acc
    ? `${acc}${separator}${key ? cur[key] : cur}` : `${key ? cur[key] : cur}`), '');
}

export const removeSessionStorageId = (link?: string) => {
  const removeSessionStorageIdLinks = ['/base', '/report-library/detail', '/report-library/author'];
  if (link && removeSessionStorageIdLinks.includes(link)) {
    switch (link) {
      case '/base':
        sessionStorage.removeItem('tag_id');
        break;

      case '/report-library/detail':
        sessionStorage.removeItem('report_id');
        break;

      default:
        sessionStorage.removeItem('author_id');
        break;
    }
  }
};

const unTagIdKey = ['stay_time_game_competitor_page'];

const stayObj: any = {
  stay_newfirst_free_page: 'stay_newfirst_dingyue_page',
  stay_time_report_home_page: 'stay_time_report_dingyue_home_page',
  stay_mark_user_data_page: 'stay_dingyue_market_user_data_page',
  stay_global_research_home_page: 'stay_dingyue_global_research_home_page',
};

export function stayPageBeaconUpdate(beacon: any, info: any, isPay?: boolean) {
  const preBeaconStayKey = sessionStorage.getItem('beacon-stay-key');
  const enterTime = sessionStorage.getItem('enter_time');
  const curTime = new Date().getTime();
  const beaconStayTimeKey = window.location.pathname.match(/^(\/[A-Za-z0-9-]*){0,3}/g)?.[0];
  const curBeaconStayKey = configs(`beacon.${beaconStayTimeKey}`);
  if (preBeaconStayKey && preBeaconStayKey !== 'undefined' && enterTime && preBeaconStayKey !== curBeaconStayKey) {
    const reportId = sessionStorage.getItem('report_id');
    const tagId = unTagIdKey.includes(preBeaconStayKey || '') ? '' : sessionStorage.getItem('tag_id');
    const authorId = sessionStorage.getItem('author_id');
    beacon.onDirectUserAction(preBeaconStayKey,
      removeObjectFalsyKey({
        stay_time: curTime - (+enterTime),
        // eslint-disable-next-line camelcase
        user_id: info?.user_id,
        report_id: reportId,
        tag_id: tagId,
        author_id: authorId,
      }));
    sessionStorage.setItem('beacon-stay-key', isPay ? stayObj?.[curBeaconStayKey] || '' : curBeaconStayKey);
    sessionStorage.setItem('enter_time', `${curTime}`);
    removeSessionStorageId(preBeaconStayKey);
  } else if (!preBeaconStayKey || preBeaconStayKey === 'undefined' || !enterTime) {
    sessionStorage.setItem('beacon-stay-key', isPay ? stayObj?.[curBeaconStayKey] || '' : curBeaconStayKey);
    sessionStorage.setItem('enter_time', `${curTime}`);
  }
}

export async function isRtx(rtx: string): Promise<boolean> {
  const result = await rtxValidate([rtx]);
  return result;
}

/**
 * 过滤 rtx 格式，如果是搜索 rtx ，则返回 user_id
 * @param name 搜索名
 */
export async function filterRtx(name: string): Promise<string> {
  const rtxRegex = new RegExp(/^[a-zA-Z][a-zA-Z_]([a-zA-Z]+)\([\u4e00-\u9fa5a-zA-Z\s]{1,}\)$/);
  if (rtxRegex.test(name)) {
    const match = name.match(/([a-zA-Z_]+)/);
    if (match) {
      const result = await isRtx(match[0]);

      return result ? match[0] : name;
    }
    return name;
  }
  return name;
}

const otherRtx = configs('report.otherRtx');

export function pathToAuthorPage(event: MouseEvent, author: string, authorName: string) {
  event.stopPropagation();
  window.open(`/report-library/author/${author}/${authorName === '' ? otherRtx : authorName}`);
}

export function pathToTagPage(event: MouseEvent, tag: string) {
  event.stopPropagation();
  window.open(`/report-library/tag?subscribed=${tag}`);
}

const getFileBlob = (filepath: string, productId?: number): Promise<Blob> => new Promise((resolve) => {
  const xhr = new XMLHttpRequest();

  xhr.open('GET', filepath, true);
  xhr.responseType = 'blob';
  xhr.setRequestHeader('content-type', 'application/vnd.ms-excel');
  if (productId) {
    xhr.setRequestHeader('szid', String(productId));
  }
  xhr.onload = () => {
    if (xhr.status === 200) {
      resolve(xhr.response);
    }
  };

  xhr.send();
});

const saveFile = (blob: Blob, filename: string) => {
  const a = document.createElement('a');
  const { body } = document;

  a.href = window.URL.createObjectURL(blob);
  a.download = filename;

  // fix Firefox
  a.style.display = 'none';
  body.appendChild(a);

  a.click();
  body.removeChild(a);

  window.URL.revokeObjectURL(a.href);
};

export function downLoadExcel(filepath: string, filename: string, productId?: number) {
  getFileBlob(filepath, productId).then((blob: Blob) => {
    saveFile(blob, filename);
  });
}

export function filterEMTag(value: string): null | string[] {
  return value.match(/[^><]+(?=<\/em>)/img);
}

export function isSingleProductPage() {
  const { pathname } = window.location;

  return pathname.includes('/single-product');
}

export function getDefaultOpenKeys(menuTree: MenuTreeData[], selectId: number): any {
  return menuTree.map((item) => {
    if (item.children.length) {
      const selectMenu: undefined | string[] | string = getDefaultOpenKeys(item.children, selectId);
      if (selectMenu && Array.isArray(selectMenu) && selectMenu.flat()?.length) {
        return selectMenu.flat().concat(item.id.toString()).flat();
      } if (selectMenu && typeof selectMenu === 'string') {
        return [selectMenu];
      }
    } else if (item.id === selectId) {
      return item.id.toString();
    }
    return [];
  });
}

export function limitTitleLength(title: string, length: number): string {
  return title.length > length ? `${title.substr(0, length - 1)}...` : title;
}

interface JsonPayload {
  [key: string]: any
}

function buildFormData(formData: FormData, data: any, parentKey = '') {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

export function transformJsonToFormData(payload: JsonPayload): FormData {
  const formData = new FormData();
  buildFormData(formData, removeObjectNil(payload));

  return formData;
}

// 检查浏览器是否支持webp
export function checkWebpSupport() {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    img.onload = (): void => {
      const result = img.width > 0 && img.height > 0;
      resolve(result);
    };
    img.onerror = (): void => {
      reject();
    };
  });
}
// 图片转换
export const dataURLtoBlob = (dataurl: string) => {
  const arr = dataurl.split(',');
  const mime = arr[0]?.match(/:(.*?);/) ?.[1];
  const bstr = atob(arr[1]);
  const n = bstr.length;
  const u8arr = new Uint8Array(n);
  for (let i = 0; i < n; i++) {
    u8arr[i] = bstr.charCodeAt(i);
  }
  return new Blob([u8arr], { type: mime });
};
export const base64ToFile = (base64: string, fileName: string) => {
  const blob = dataURLtoBlob(base64);
  return new File([blob], fileName, { type: 'image/png' });
};
export const urlToBase64 = (url: string) => new Promise((resolve) => {
  const img = new Image();
  img.src = url;
  img.setAttribute('crossOrigin', 'Anonymous');
  img.onload = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
    context!.drawImage(img, 0, 0, img.width, img.height);
    const base64 = canvas.toDataURL('image/png');
    resolve(base64);
  };
});
export const fileToBase64 = (imgFile: any) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => resolve(reader.result));
  reader.readAsDataURL(imgFile);
});
export const isBase64 = (str: string) => {
  if (str === '' || str.trim() === '') return false;
  // eslint-disable-next-line
  const base64Reg = /^\s*data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@\/?%\s]*?)\s*$/i;
  return base64Reg.test(str);
};
// 复杂对象转formData
export const objectToFormData = (data: any, parent = '', formData = new FormData()) => {
  const form = formData;
  Object.keys(data).forEach((key, index) => {
    const value = data[key];
    if (isArray(value)) {
      let parentKey = parent ? `${parent}[${index}][${key}]` : key;
      if (!isArray(data[parent])) {
        parentKey = parent ? `${parent}[${key}]` : key;
      }
      objectToFormData(value, parentKey, form);
    } else if (typeof (value) === 'object') {
      const parentKey = parent ? `${parent}[${key}]` : key;
      objectToFormData(value, parentKey, form);
    } else {
      const parentKey = parent ? `${parent}[${key}]` : key;
      formData.append(parentKey, data[key]);
    }
  });
  return form;
};
// 为url拼接params和query参数
interface Query{
  [key: string]: string
}
export const setUrl = (url: string, params: any[], query: Query) => {
  let paramsStr = '';
  let queryStr = '';
  if (params.length) {
    paramsStr = params.reduce((pre, cur) => `${pre}/${cur}`, paramsStr);
  }
  if (!isEmpty(query)) {
    const resStr = Object.keys(query).reduce((pre, cur) => `${pre + cur}=${query[cur]}&`, '?');
    queryStr = resStr.substring(0, resStr.length - 1);
  }
  return `${url}${paramsStr}${queryStr}`;
};

// 获取文章中第一个图片地址
export const getImgSrc = (text: string): string => {
  const reg1 = /<img [^>]*src=['"]([^'"]+)[^>]*>/g;
  const regResult = reg1.exec(text);
  if (regResult) {
    return regResult[1];
  }
  return '';
};

export const getQueryVariable = (variable: string) => {
  const query = decodeURIComponent(window.location.href);
  const vars = query.split('&');
  // eslint-disable-next-line no-restricted-syntax
  for (const ele of vars) {
    const pair = ele.split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return '';
};
