/* eslint-disable max-len */
const HIGHT_INTELLIGENCE = '高智性';
const HIGHT_IMMERSION = '高沉浸';
const CHINESE_NARRATIVE = '中式叙事';
const HIGH_EFFICIENCY = '高效率';
const PURSUIT_OF_MEANING = '追求意义';
const PARTICIPATE_IN_CREATION = '参与创造';
const INDIVIDUALIZE = '个体化';
const RETRO_TREND = '复古潮流';

export default {
  [HIGHT_INTELLIGENCE]: '未来主流的年轻用户市场，主要是受过大学教育的用户构成。他们的娱乐需求上，更重视价值观表达、重内容逻辑性，本身审美能力也有所提升。高智性趋势出现，年轻人对待娱乐的意义，从“打发时间”到“娱乐时也看重价值感”。这会产生一些表现，如：1、探讨多元目标/成就2、偏好系统性审美和文化深度3、喜欢节奏快信息量大的作品，如悬疑烧脑、科幻题材、解谜玩法兴起4、喜欢有趣演绎学术内容的娱乐作品5、关注内容是否还原考据严谨',
  [HIGHT_IMMERSION]: '都市忙碌，高压的生活方式泛化，各种焦虑心态出现，为了释压，人们更愿意花钱享受能带来深度沉浸、身心放松的娱乐体验。体验技术的发展也是原因之一。高沉浸趋势出现，年轻人在娱乐中，追求更隔绝、深度的娱乐体验，以此获得身心放松。这会产生一些表现，如：1、五感延伸，在视听之外不断延伸，开始追求嗅觉、触觉等感官体验；2、情绪沉浸，沉浸于治愈系、陪伴向、强情感、强共鸣的娱乐内容 3、线下、实体沉浸，深入生活日常的主题性包裹，包括线下体验、实体收藏、旅游朝圣、生活用品衍生等',
  [CHINESE_NARRATIVE]: '新一代用户生长在中国快速崛起的时代，对与国家、制度和文化高度认同，且在贸易战、疫情等影响下热情更高。这也符合国家文化导向、地方人才引进等政策的引导。互联网技术则让传统文化展现形式更丰富有趣，促进了传统文化元素的发展。中式叙事趋势出现，年轻人期待在文娱消费中，看到更多、更精品，体现国家实力和本土特色的内容。这会产生一些表现，如：1、爱国情绪和题材成为创作的出发点和原则2、挖掘更多时代、地方、少数民族、风俗传说等小众元素（IP、题材、制作工艺、生活方式、器乐……）3、中式审美特点（画风、构图、视角、配色……）4、改编方向，如潮流化Mix、中式未来幻想、中西元素碰撞等',
  [HIGH_EFFICIENCY]: '网络时代的信息爆炸，让年轻人对信息的接受、处理速度更快，对漫长等待、无聊体验等方面的耐性下降。高效率趋势出现，年轻人从信息接收到表达方式，都追求精准和效率，在游戏里也要节省时间，直达兴奋点。这会产生一些表现，如：1、偏好简单爽、快上手、碎片化的体验 2、要求强新鲜感 3、更精明的认知决策形式（节省时间、金钱/性价比）4、时刻转化或叠加的体验 5、极致的标签化（消费设定、大纲文、代餐、白毛……）',
  [PURSUIT_OF_MEANING]: '在充斥着“拼搏上进”、“目标”和“追求“的大环境里，年轻人在压力和焦虑间，需要有抚慰心灵、感受生活的释放窗口。年轻人开始更多探索人生意义，不一定是成功、目标，而是“过好每一天”的小确幸，从生活的微小事物发现美好和意义，用日常生活的浪漫表达，消解日常无力感和寻求治愈。追求意义趋势出现，年轻人在娱乐消费上更注重品牌内容的价值感表达、仪式、氛围营造，偏好能带来自我探索的体验，和烟火气、日常浪漫形态。这会产生一些表现，如：1、立场经济，关注品牌立场、价值表达，追求仪式感，为普世价值买单。2、标准自定，通过定期内观、复盘，关注自我状态，自定义自我追求的意义。3、日常复魅，为平凡、低成本的日常生活主动构建意义，如寻找烟火气、挖掘无用美学、展现日常浪漫，主动挖掘细节美好、创造相关氛围',
  [PARTICIPATE_IN_CREATION]: '年轻人创作能力提升，创作既是个人表达的一种方式，也是可触及的成功路径之一。UGC平台、内容社交平台的搭建、技术工具升级，也让让创作、展示门槛更低。参与创造趋势出现，低门槛的参与和再创造已经泛滥，更高品质、高难度、高原创性的创作正在出现。这会产生一些表现，如：1、专业创作工具普及 2、创作者圈子、生态形成 3、基于用户共创的模式、经济出现游戏个性化功能升级；也要好聊、好造、好晒，输出素材、工具和平台能力；原作设计时就要考虑为用户留出参与接口；官民互动和运营时鼓励用户参与和创作；培养和引入核心“种子用户”，让专业观众参与创作过程；探索基于用户共创的商业模式。',
  [INDIVIDUALIZE]: '政治经济发展稳定，时代标签弱化。阶层分化，不同阶层年轻人的审美趣味、观念差异巨大。成长环境宽松，年轻人“生存”压力下降，但阶层封闭也让年轻人更难实现“成功”，因此他们不再追求单一成就，更关注“个人意志”，自己决定要成为什么样的人，过怎样的人生。个体化趋势出现，让年轻人在娱乐中更关注类型内容、个性化功能、自我探索和兴趣类体验。这会产生一些表现，如：1、类型内容崛起——垂类、细分赛道2、极致的个性化、定制功能3、自我探索，拓展人生边界4、为兴趣付费，主题式的生活（职业、情感、社交……）5、输赢成就重要性下降，更看重丰富有趣的过程体验6、自由度和多元目标追求，弱化“输”的感知',
  [RETRO_TREND]: '每当经济产生落差、科技发生巨变，掌握文化话语权的一代人，就会感到焦虑、不安，于是去怀念自己童年时代的美好记忆，从而在娱乐、时尚等各个领域兴起复古潮流。近现代世界兴起过四次比较典型的复古潮流，分别是50-70年代兴起的美式复古；80-90年代末兴起的阿美咔叽；10-15年兴起的蒸汽波/合成器浪潮；和18年至今兴起的复古未来主义。而目前在国内，中国本土文化的复古潮流也在兴起，如港风复古、地域怀旧、8090后童年怀旧等。',
};
