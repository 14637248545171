import React, { FC, ReactNode } from 'react';
import { Modal } from 'antd';
import dialogBg from '@/assets/images/dialogBg.png';
import styles from './index.module.less';

interface PlatformAgreementProps {
  visible: boolean,
}

const PlatformAgreement: FC<PlatformAgreementProps> = ({ visible }: PlatformAgreementProps) => {
  const str1 = '感谢您对游戏市场洞察系统的关注！';
  const str2 = '本平台所有内容均由IEG市场与用户研究部产出，基于IEG数据安全需要，本平台仅对IEG人员开放。';
  const str3 = '部分公开内容可在KM平台“互娱用研i-MUR”查阅。';
  const str4 = '如您的岗位与游戏业务高度相关，重要工作需要研究报告/市场用户数据，请联系danniepeng(彭丹妮)，谢谢！';

  if (!visible) return null;

  return (
    <Modal
      visible={visible}
      centered
      title={null}
      footer={null}
      forceRender
      modalRender={(modal: ReactNode) => (
        <div className={styles.dialog}>
          {modal}
        </div>
      )}
      bodyStyle={{
        width: '520px',
        height: '360px',
        backgroundColor: 'transparent',
        backgroundImage: `url(${dialogBg})`,
        backgroundSize: '556px 408px',
        backgroundPosition: '-18px -24px',
      }}
      maskStyle={{ zIndex: 1000000001 }}
      zIndex={1000000002}
    >
      <div className={styles.title}>权限关闭通知</div>
      <div className={styles.text}>
        <p>{str1}</p>
        <p>{str2}</p>
        <p>{str3}</p>
        <p>{str4}</p>
      </div>
    </Modal>
  );
};
export default PlatformAgreement;
