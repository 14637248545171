/* eslint-disable camelcase */
import React, { FC, useState } from 'react';
import {
  Form, Input, Button, message, Typography,
} from 'antd';
import { UserInfoContext } from '@/context';
import { applyPermission, getApprover } from '@/services/report-library';
import { getZoneReportApprover, applyZoneReportPermission } from '@/services/single-product/home';
import { useFetch } from '@/hooks';
import { CURRENT_LANGUAGE } from '@/i18n';
import { useTranslation } from 'react-i18next';
import { SuperiorDisplay } from '@/components';
import styles from './index.module.less';

const { TextArea } = Input;
const { Text } = Typography;

interface ApprovalFormProps {
  openid: string,
  successCallback: () => void,
  productId?: number,
}

const ApprovalForm: FC<ApprovalFormProps> = ({
  productId, successCallback, openid,
}: ApprovalFormProps) => {
  const { t } = useTranslation();
  const { info, isStaffUser } = UserInfoContext.useContainer();
  const [formLoading, setFormLoading] = useState(false);

  const { data } = useFetch(() => (productId ? getZoneReportApprover(productId, openid) : getApprover(openid)));
  const zhLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 22 },
  };

  const enLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const onFinish = async (values: any) => {
    setFormLoading(true);
    const result = productId
      ? await applyZoneReportPermission(productId, { ...values, openid })
      : await applyPermission({ ...values, openid });
    if (Object.keys(result).length) {
      successCallback();
    } else {
      message.info('已申请，提交失败！如有其他问题请找管理员~');
    }
    setFormLoading(false);
  };

  return (
    <>
      <h3 className={styles.subTitle}>
        {isStaffUser ? t('application_report_access') : t('report_access_application')}
      </h3>
      <section>
        <Form
          onFinish={onFinish} style={{ width: 1000 }}
          className={styles.approvalForm} {...CURRENT_LANGUAGE === 'en' ? enLayout : zhLayout}
        >
          <Form.Item label={t('process_type')}>
            {data && <SuperiorDisplay superiorData={data} isZone={!!productId} />}
          </Form.Item>
          <Form.Item
            label={t('application_reasons')}
            className={styles.reasonFormItem}
            required
          >
            <Form.Item
              name="reason"
              rules={[
                { required: true, message: t('application_reasons') },
                { min: 10, message: t('permission_reason_validate_tips', { minLength: 10, maxLength: 300 }) },
              ]}
            >
              <TextArea
                placeholder={isStaffUser ? '请输入您需要这份报告的原因，审批人收到后，会根据申请原因决定是否购买。' : t('reportReason')}
                maxLength={300}
              />
            </Form.Item>
          </Form.Item>
          {info && (
            <Form.Item
              label={t('applicant')}
            >
              <Text className={styles.formInput}>
                {info.user_id.split('@')[0]}
              </Text>
            </Form.Item>
          )}
          <Form.Item className={styles.submitFormItem}>
            <Button
              type="primary" htmlType="submit"
              className={styles.submitButton}
              loading={formLoading} size="large"
            >
              {t('submit')}
            </Button>
          </Form.Item>
        </Form>
      </section>
    </>
  );
};

export default ApprovalForm;
