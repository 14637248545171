import React, {
  FC, ReactNode, useEffect, useState,
} from 'react';
import { Button, Modal } from 'antd';
import { TipsModal } from '@/components';
import { useTranslation } from 'react-i18next';
import { UserBehaviorContext } from '@/context';
import dialogBg from '@/assets/images/dialog-bg.svg';
import styles from './index.module.less';

const SafeModal: FC = () => {
  const { t } = useTranslation();
  const {
    step, setStep, isFirst, isSubscribeFirst,
  } = UserBehaviorContext.useContainer();
  const [visible, setVisible] = useState<boolean>(false);
  const [tipsVisible, setTipsVisible] = useState<boolean>(false);

  const str1 = '如需因研究目的引用平台内的报告或数据内容，请务必注明出处为“IEG市场与用户研究部”，并在获得相关内容负责人同意后使用。';
  const str2 = '我们将协助您更准确地理解和应用这些报告或数据，以提高大家对研究产出二次分析的效率。对于未注明来源的引用、盗用、篡改以及其他侵犯著作权的行为，我们保留追究法律责任的权力。';
  const str3 = '请切勿以任何方式（包括但不限于复制、转载、拍照、录像、截图、网络爬虫技术等）擅自获取、发布、传播和使用本平台的内容用于其他用途或目的。如因对外传播触犯公司商业机密而引发的公关风险，个人须完全承担责任。';
  const str4 = '无论当事人是否在职，公司将根据《防止办公网内高危行为管理办法》和《阳光行为准则》对违规行为进行处理，并对情节严重者保留追究法律责任的权力。';

  useEffect(() => {
    if (step === 0) {
      setVisible(true);
    } else if (step === 1) {
      setTipsVisible(true);
    }
  }, [step]);

  return (
    <>
      <Modal
        visible={visible}
        centered
        closable={false}
        title={null}
        footer={null}
        forceRender
        modalRender={(modal: ReactNode) => (
          <div className={styles.modalRender}>
            {modal}
          </div>
        )}
        bodyStyle={{
          width: '1100px',
          height: '725px',
          backgroundColor: 'transparent',
          backgroundImage: `url(${dialogBg})`,
          backgroundSize: '1100px 725px',
          paddingTop: '60px',
        }}
        maskStyle={{ zIndex: 1000000001 }}
        zIndex={1000000002}
        wrapClassName={styles.wrap}
      >
        <div className={styles.title}>
          游戏市场洞察系统使用须知
        </div>
        <div className={styles.content}>
          <p>欢迎使用 i-MUR 平台！在您开始使用本平台前，请仔细阅读并遵守以下使用须知：</p>
          <p>1. 本平台（imur.woa.com）的所有内容均由 IEG 市场与用户研究部产出。我们拥有所有产出内容（包括但不限于报告、数据、文章、图片、图表、音视频等）的知识产权，该内容仅供腾讯内部学习交流使用。</p>
          <p>2. 本平台致力于集合部门在游戏市场与用户研究领域的研究成果，为您提供及时、便捷地获取游戏市场和用户变化趋势的途径。我们热忱欢迎您与我们探讨、了解市场和用户。如对某领域有合作需求，也请随时与我们沟通。</p>
          <p>{`3. ${str1}${str2}`}</p>
          <p>{`4. ${str3}${str4}`}</p>
          <p>感谢您的支持与合作，祝您在 i-MUR 平台上获得宝贵的知识与洞见！</p>
        </div>
        <Button
          onClick={() => {
            setVisible(false);
            if (isFirst) {
              setStep(1);
            } else if (isSubscribeFirst) {
              setStep(2);
            } else {
              setStep(-1);
            }
          }}
          className={styles.button}
        >
          {t('software_update_close')}
        </Button>
      </Modal>
      <TipsModal
        visible={tipsVisible}
        changeVisible={(v) => {
          setTipsVisible(v);
          if (isSubscribeFirst) {
            setStep(2);
          } else {
            setStep(-1);
          }
        }}
      />
    </>
  );
};

export default SafeModal;
