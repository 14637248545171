import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

export function getPresetLang(): string {
  if (sessionStorage.murLang) {
    return sessionStorage.murLang;
  }

  const langRE = /[?|&]lang=(\w+)/;
  const match = window.location.search.match(langRE);
  return match?.[1] ?? 'zh';
}

export function changeLanguage(lang: string) {
  sessionStorage.murLang = lang;
}

export const CURRENT_LANGUAGE = getPresetLang();

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    fallbackLng: 'zh',
    lng: CURRENT_LANGUAGE,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
