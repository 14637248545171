/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { UserGroupItemData } from '@/services/user-group';
import { ReportDetail, GroupItem } from '@/services/report-library-admin';
import moment from 'moment';

import configs from '@/configs';
import { reportFormDataTransform } from '@/utils/helper/form';

const BASE_USER_GROUP_NAME = configs('user-group.baseUserGroupName');
const PRIVACY_PERM = configs('menu.privacyPerm');
const COVER_REPORT_FONT_PAGE_TYPE = configs('report.coverReportFontPageType');
const UPLOAD_URL = configs('app.uploadUrl');
const formListInit = configs('report.formListInit');

export const INIT_TERMINAL = '移动';
export const INIT_INDUSTRY = '游戏';
export const INIT_TERMINAL_EN = 'Mobile';
export const INIT_INDUSTRY_EN = 'Games';
export const BASIC_REPORT_TYPE = 1;
export const REPORT_TYPE = 3;
export const BASIC_REPORT_LANGUAGE = 'zh';

export const initialValues = {
  type: BASIC_REPORT_TYPE,
  report_type: REPORT_TYPE,
  search_authority: PRIVACY_PERM,
  read_authority: PRIVACY_PERM,
  download_authority: PRIVACY_PERM,
  cover_pic_type: COVER_REPORT_FONT_PAGE_TYPE,
  lang: BASIC_REPORT_LANGUAGE,
};

export const CASCADED_DEFAULT_ID = 0;

interface UserValues {
  read_user?: string[],
  search_user?: string[],
  download_user?: string[],
  unBaseSearchGroup?: number[],
  unBaseReadGroup?: number[],
  unBaseDownloadGroup?: number[],
}

export function transformHistoryGroup(data: GroupItem[]) {
  const userData: UserValues = {};
  const searchType = data.find((item) => item.type === 1)?.data;
  const readType = data.find((item) => item.type === 2)?.data;
  const downLoadType = data.find((item) => item.type === 3)?.data;
  const searchGroup = searchType?.group;
  userData.unBaseSearchGroup = searchGroup?.length
    ? searchGroup.filter((item) => item.group_name !== BASE_USER_GROUP_NAME).map((item) => item.id) : [];
  const readGroup = readType?.group;
  userData.unBaseReadGroup = readGroup?.length
    ? readGroup.filter(
      (item) => item.group_name !== BASE_USER_GROUP_NAME,
    ).map((item) => (typeof item === 'object' ? item.id : item)) : [];
  const downLoadGroup = downLoadType?.group;
  userData.unBaseDownloadGroup = downLoadGroup?.length
    ? downLoadGroup.filter(
      (item) => item.group_name !== BASE_USER_GROUP_NAME,
    ).map((item) => (typeof item === 'object' ? item.id : item)) : [];
  userData.search_user = searchType?.user?.map((item: any) => item.user_id);
  userData.read_user = readType?.user?.map((item: any) => item.user_id);
  userData.download_user = downLoadType?.user?.map((item: any) => item.user_id);
  return userData;
}

export function transformInitialValues(data: ReportDetail) {
  const { search_group: searchGroup } = data;
  const unBaseSearchGroup = searchGroup.length
    ? searchGroup.filter((item) => item.group_name !== BASE_USER_GROUP_NAME).map((item) => item.id) : [];
  const { read_group: readGroup } = data;
  const unBaseReadGroup = readGroup.length
    ? readGroup.filter(
      (item) => item.group_name !== BASE_USER_GROUP_NAME,
    ).map((item) => (typeof item === 'object' ? item.id : item)) : [];
  const { download_group: downLoadGroup } = data;
  const unBaseDownloadGroup = downLoadGroup.length
    ? downLoadGroup.filter(
      (item) => item.group_name !== BASE_USER_GROUP_NAME,
    ).map((item) => (typeof item === 'object' ? item.id : item)) : [];

  const searchUser = data.search_user.map((item) => item.user_id);
  const readUser = data.read_user.map((item) => item.user_id);
  const downloadUser = data.download_user.map((item) => item.user_id);

  return {
    ...data,
    search_group: unBaseSearchGroup,
    read_group: unBaseReadGroup,
    download_group: unBaseDownloadGroup,
    cover_pic_type: data.cover_pic_type,
    research_type: Object.keys(data.research_type),
    application: Object.keys(data.application),
    category: Object.keys(data.category),
    industry: Object.keys(data.industry),
    crowd: Object.keys(data.crowd),
    area: Object.keys(data.area),
    terminal: Object.keys(data.terminal),
    search_user: searchUser,
    read_user: readUser,
    download_user: downloadUser,
    mid: data.mid || '',
  };
}

export function transformUpdateValues(userGroup: UserGroupItemData[] | null, values: any): FormData {
  const formData = new FormData();
  if (Array.isArray(values.mul_report_type) && values.mul_report_type?.length) {
    const arr = values.outClassification?.filter((item: any) => item > CASCADED_DEFAULT_ID);
    if (arr?.length) {
      values.ext_field = [
        ...arr,
        ...values.mul_report_type,
      ];
    } else {
      values.ext_field = [
        ...values.mul_report_type,
      ];
    }
  } else if (!Array.isArray(values.mul_report_type) && values.mul_report_type) {
    const arr = values.outClassification?.filter((item: any) => item > CASCADED_DEFAULT_ID);
    if (arr?.length) {
      values.ext_field = [
        ...arr,
        values.mul_report_type,
      ];
    } else {
      values.ext_field = [
        values.mul_report_type,
      ];
    }
  } else {
    values.ext_field = values.outClassification?.filter((item: any) => item > CASCADED_DEFAULT_ID);
  }
  values.ext_field = (values.ext_field && values.ext_field.length > 0) ? values.ext_field : [];
  Object.keys(formListInit).forEach((formItem) => {
    if (values[formItem] && values[formItem].length > 0) {
      values.ext_field = [...values.ext_field, ...values[formItem]];
    }
    delete values[formItem];
  });
  delete values.outClassification;
  delete values.mul_report_type;
  reportFormDataTransform(userGroup, formData, values);
  formData.set('output_at', moment(values.output_at).format('YYYY-MM-DD 00:00:00'));
  if (!formData.get('mul_lang')) {
    formData.delete('mul_lang');
  }
  if (!formData.get('mid')) {
    formData.delete('mid');
  }
  const file = formData.get('pdf_file');
  const fileArr = file?.toString().split('/');
  if (fileArr && fileArr.length > 0) {
    formData.set('pdf_file', `${UPLOAD_URL}/pdf/${fileArr[fileArr.length - 1]}`);
  }
  if (!formData.get('pdf_file')) {
    formData.delete('pdf_file');
  }
  if (typeof formData.get('cover_pic') === 'string') {
    formData.delete('cover_pic');
  }
  if (typeof formData.get('banner_pic') === 'string') {
    formData.delete('banner_pic');
  }

  return formData;
}
