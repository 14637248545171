const ANYONE_PERM = 1;
const SPECIAL_PERM = 2;
const PRIVACY_PERM = 3;
const INVALID_MENU_ID = -1;

const ADMIN_MENU_PERM = 'admin_menus';
const HOME_MENU_PERM = 'home_menus';

const PARENT_MENU = [
  '数据洞察',
  '市场用户数据',
  '海外专区',
];

const optionObj = {
  genres: '选择品类',
  markets: '选择区域',
  products: '选择游戏平台',
};

export default {
  directoryType: 1,

  urlType: 2,

  permissions: [{
    key: ANYONE_PERM,
    label: '任何人',
  },
  {
    key: SPECIAL_PERM,
    label: '指定部分',
  },
  {
    key: PRIVACY_PERM,
    label: '完全保密',
  }],

  anyonePerm: ANYONE_PERM,
  specialPerm: SPECIAL_PERM,
  privacyPerm: PRIVACY_PERM,

  adminMenuPerm: ADMIN_MENU_PERM,
  homeMenuPerm: HOME_MENU_PERM,

  invalidMenuId: INVALID_MENU_ID,

  parentMenu: PARENT_MENU,
  optionObj,
};
