import axios from 'axios';
import { message } from 'antd';
import history from './history';

const request = axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE,
  withCredentials: true,
  timeout: 10000,
});

request.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const { data } = error.response;
    console.log('%c json api errors message: ', 'color:#ff4d4f;font-size:18px;');
    console.table(data);
    const { errors } = data;
    if (errors?.status === 401) {
      // eslint-disable-next-line camelcase
      window.location.href = errors?.redirect_url;
    }
    if (errors?.status === 403 && errors.code === 10005) {
      history.push('/?subscribe=0');
    }
    if (errors?.status === 404 || errors?.status === 400) {
      history.push(`/exception/forbidden/${errors?.status}/${errors?.detail}`);
    }
    if (errors?.status === 500) {
      message.error('当前网络不佳，请稍后再试~');
    }
    return Promise.reject(data);
  },
);

export default request;
