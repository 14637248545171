/* eslint-disable camelcase */
import request from '@/utils/request';
import { TableBaseParams } from '@/configs/type';
import { WriterOrCharger, UserInfoData } from './response.type';

export interface User {
  created_at: string;
  headimg: string;
  id: number;
  updated_at: string;
  user_id: string;
  user_name: string;
}

export interface AdminReportListReportType {
  created_at: string;
  id: number,
  name: string,
  updated_at: string,
}

export interface AdminReportListItem {
  id: number;
  title: string;
  type: string;
  created_by: User;
  charge_by: Array<User>;
  write_by: Array<User>;
  search_authority: number;
  read_authority: number;
  report_type: AdminReportListReportType;
  openid: string,
  [x: string]: any,
}

export interface AdminReportList {
  data: AdminReportListItem[] | null;
  total: number;
  last_page: number;
}

export interface ReportListParams extends TableBaseParams {
  title?: string,
  charge_by?: string,
  write_by?: string,
  classified_id?: []
}

export async function getAdminReportList(params: ReportListParams): Promise<AdminReportList> {
  const res = await request.post('/api/adminapi/report/list', params);
  return res.data;
}

export async function deleteReport(rid: (number | string)[]) {
  await request.delete('/api/adminapi/report/del', { data: { rid } });
  return true;
}

interface AuthGroup {
  id: number,
  created_at: string,
  group_name: string,
  updated_at: string,
}

export interface ReportBaseDetail {
  id?: number,
  mid?: string,
  title: string,
  introduction?: string,
  tag: string[],
  method: string[],
  charge_by: WriterOrCharger[],
  search_authority: number,
  read_authority: number,
  created_at?: string,
  output_at: string,
  pdf_file?: File,
  file_path?: string,
  write_by: WriterOrCharger[],
  cover_pic?: string,
  banner_pic?: string,
  cover_pic_type: number,
  type: { name: string } | null,
  relegation: { name: string } | null,
  keep_eye: number,
}

export interface ClassificationItem {
  [key: number]: string,
}

export interface ReportDetail extends ReportBaseDetail {
  search_group: AuthGroup[],
  search_user: UserInfoData[],
  read_group: AuthGroup[],
  read_user: UserInfoData[],
  download_group: AuthGroup[],
  download_user: UserInfoData[],
  research_type: ClassificationItem | []
  application: ClassificationItem | []
  category: ClassificationItem | []
  industry: ClassificationItem | []
  crowd: ClassificationItem | []
  area: ClassificationItem | []
  terminal: ClassificationItem | [],
  created_by: User[],
}

interface GroupItemDetail {
  group: AuthGroup[],
  user: UserInfoData[],
}

export interface GroupItem {
  type: number,
  data: GroupItemDetail
}

export async function getReportEditDetail(rid: string): Promise<ReportDetail> {
  const res = await request.get(`/api/adminapi/report/editPage/${rid}`);
  return res.data;
}

export async function getReportGroupDetail(rid: string): Promise<GroupItem[]> {
  const res = await request.get(`/api/adminapi/report/authLog/${rid}`);
  return res.data;
}

export async function updateReport(payload: FormData): Promise<number | boolean> {
  const res = await request.post('/api/adminapi/report/edit', payload, { timeout: 0 });

  return res.data;
}

export interface ReportRelegation {
  id: number,
  name: string,
  type: number,
}

export async function getRelegation(reportType: number): Promise<ReportRelegation[]> {
  const res = await request.get('/api/commonapi/report/relegation', { params: { typeId: reportType } });

  return res.data;
}

type ReportType = ReportRelegation;

export async function getType(): Promise<ReportType[]> {
  const res = await request.get('/api/commonapi/report/type');

  return res.data;
}

interface UnlockMailParams {
  rid: string
}

export async function unlockMail(params: UnlockMailParams): Promise<boolean> {
  const res = await request.post('/api/adminapi/report/unlock', params);

  return res.data;
}
export interface Credentials {
  sessionToken: string,
  tmpSecretId: string,
  tmpSecretKey: string
}
export interface COSRequest {
  expiredTime: number,
  expiration?: number,
  credentials: Credentials,
  requestId?: string,
  startTime: number,
  bucket: string,
  region: string,
}

export async function getReportCosAuth(szid?: number): Promise<COSRequest> {
  if (szid) {
    const res = await request.get(`/api/szadmin/report/cosTmpKey?szid=${szid}`);
    return res.data;
  }
  const res = await request.get('/api/adminapi/report/cosTmpKey');
  return res.data;
}

export async function getCos(): Promise<COSRequest> {
  const res = await request.get('/api/adminapi/data/cosTmpKey');
  return res.data;
}

export interface ProjectGroup {
  project_no: string,
  project_name: string,
  applicant: string,
  project_participants: { eng_name: string, chn_name: string }[],
}

const arr = [
  'https://p.imur.woa.com/public-api/related_projects',
  'https://p-test.imur.woa.com/public-api/related_projects',
];

export async function getProjectGroup(name?: string): Promise<ProjectGroup[]> {
  const url = window.location.host.includes('test') ? arr[1] : arr[0];
  const params = name === '' ? { limit: 100 } : { project_name: name, limit: 100 };
  const res = await request({
    method: 'GET',
    url,
    params,
  });
  return res.data;
}

export async function getTemporaryId(url: string) {
  const res = await request({
    method: 'POST',
    url: '/api/adminapi/report/simpleEdit',
    params: {
      pdf_file: url,
    },
  });
  return res.data;
}

export async function getSzTemporaryId(url: string, szid: number) {
  const res = await request({
    method: 'POST',
    url: '/api/szadmin/report/simpleEdit',
    params: {
      pdf_file: url,
    },
    headers: {
      szid,
    },
  });
  return res.data;
}

export interface ProgressItem {
  id: number,
  sort: number,
  images: string,
}

export interface ProgressProps {
  progress: number,
  data?: ProgressItem[],
}

export async function getProgress(rid: number, type: number): Promise<ProgressProps> {
  const res = await request.get(`/api/adminapi/report/preview?rid=${rid}&type=${type}`);
  return res.data;
}

export async function getSzProgress(
  rid: number, type: number, szid: number,
): Promise<ProgressProps> {
  const res = await request.get(`/api/szadmin/report/preview?rid=${rid}&type=${type}&szid=${szid}`);
  return res.data;
}

export interface TreeNodeProps {
  name: string,
  key: string,
  anchor?: number,
  children?: TreeNodeProps[],
}

export const mapTreeDataError = (node: TreeNodeProps[]): string[] => {
  let list: string[] = [];
  node.forEach((item) => {
    if (!item.anchor && item.key !== '0') {
      list.push(item.key);
    }
    if (item.children?.length) {
      const res = mapTreeDataError(item.children);
      if (res?.length) {
        list = [...list, ...res];
      }
    }
  });
  return list;
};

export const mapTreeDataExpand = (node: TreeNodeProps[]): string[] => {
  let list: string[] = [];
  node.forEach((item) => {
    if (item.children?.length) {
      list.push(item.key);
      const res = mapTreeDataExpand(item.children);
      if (res?.length) {
        list = [...list, ...res];
      }
    }
  });
  return list;
};

export const mapTreeDataUpload = (node: TreeNodeProps[], rid: number): any => node.map((item) => ({
  name: item.name,
  anchor: item.anchor,
  rid,
  type: item.children?.length ? 1 : 2,
  children: mapTreeDataUpload(item.children || [], rid),
}));

export const mapTreeDataAddKey = (node: TreeNodeProps[], key: string): any => node.map((item, i) => ({
  name: item.name,
  anchor: item.anchor,
  key: key === 'default' ? '0' : `${key}-${i}`,
  children: mapTreeDataAddKey(item.children || [], key === 'default' ? '0' : `${key}-${i}`),
}));

export const mapTreeDataByKey = (node: TreeNodeProps[], key: string, anchor?: number): any => node.map((item) => ({
  ...item,
  anchor: item.key === key ? anchor : item.anchor,
  children: mapTreeDataByKey(item.children || [], key, anchor),
}));

export const mapTreeByKey = (
  key: string, node: TreeNodeProps, index: number,
): any => {
  const res = key.split('-');
  if (node.key === key) {
    return node;
  }
  const item = node.children?.filter((ele) => ele.key.split('-')[index] === res[index]);
  if (item?.length) {
    return mapTreeByKey(key, item[0], index + 1);
  }
  return null;
};
