/* eslint-disable camelcase */
import request from '@/utils/request';
import { LabelItem } from '@/services/common';

type ReportType = 0;
type CulturalSignalsType = 1;

export type LabelType = ReportType | CulturalSignalsType;

export interface LabelGroupDataItem {
  id: number,
  field_type: number,
  field_name: string,
  value: LabelItem[]
}

export async function getLabelList(type: LabelType): Promise<LabelGroupDataItem[]> {
  const res = await request.get(`/api/commonapi/classification/getExt?type=${type}&sys_lang=zh`);

  return res.data;
}

export interface LabelGroupPayload {
  id?: number,
  name: string,
  field_type: number,
  ext_type: LabelType,
  value?: string,
  classified_id?: number,
}

export async function updateLabelGroup(payload: LabelGroupPayload): Promise<boolean> {
  const res = await request.post('/api/adminapi/classification/edit', payload);

  return res.data;
}

export interface LabelPayload {
  ext_type: LabelType,
  old_ext_id: number,
  new_ext_id: number,
  old_classified_id: number,
  pre_id: number,
}

export async function updateLabel(payload: LabelPayload) {
  const res = await request.post('/api/adminapi/classification/dragCl', payload);

  return res.data;
}

export interface LabelGroupSortPayload {
  ext_type: LabelType,
  sort_ext_id: number,
  pre_id: number,
}

export async function updateLabelGroupSort(payload: LabelGroupSortPayload) {
  const res = await request.post('/api/adminapi/classification/dragExt', payload);

  return res.data;
}

export async function delLabelGroup(ids: number[]) {
  const res = await request.delete('/api/adminapi/classification/del', { data: { id: ids } });

  return res.data;
}

export interface ReportLabelPayload {
  type: string,
  classified_id?: number,
  name?: string,
  pre_id?: number,
}

export async function updateReportLabel(payload: ReportLabelPayload): Promise<boolean> {
  const res = await request.post('/api/adminapi/classification/reportBasicClEdit', payload);

  return res.data;
}
