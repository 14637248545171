/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, {
  FC, useEffect, useState,
  createRef, useCallback, useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import {
  Alert, Button, message, Modal,
} from 'antd';
import { PhotoSlider } from 'react-photo-view';
import { ReportPreviewData, getReportImages } from '@/services/report-library';
import { getZoneReportImages } from '@/services/single-product/home';
import styles from '../index.module.less';
import 'react-photo-view/dist/index.css';

interface Props {
  data: ReportPreviewData,
  anchor: number,
  productId?: number,
  openid: string|number,
}

interface DataSource {
  images: string,
  id: number,
}

const FullReport: FC<Props> = ({ data, productId, anchor }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initPageSize = 5;
  const initDataSource = data.preview_pic
    ? data.preview_pic.map((item, index) => ({ images: item, id: index * -1 }))
    : [];
  const loadingRef = createRef<HTMLDivElement>();
  const refSort = useRef<number>(0);
  const refSortInit = useRef<boolean>(false);
  const refPage = useRef<number>(1);

  const [sort, setSort] = useState<number>(Number(searchParams.get('sort') || '0'));
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [tipsVisible, setTipsVisible] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<DataSource[]>(searchParams.get('sort') ? [] : [...initDataSource]);
  const [previewIndex, setPreviewIndex] = useState<number>(0);
  const [previewImages, setPreviewImages] = useState<string[]>(data.preview_pic || []);
  const [loadMore, setLoadMore] = useState<boolean>(!!sort);
  const [sortLoading, setSortLoading] = useState<boolean>(!!sort);
  const [sortPercent, setSortPercent] = useState<string>('0%');
  const [initSortImg, setInitSortImg] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const getDataSource = async (id: string, pageNo: number, pageSize: number) => {
    setLoading(true);
    let res;
    if (productId) {
      res = await getZoneReportImages(productId, id, pageNo, pageSize);
    } else {
      res = await getReportImages(id, pageNo, pageSize);
    }
    if (res?.length) {
      setLoading(false);
      const list = [...dataSource, ...res];
      setDataSource(list);
      setPreviewImages(list.map((item) => item.images || ''));
    } else {
      setHasMore(false);
    }
  };

  const handleDataSource = async (paramPageNo: number, paramPageSize: number) => {
    await getDataSource(data?.openid as string, paramPageNo, paramPageSize);
  };

  useEffect(() => {
    if (sort) {
      refSort.current = 0;
      setSortPercent('0%');
      setSortLoading(true);
    }
  }, [sort]);

  useEffect(() => {
    if (sortLoading) {
      const num = ((Number((sort / 10).toFixed(0)) + 1) * 10);
      handleDataSource(1, num);
      setPage(num / 5);
      refPage.current = num / 5;
    } else {
      refSortInit.current = true;
    }
  }, [sortLoading]);

  useEffect(() => {
    if (refSortInit.current && page > 1) {
      handleDataSource(page, initPageSize);
    }
  }, [page]);

  useEffect(() => {
    if (!sortLoading) {
      refPage.current = page;
    }
  }, [dataSource]);

  useEffect(() => {
    if (anchor) {
      if (anchor > 5) {
        setLoadMore(true);
      }
      setSort(anchor);
      if (anchor > dataSource.length) {
        handleDataSource(1, (Number((anchor / 10).toFixed(0)) + 1) * 10);
        setPage(((Number((anchor / 10).toFixed(0)) + 1) * 10) / 5);
      }
    }
  }, [anchor]);

  const handlePreviewImageChange = async (index: number) => {
    if (dataSource.length - 1 === index) {
      if (hasMore) {
        setPage(page + 1);
      }
      if (!loadMore) {
        setLoadMore(true);
      }
    }
    if (!previewImages[index] && dataSource) {
      previewImages[index] = dataSource[index].images;
      setPreviewImages(previewImages);
    }
    setPreviewIndex(index);
  };

  const handleImgClick = (item: string, index: number) => {
    previewImages[index] = item;
    setPreviewImages(previewImages);
    setPreviewIndex(index);
    setVisible(true);
  };

  const handleLoadMoreClick = async () => {
    setPage(page + 1);
    setLoadMore(true);
  };

  const scrollFn = useCallback(() => {
    const onPullUpHeight = loadingRef!.current?.clientHeight || 0;
    const documentHeight = document.documentElement.clientHeight;
    const documentTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (onPullUpHeight > documentHeight
      && onPullUpHeight < documentHeight + documentTop - 150
      && !loading && loadMore && hasMore && refPage.current === page
    ) {
      setPage(page + 1);
    }
  }, [loading, loadingRef]);

  useEffect(() => {
    window.addEventListener('scroll', scrollFn);
    return () => {
      window.removeEventListener('scroll', scrollFn);
    };
  }, [scrollFn]);

  const keydownFn = useCallback((e) => {
    if (!hasMore && e.keyCode === 39
      && !tipsVisible && previewIndex === dataSource.length - 1) {
      message.warn('已经到底啦~');
      setTipsVisible(true);
    }
  }, [hasMore, tipsVisible, previewIndex, dataSource]);

  useEffect(() => {
    window.addEventListener('keydown', keydownFn);
    return () => {
      window.removeEventListener('keydown', keydownFn);
    };
  }, [scrollFn]);

  useEffect(() => {
    if (initSortImg) {
      const img = document.getElementById(`preview-${sort}`);
      if (img) img.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      setSortPercent('100%');
      setSortLoading(false);
    }
  }, [initSortImg]);

  return (
    <div ref={loadingRef}>
      {!data.preview_pic && (
        <Alert
          message={t('tips')}
          description={t('wait_generating_tips')}
          type="info"
          showIcon
        />
      )}
      {data?.preview_pic && (
        <section className={`${styles.section} ${styles.previewSection}`}>
          {dataSource.map(((item, index) => (
            <img
              key={`${item.id}img`}
              className={styles.previewImg}
              src={item.images}
              alt=""
              id={`preview-${index + 1}`}
              onClick={() => handleImgClick(item.images, index)}
              onLoad={() => {
                if (sort && index <= sort) {
                  refSort.current += 1;
                  if (refSort.current === sort) {
                    setInitSortImg(true);
                    setSortPercent('99%...');
                  } else {
                    setSortPercent(`${((refSort.current / Number(sort)) * 100).toFixed(0)}%...`);
                  }
                }
              }}
            />
          )))}
          <PhotoSlider
            images={previewImages.map((item) => ({ src: item }))}
            visible={visible}
            onClose={() => {
              setVisible(false);
              setTipsVisible(false);
            }}
            index={previewIndex}
            onIndexChange={(index: number) => handlePreviewImageChange(index)}
            imageClassName={styles.previewPhoto}
            maskClassName={styles.mask}
          />
          {!loadMore && data.read_auth === 1 && (
            <Button
              className={styles.loadingMore}
              onClick={handleLoadMoreClick}
            >
              <div>
                <span>{t('view_full_report')}</span>
                <DownOutlined style={{ fontSize: 10, marginLeft: 6 }} />
              </div>
              <div className={styles.loadingMoreTips}>
                （禁止copy及大范围传播）
              </div>
            </Button>
          )}
        </section>
      )}
      <Modal
        visible={sortLoading}
        footer={false}
        maskClosable={false}
        mask={false}
        closable={false}
        centered
        width={300}
        bodyStyle={{
          padding: '24px 52px',
          height: '152px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        wrapClassName={styles.sortLoad}
      >
        <p className={styles.sortLoadTitle} />
        <p className={styles.sortLoadText}>
          {sortPercent}
        </p>
        <p
          className={styles.sortLoadText}
          style={{ opacity: 0.65 }}
        >
          正加载到原文位置中，请稍等
        </p>
      </Modal>
    </div>
  );
};

export default FullReport;
