const onHangUp = () => {
  let timer: NodeJS.Timeout;
  const reload = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      window.location.reload();
    }, 21600000); // 6hours
  };
  document.onmousemove = reload;
  document.onkeydown = reload;
  document.onvisibilitychange = () => {
    if (document.visibilityState === 'hidden') {
      reload();
    }
  };
};

export default onHangUp;
