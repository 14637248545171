import React, { forwardRef } from 'react';
import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

type RefType = any;

interface TableSearchProps {
  onSearch?: (selectedKeys: any) => void,
  onReset?: () => void,
  setSelectedKeys: (array: [] | [string | number]) => void,
  selectedKeys: [string | number],
  clearFilters: () => void,
  confirm: () => void,
}

const TableSearch = forwardRef<RefType, TableSearchProps>(({
  onSearch, onReset, setSelectedKeys, selectedKeys, clearFilters, confirm,
}: TableSearchProps, ref: RefType) => {
  const handleReset = () => {
    if (onReset) onReset();
    clearFilters();
  };
  const handleSearch = (selectKeys: any[]) => {
    if (!selectKeys.toString().trim()) {
      handleReset();
      return;
    }
    if (onSearch) onSearch(selectKeys);
    confirm();
  };

  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={ref}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          搜索
        </Button>
        <Button
          onClick={() => handleReset()} size="small"
          style={{ width: 90 }}
        >
          重置
        </Button>
      </Space>
    </div>
  );
});

export default TableSearch;
