const usingProductStatus = 1;
const disabledProductStatus = 0;

const singleSelectType = 0;
const multipleSelectType = 1;
const cascadedSelectType = 2;

const escapeCharacter = '~!~';

const SPECIAL_PRODUCT_NAME = process.env.REACT_APP_SPECIAL_PRODUCT;
const SPECIAL_LABEL = 'untapped';

export interface SelectType {
  label: string,
  value: number,
}

const selectTypes: SelectType[] = [
  { label: '下拉单选', value: singleSelectType },
  { label: '下拉多选', value: multipleSelectType },
  { label: '级联单选', value: cascadedSelectType },
];

export default {
  usingProductStatus,
  disabledProductStatus,
  singleSelectType,
  multipleSelectType,
  cascadedSelectType,
  selectTypes,
  escapeCharacter,
  specialProductName: SPECIAL_PRODUCT_NAME,
  specialLabel: SPECIAL_LABEL,
};
