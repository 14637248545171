import React, {
  FC, useState, useEffect,
} from 'react';
import classNames from 'classnames';
import styles from './index.module.less';

interface IframeContainerProps {
  url: string,
  style?: React.CSSProperties,
}

const tableauState = ['tableau.listening', 'tableau.responsive', 'tableau.completed'];

const IframeContainer: FC<IframeContainerProps> = ({ url, style }: IframeContainerProps) => {
  const [containerHeight, updateContainerHeight] = useState(0);
  function handlePostMessage(event: { data: string; }) {
    if (event.data && typeof event.data === 'string') {
      try {
        const data = JSON.parse(event.data);

        if (data?.height) {
        // iframe子页面传的高度存在误差，多预留25px的高度
          updateContainerHeight(data.height + 24);
        }
      } catch {
        // tableau单独处理
        if (!tableauState.includes(event.data)) {
          const height = Number(event.data.split('maxHeight')?.[1]?.split(':')?.[1]?.split(',')?.[0]);
          if (!Number.isNaN(height)) {
          // iframe子页面传的高度存在误差，多预留55px的高度
            updateContainerHeight(height + 51);
          }
        }
      }
    }
  }

  window.addEventListener('message', handlePostMessage);

  useEffect(() => {
    updateContainerHeight(0);
    window.removeEventListener('message', handlePostMessage);
  }, [url]);

  // 组件卸载阶段，销毁event listen
  useEffect(() => function cleanup() {
    window.removeEventListener('message', handlePostMessage);
  }, []);

  return (
    <iframe
      src={url}
      title={url}
      width="100%"
      height={containerHeight ? `${containerHeight}px` : ''}
      frameBorder="0"
      className={classNames(styles.container, 'iframe-container')}
      style={style}
    />
  );
};

export default IframeContainer;
