import React, { FC } from 'react';
import logo from 'assets/images/logoZH.svg';
import classnames from 'classnames';
import styles from './index.module.less';

interface LogoProps {
  className?: string,
}

const Logo: FC<LogoProps> = ({ className }: LogoProps) => (
  <div className={classnames(styles.navLogoContainer, className)}>
    <a href="/single-product/dashboard" className="nav-logo zh-nav-logo">
      <img src={logo} alt="i-mur洞察平台" />
    </a>
  </div>
);

export default Logo;
