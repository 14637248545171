import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'antd';
import styles from './index.module.less';

interface Props {
  visible: boolean,
  changeVisible: (v: boolean) => void,
}

const str1 = '说明：基于中台服务市场化的管理需要，MUR洞察平台推出免费版及订阅版，订阅账号的申请按业务独立财务单元或经营管理汇报单位设置（工作室/部门），';
const str2 = '请申请订阅的业务团队遵守中台知识产权及研究成果的使用管理规定，勿私自对外传播转发扩散给公司外部其他团队。业务开通订阅代表同意遵守以上规范，洞察平台也将通过各种方式检测使用行为并落实管理规范。';

const TipsModal: FC<Props> = ({ visible, changeVisible }: Props) => (
  <Modal
    visible={visible}
    centered
    title={null}
    footer={null}
    forceRender
    modalRender={(modal: ReactNode) => (
      <div className={styles.tipsModal}>
        {modal}
      </div>
    )}
    bodyStyle={{
      width: '500px',
      height: '288px',
    }}
    maskStyle={{ zIndex: 1000000001 }}
    zIndex={1000000002}
  >
    <div className={styles.title}>提示</div>
    <div className={styles.textContent}>
      <div className={styles.text}>
        {`${str1}${str2}`}
      </div>
    </div>
    <Button
      type="primary"
      onClick={() => {
        changeVisible(false);
      }}
      className={styles.button}
    >
      知道了
    </Button>
  </Modal>
);

export default TipsModal;
