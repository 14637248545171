/* eslint-disable camelcase */
import React, { FC } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserInfoContext, BeaconContext } from '@/context';
import { SmileTwoTone, FrownTwoTone } from '@ant-design/icons';
import styles from './index.module.less';

interface Props {
  type: string,
  keyword: string,
  callBack?: (v: string) => void,
}

const tabObj: any = {
  研究报告: 'report',
  报告全文: 'text',
  数据报表: 'data',
  标签页: 'tag',
  报告作者: 'author',
};

const ResultSatisfaction: FC<Props> = ({ type, keyword, callBack }: Props) => {
  const { t } = useTranslation();
  const { info } = UserInfoContext.useContainer();
  const { beacon } = BeaconContext.useContainer();

  const onClick = (id: string) => {
    beacon.onDirectUserAction(
      'click_search_results_satisfaction_button',
      {
        user_id: info?.user_id,
        keyword,
        button_id: id === t('result_yes') ? 'yes' : 'no',
        type_id: tabObj[type],
      },
    );
    if (callBack) callBack(id === t('result_yes') ? 'true' : 'false');
    message.success(t('submitted'));
  };

  return (
    <div className={styles.outside}>
      <div className={styles.inside}>
        <div className={styles.text}>{t('result_want')}</div>
        <div className={styles.yes} onClick={() => onClick(t('result_yes'))}>
          <SmileTwoTone twoToneColor="#1890FF" />
          <span>{t('result_yes')}</span>
        </div>
        <div className={styles.no} onClick={() => onClick(t('result_no'))}>
          <FrownTwoTone twoToneColor="#A8071A" />
          <span>{t('result_no')}</span>
        </div>
      </div>
    </div>
  );
};

export default ResultSatisfaction;
