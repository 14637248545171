import React, { FC, useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import _ from 'lodash';
import { StaffData } from '@/services/common';
import { useFetch } from '@/hooks';

const { Option } = Select;

const renderStaffOption = (optionList: StaffData[]) => optionList.map((item) => (
  <Option
    key={item.name} value={item.name}
  >
    {item.name}
  </Option>
));

interface StaffSelectProps {
  mode?: 'multiple' | 'tags',
  size?: number,
  style?: object,
  initialValue?: string,
  placeholder?: string,
  renderChildrenFn?: () => void,
  disabled?: boolean,
  serviceFn: (staffWord: string, size: number) => Promise<StaffData[]>,
  className?: string,
  onChange?: (value: string) => void,
}

const defaultStyle = {
  width: '100%',
};

const defaultSize = 10;

const StaffSelect: FC<StaffSelectProps> = ({
  size, style, initialValue,
  placeholder = '请输入组织名称', renderChildrenFn, disabled, serviceFn, className, onChange,
}: StaffSelectProps) => {
  const [staffWord, setStaffWord] = useState('');
  const [value, setValue] = useState<string>();
  const { data, loading } = useFetch(
    () => serviceFn(staffWord, size || defaultSize), { depends: [staffWord], interrupt: staffWord.length === 0 },
  );

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const handleChange = (staffName: string) => {
    setValue(staffName);
    if (onChange) {
      onChange(staffName);
    }
  };

  const children = () => (renderChildrenFn ? renderChildrenFn() : null);

  return (
    <Select
      value={value}
      onSearch={_.debounce(setStaffWord, 500)}
      notFoundContent={loading ? <Spin size="small" /> : null}
      filterOption={false}
      disabled={disabled}
      placeholder={placeholder}
      style={{ ...defaultStyle, ...style }}
      className={className}
      autoClearSearchValue
      showSearch
      onChange={handleChange}
    >
      {children()}
      {
        data && renderStaffOption(data)
      }
    </Select>
  );
};

export default StaffSelect;
