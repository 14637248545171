/* eslint-disable camelcase */
import React, { FC } from 'react';
import { SuperiorData } from '@/services/common';
import progressImg from '@/assets/images/progress.png';
import { useTranslation } from 'react-i18next';
import { UserInfoContext } from '@/context';
import styles from './index.module.less';

interface SuperiorDisplayProps {
  superiorData: SuperiorData,
  isZone?: boolean
  isData?: boolean
}

const SuperiorDisplay: FC<SuperiorDisplayProps> = ({ superiorData, isZone, isData = false }: SuperiorDisplayProps) => {
  const { stream } = superiorData;
  const { t } = useTranslation();
  const { isStaffUser } = UserInfoContext.useContainer();
  let approverLabelArr = isStaffUser ? ['企业管理员']
    : [t('direct_manager'), t('higher_level_manager'), isData ? t('lead_data_contact') : t('lead_report_contact')];
  const approverLength = 3;
  if (isZone) {
    approverLabelArr = ['专区管理员'];
  }

  return (
    <>
      {stream.map(
        (item, index) => (
          <>
            <span className={styles.tag} key={item.user_id}>
              {stream.length === 2 && index === 1 ? approverLabelArr[index + 1] : approverLabelArr[index]}
              (
              {item.user_id.split('@')[0]}
              )
            </span>
            {(index < approverLength - 1) && (index < stream.length - 1) ? (
              <img
                className={styles.progress} src={progressImg}
                alt="progress"
              />
            ) : null}
          </>
        ),
      )}
    </>
  );
};

export default SuperiorDisplay;
