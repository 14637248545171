import React, { FC, useState } from 'react';
import { Input, Form, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import RichTextEditor from '@/components/report/rich-text-editor';
import styles from './index.module.less';

const BaseInfo: FC = () => {
  const [richError, setRichError] = useState<boolean>(false);
  return (
    <>
      <Form.Item
        label="报告标题"
        required
        className={styles.formWrapper}
      >
        <Form.Item
          name="title" className={styles.tipsFormItem}
          rules={[{ required: true, message: '请输入报告标题!' }, {
            max: 144,
            message: '最多144个字符',
          }]}
        >
          <Input
            placeholder="请输入报告标题，最多 144 个字"
          />
        </Form.Item>
        <Tooltip
          placement="right" title="PPT首页报告名，注意删除“【i-MUR+模块名】、完整版、分享版、V5、日期”等无关信息"
        >
          <ExclamationCircleOutlined className={styles.tipsIcon} />
        </Tooltip>
      </Form.Item>
      <Form.Item
        label="报告简介"
        className={styles.formWrapper}
        required
      >
        <Form.Item
          name="introduction"
          className={styles.tipsFormItem}
          rules={[
            {
              validator(_, value: string) {
                if (!value?.replace(/<[^>]+>/ig, '')) {
                  setRichError(true);
                  return Promise.reject(new Error('请输入报告简介!'));
                }
                if (value?.replace(/<[^>]+>/ig, '')?.length > 5000) {
                  setRichError(true);
                  return Promise.reject(new Error('报告简介最多为5000个字符!'));
                }
                setRichError(false);
                return Promise.resolve();
              },
            },
          ]}
        >
          <RichTextEditor
            placeholder="报告主要内容，可以是：1.报告摘要：2.主要章节标题；3.解答的核心问题，任意一种；为方便用户快速了解报告内容，建议详细填写。"
            richError={richError}
            contentStyle={{ height: '310px' }}
          />
        </Form.Item>
        <Tooltip
          placement="right"
          title="报告主要内容，可以是：1. 报告摘要；2. 主要章节标题；3. 解答的核心问题，任意一种"
        >
          <ExclamationCircleOutlined
            className={styles.tipsIcon}
            style={{ verticalAlign: 'top', paddingTop: '344px' }}
          />
        </Tooltip>
      </Form.Item>
    </>
  );
};

export default BaseInfo;
