import React, { FC } from 'react';
import { Layout, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { UserInfoContext, HomeMenuContext } from '@/context';
import logo from 'assets/images/logoZH.svg';
import classnames from 'classnames';
import NavAvatar from '../nav-avatar';
import styles from './index.module.less';

const { Header } = Layout;

const AdminHeader: FC = () => {
  const { info, isPayingUsers } = UserInfoContext.useContainer();
  const { menu } = HomeMenuContext.useContainer();

  return (
    <Header className={classnames(styles.header, styles.adminHeader)}>
      <a
        href="/" className={classnames(
          'nav-logo',
          {
            'zh-nav-logo': !isPayingUsers,
            'zh-nav-logo-pay': isPayingUsers,
          },
        )}
      >
        <div style={{ position: 'relative' }}>
          <img src={logo} alt="i-mur洞察平台" />
          {isPayingUsers && <span>(订阅版)</span>}
        </div>
      </a>
      <Menu theme="dark" mode="horizontal">
        <Menu.Item key="/">
          <NavLink to="/">
            <span>首页</span>
          </NavLink>
        </Menu.Item>
        {menu?.children.map((item) => (
          <Menu.Item key={item.data.path}>
            <NavLink to={item.data.path}>
              <span>{item.name}</span>
            </NavLink>
          </Menu.Item>
        ))}
        <Menu.Item key="/personal-center">
          <NavLink to="/personal-center">
            <span>个人中心</span>
          </NavLink>
        </Menu.Item>
      </Menu>
      <div className="nav-right-action">
        <NavAvatar userInfo={info} />
      </div>
    </Header>
  );
};

export default AdminHeader;
