/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC, useEffect, useState, MouseEvent,
} from 'react';
import { useLocation } from 'react-router-dom';
import { Layout, message } from 'antd';
import classnames from 'classnames';
import { AdminMenuData } from '@/services/common';
import {
  SingleProductInfoContext, MenuUrlContext, BeaconContext, UserInfoContext,
} from '@/context';
import history from '@/utils/history';
import { MenuTreeData } from '@/services/menu';
import { ZoneItemData } from '@/services/single-product';
import defaultLogo from '@/assets/images/product_logo.png';
import { useFetch } from '@/hooks';
import { getProductMenuTree, getZoneBaseMenu } from '@/services/single-product/home';
import { getDefaultOpenKeys } from '@/utils/helper/base';
import configs from '@/configs';
import LeftMenu from '../left-menu';
import styles from './index.module.less';
import Logo from './logo';

const SPECIAL_PRODUCT_NAME = configs('single-product.specialProductName');
const SPECIAL_LABEL = configs('single-product.specialLabel');

const { Sider } = Layout;

interface MenuData {
  item: AdminMenuData | MenuTreeData,
  openKeys: string[],
  id: number,
}

const specialMenu = {
  children: [{
    children: [],
    data: {
      type: SPECIAL_LABEL,
    },
    icon: '',
    id: 2,
    name: '英雄粉丝',
    parent_id: 1,
    perm: 1,
    scope: SPECIAL_PRODUCT_NAME,
    type: 1,
  }, {
    children: [],
    data: { type: SPECIAL_LABEL },
    icon: '',
    id: 3,
    name: '年轻人洞察',
    parent_id: 1,
    perm: 1,
    scope: SPECIAL_PRODUCT_NAME,
    type: 1,
  }],
  data: {},
  icon: 'icon-pvp',
  id: 1,
  name: '洞察专区',
  parent_id: null,
  perm: 1,
  scope: SPECIAL_PRODUCT_NAME,
  type: 1,
};

const HomeSider: FC = () => {
  const nameMaxLength = 6;
  const {
    data, productData, productId, onProductIdChange, refreshAuthProductData,
    changeMenuAuth, pinyin, productInfoRefresh,
  } = SingleProductInfoContext.useContainer();
  const { beacon } = BeaconContext.useContainer();
  const { info } = UserInfoContext.useContainer();
  const { changeURL, menuId } = MenuUrlContext.useContainer();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { data: menuTree, error } = useFetch(
    () => getProductMenuTree(productId), { defaultData: undefined, depends: [productId] },
  );
  const { data: iDataMenuTree } = useFetch(() => getZoneBaseMenu(productId, pinyin), {
    interrupt: !pinyin,
    depends: [pinyin],
  });
  const [selectProductName, setSelectProductName] = useState('');
  const [productSelectVisible, setProductSelectVisible] = useState(false);

  const [mergeMenuTree, setMergeMenuTree] = useState<AdminMenuData | null>(null);

  useEffect(() => {
    if (!productData) {
      refreshAuthProductData();
    }
    if (!data) {
      productInfoRefresh();
    }
  }, []);

  useEffect(() => {
    if (menuTree && !Array.isArray(menuTree)) {
      const path = menuTree.children[0]?.data.path;
      const szid = searchParams.get('szid');
      if (path && (path === '/single-product/data'
        || (szid && productId !== Number(szid)))) {
        history.replace(path);
      }
    }
    if (Array.isArray(menuTree)) {
      message.info('还未配置菜单，请联系管理员配置!');
      changeMenuAuth(false);
    } else {
      changeMenuAuth(true);
    }
  }, [menuTree]);

  useEffect(() => {
    if (menuTree && iDataMenuTree && !Array.isArray(menuTree)) {
      const reportIndex = menuTree.children.findIndex((item) => item.data.path === '/single-product/report-index');
      const IDataIndex = menuTree.children.findIndex((item) => item.data.path === '/single-product/data');

      const tempMenuTree = JSON.parse(JSON.stringify(menuTree));
      if (reportIndex >= 0) {
        tempMenuTree.children[reportIndex].icon = 'icon-report';
      }

      if (IDataIndex >= 0 && iDataMenuTree.children.length) {
        tempMenuTree.children[IDataIndex] = iDataMenuTree;
      }

      if (IDataIndex >= 0) {
        tempMenuTree.children[IDataIndex].icon = 'icon-graph';
      }

      if (pinyin === SPECIAL_PRODUCT_NAME) {
        tempMenuTree.children.push(specialMenu);
      }

      setMergeMenuTree({ ...tempMenuTree });
    }
  }, [menuTree, iDataMenuTree, location.pathname, pinyin]);

  useEffect(() => {
    if (error) {
      message.info('尚未开通该专区的权限，请先申请产品专区权限!');
      history.push(`/single-product/dashboard?szid=${productId}`);
    }
  }, [error]);

  useEffect(() => {
    const storageProductId = localStorage.getItem('product_id');
    if (!productId && storageProductId) {
      onProductIdChange(+storageProductId);
    }
  }, [onProductIdChange, productId]);

  useEffect(() => {
    if (productData && productId && data) {
      const res = productData.find((item: ZoneItemData) => item.id === productId);
      setSelectProductName(res?.name || '');
    }
  }, [data, productData, productId]);

  const onMenuChange = ({ item, openKeys }: MenuData) => {
    const menuItem = item as AdminMenuData;
    beacon.onDirectUserAction(
      'click_zhuanqu_data',
      {
        user_id: info?.user_id || '',
        data_id: item?.name || '',
        zhuanqu_id: data?.name || '',
      },
    );
    if (menuItem.data?.path) {
      history.push(`${menuItem.data?.path}?szid=${productId}`);
    } else if (menuItem.data?.type === 'idata' || menuItem.data?.type === 'tableau') {
      const openKey = openKeys.reduce((openItem, cur, index) => (index === 0 ? openItem : `${cur}-${openItem}`));
      history.push(`/single-product/data?id=${menuItem.id}&szid=${productId}&open_keys=${openKey}`);
    } else if (menuItem.data?.type === SPECIAL_LABEL) {
      history.push(`/single-product/${SPECIAL_LABEL}`);
    } else {
      changeURL('');
      history.push('/single-product/data');
    }
  };

  const getDefaultMenuId = (item: any) => {
    if (item?.type === 1) {
      if (item.children?.length) {
        let id = menuId;
        item.children.forEach((ele: any) => {
          if (id === menuId) {
            const res = getDefaultMenuId(ele);
            if (res !== id) {
              id = res;
            }
          }
        });
        return id;
      }
      return menuId;
    }
    return item?.id || menuId;
  };

  const onSubMenuChange = (openKeys: string[]) => {
    if (!openKeys.length || searchParams.get('id')) return;
    const defaultMenuId = getDefaultMenuId(mergeMenuTree?.children?.[1]);
    const openKey = openKeys.reduce((item, cur, index) => (index === 0 ? item : `${cur}-${item}`));
    history.push(`/single-product/data?id=${defaultMenuId}&szid=${productId}&open_keys=${openKey}`);
  };

  const currentOpenKeys = searchParams.get('open_keys');
  const defaultId = searchParams.get('id');
  const defaultOpenKeys = currentOpenKeys?.split('-')
    || (iDataMenuTree && defaultId
      && getDefaultOpenKeys([iDataMenuTree], Number(defaultId)).flat())
    || iDataMenuTree?.children.map((item) => item.id.toString());

  const handleProductClick = (event: MouseEvent) => {
    event.stopPropagation();
    setProductSelectVisible(true);
  };

  const handleProductSelect = (id: number) => {
    onProductIdChange(id);
    if (id !== productId) {
      history.push(`/single-product/report-index?szid=${id}`);
    }
    setProductSelectVisible(false);
  };

  window.onclick = () => {
    setProductSelectVisible(false);
  };

  const filterName = (name: string) => (name.length > nameMaxLength ? `${name.substring(0, 6)}...` : name);

  return (
    <Sider
      width={240} className={classnames(styles.sider, styles.homeSider)}
    >
      <Logo className={styles.logo} />
      {data && productData && (
        <div className={styles.productContainer}>
          <div className={styles.productionBasicInfoContainer}>
            <img
              src={data.cover_pic ? `${data.cover_pic}&imageMogr2/thumbnail/x60` : defaultLogo} alt={data?.name}
              className={styles.productIcon}
            />
            <div className={styles.selectBox}>
              <span className={styles.selectName} onClick={handleProductClick}>{filterName(selectProductName)}</span>
              {productSelectVisible && (
                <div className={styles.selectOptions}>
                  {productData.map(
                    (item: ZoneItemData) => (
                      <span
                        className={classnames(styles.optionItem, {
                          [styles.optionItemActive]: item.id === productId,
                        })} key={item.id}
                        onClick={() => handleProductSelect(item.id)}
                      >
                        {filterName(item.name)}
                      </span>
                    ),
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={styles.skinContainer}>
            {mergeMenuTree && LeftMenu({
              menuTree: mergeMenuTree,
              onMenuChange,
              onSubMenuChange,
              theme: 'dark',
              defaultOpenKeys,
              className: styles.menuTree,
              selectId: defaultId || undefined,
            })}
            {data?.skin && <img src={`${data?.skin}&imageMogr2/thumbnail/240x`} alt="img" />}
          </div>
        </div>
      )}
    </Sider>
  );
};

export default HomeSider;
