import { useState } from 'react';
import { createContainer } from 'unstated-next';

function useOutDisplayData() {
  const [needDisplayOption, setNeedDisplayOption] = useState(false);
  const [optionName, setOptionName] = useState('');
  const [fromOut, setFromOut] = useState(false);
  const [needTableau, setNeedTableau] = useState(false);
  const [echoValue, setEchoValue] = useState('');

  return {
    fromOut,
    setFromOut,
    optionName,
    setOptionName,
    needDisplayOption,
    setNeedDisplayOption,
    needTableau,
    setNeedTableau,
    echoValue,
    setEchoValue,
  };
}

const OutDisplay = createContainer(useOutDisplayData);

export default OutDisplay;
