import React, {
  FC, CSSProperties, useState, useEffect,
} from 'react';
import configs from '@/configs';
import { WxworkLink } from '@/components';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IMur from 'assets/images/i-mur.png';
import { UserInfoContext } from '@/context';
import styles from './index.module.less';

const { Footer } = Layout;
interface MainFooterProps {
  style?: CSSProperties,
  mode?: 'home' | 'admin',
}

const MainFooter: FC<MainFooterProps> = ({ style, mode = 'admin' }: MainFooterProps) => {
  const location = useLocation();
  const [iDataVisible, setIDataVisible] = useState(false);
  const { isStaffUser } = UserInfoContext.useContainer();
  const { t } = useTranslation();

  useEffect(() => {
    if (location.pathname === '/base') {
      setIDataVisible(true);
    } else {
      setIDataVisible(false);
    }
  }, [location.pathname]);

  return (
    <Footer
      className={
        `${styles.footer} ${iDataVisible ? styles.iDataFooter : ''}
        ${mode === 'home' ? styles.homeFooter : ''}`
      } style={style}
    >
      <span className={styles.feedbackLink}>
        <img
          src={IMur} alt="I-MUR"
          className={styles.IMur}
        />
        {t('copyRight')}
      </span>
      {!isStaffUser && (
      <WxworkLink
        href={configs('baseInsight.wxworkAssistantLink')} message={t('assistant')}
      />
      )}
      {!isStaffUser && (
      <a
        href="https://tucao.oa.com/products/175142"
        target="_blank"
        rel="noreferrer noopener"
      >
        {t('feedback')}
      </a>
      )}
      {iDataVisible ? <p className={styles.powerByIData}>Powered by iData</p> : null}
    </Footer>
  );
};

export default MainFooter;
