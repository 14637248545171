/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from 'react';
// eslint-disable-next-line no-unused-vars
import promiseFinally from 'promise.prototype.finally';

export interface JsonError {
  id?: number,
  links?: object,
  status: number,
  code: number,
  title: string,
  detail: string,
  source?: object,
  meta?: object,
}

promiseFinally.shim();

interface FetchResponse<T> {
  data: T | null,
  error: JsonError | null,
  loading: boolean,
  isFinish: boolean,
}

function isInterrupt(options?: { interrupt?: Boolean }) {
  if (options === undefined || options.interrupt === undefined) {
    return false;
  }

  return options.interrupt === true;
}

function useFetch<T>(
  serviceFn: () => Promise<T>,
  options?: { defaultData?: T, depends: Array<any>, interrupt?: Boolean },
) {
  const [response, setResponse] = useState<FetchResponse<T>>({
    data: (options?.defaultData) ? options.defaultData : null,
    loading: false,
    isFinish: false,
    error: null,
  });
  const depends = options?.depends || [];
  const [isRefresh, setRefresh] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !isInterrupt(options)) {
      setResponse((prevState) => ({ ...prevState, loading: true, isFinish: false }));
      serviceFn().then((data) => {
        if (!isMounted) return;
        setResponse((prevState) => ({
          ...prevState,
          data,
          error: null,
          isFinish: true,
        }));
      }).catch((error) => {
        if (!isMounted) return;
        setResponse((prevState) => ({
          ...prevState,
          error: error?.errors || error,
          data: null,
          isFinish: true,
        }));
      }).finally(() => {
        if (!isMounted) return;
        setResponse((prevState) => ({
          ...prevState,
          loading: false,
          isFinish: true,
        }));
      });
    }

    return () => {
      isMounted = false;
    };
  }, [...depends, isRefresh]);

  const refresh = useCallback(() => {
    setRefresh(!isRefresh);
  }, [...depends, isRefresh]);

  return { ...response, refresh };
}

export default useFetch;
