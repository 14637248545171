import { createContainer } from 'unstated-next';
import { getExtClass } from '@/services/report-library';
import { useFetch } from '@/hooks';

function useReportExtClassification() {
  const { data, error, refresh } = useFetch(getExtClass);

  return {
    reportClassificationData: data,
    error,
    refresh,
  };
}

const ReportExtClassification = createContainer(useReportExtClassification);

export default ReportExtClassification;
